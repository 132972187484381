
import {
    Tooltip
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { WithConfirm } from '../../../WithConfirm';


// ----------------------------------------------------------------------

const ActionRenderer = (props) => {
    const navigate = useNavigate();
    const { data } = props;
    const [showDashboard, setShowDashboard] = useState(false);


    useEffect(() => {
        if (data?.l_dvc_typ_name === "ACCESS_POINT" && data?.r_dvc_controller === "WLC" || data?.r_dvc_type_source !== "DC") {
            setShowDashboard(false);
        } else {
            setShowDashboard(true);
        }
    }, [data]);

    if (props.data === undefined) {
        return '';
    }

    if (props?.data) {
        return (
            <>
                {showDashboard && (
                    <Tooltip title="Go to health dashboard">
                        <LoadingButton
                            variant="outlined"
                            onClick={() => {
                                navigate(`/dashboard/health?locationUuid=${data?.location?._uid}&serialNum=${data?.r_dvc_serial_number}`);
                            }}
                        >
                            View Health
                        </LoadingButton>
                    </Tooltip>
                )}
            </>
        )
    }
};


ActionRenderer.propTypes = {
    data: PropTypes.object,
};

export default WithConfirm(ActionRenderer);
