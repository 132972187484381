import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import 'simplebar-react/dist/simplebar.min.css';
import './pages/Pages.css';
import './styles/aggrid-styling.css';
import 'react-grid-layout/css/styles.css';

import { createStore,applyMiddleware  } from 'redux';
import thunk from 'redux-thunk';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import allReducers from './reducer/index';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import "./utils/browserSupports"
import { HealthProvider } from './components/FirewallHealth/components/userContextHealth';
// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

const store = createStore(allReducers, applyMiddleware(thunk));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
        <HealthProvider>
        <App />
        </HealthProvider>
      </Provider>
    </BrowserRouter>
  </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
