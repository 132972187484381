import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import InterfaceSelector from '../InterfaceSelector';
import { useHealthContext } from '../userContextHealth';
import APTable from './components/Client/APTable';
import ClientsTable from './components/Client/ClientsTable';
import VLANtable from './components/Client/VLANtable';
import InterfaceStatusTable from './components/Interface/InterfaceStatusTable';
import ThroughputBandwidth from './components/Interface/ThroughputBandwidth';
import CPUChart from './components/Overview/CPUChart';
import MemoryFreeChart from './components/Overview/MemoryFreeChart';
import NetworkClients from './components/Overview/NetworkClients';
import OverviewDetails from './components/Overview/OverviewDetails';

//-------------------------------------------------------------
const useStyles = makeStyles({
    skeletonLoader: {
        top: 0,
        left: 0,
        width: "100%",
        display: 'flex',
    },
    noDataSelectedContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px',
        border: '2px solid',
        borderColor: '#cfd8dc'
    }
});
//----------------------------------------------------

const WLCHealthDashboardComponent = () => {
    const classess = useStyles();
    const { healthDashState } = useHealthContext()
    const [overviewExpanded, setOverviewExpanded] = useState(true);
    const [clientsExpanded, setClientsExpanded] = useState(false);
    const [apExpanded, setApExpanded] = useState(false);
    const [vlanExpanded, setVlanExpanded] = useState(false);
    const [interfaceExpanded, setInterfanceExpanded] = useState(false);


    const handleOverviewChange = () => {
        setOverviewExpanded(!overviewExpanded);
    };

    const handleClientsChange = () => {
        setClientsExpanded(!clientsExpanded);
    };

    const handleApChange = () => {
        setApExpanded(!apExpanded);
    };

    
    const handleVlanChange = () => {
        setVlanExpanded(!vlanExpanded);
    };

    const handleInterfaceChange = () => {
        setInterfanceExpanded(!interfaceExpanded);
    };

    if (!healthDashState.selectedDevice) {
        return <Stack direction="column" className={classess.noDataSelectedContainer}>
            <Typography variant="h3" display="block" gutterBottom>
                Please select device
            </Typography>
            <Typography variant="subtitle1" display="block" gutterBottom>
                to view dashboard
            </Typography>
        </Stack>
    }

    return (
        <>
            <Box>
                <Accordion expanded={overviewExpanded} onChange={handleOverviewChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}>Overview</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <OverviewDetails overviewExpanded={overviewExpanded} />
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <NetworkClients overviewExpanded={overviewExpanded} />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={1}>
                                <Grid item sm={6} md={6} lg={6}>
                                    <CPUChart overviewExpanded={overviewExpanded} />
                                </Grid>
                                <Grid item sm={6} md={6} lg={6}>
                                    <MemoryFreeChart overviewExpanded={overviewExpanded} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={interfaceExpanded} onChange={handleInterfaceChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}>Interface</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            <Grid container item sm={12} md={12} lg={12} sx={{ pb: 2 }}>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <InterfaceSelector interfaceExpanded={interfaceExpanded} />
                                </Box>
                            </Grid>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={8} md={8} lg={8}>
                                    <ThroughputBandwidth expandedState={interfaceExpanded} />
                                </Grid>
                                <Grid item sm={4} md={4} lg={4}>
                                    <InterfaceStatusTable expandedState={interfaceExpanded} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={clientsExpanded} onChange={handleClientsChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}>Clients</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <ClientsTable clientsExpanded={clientsExpanded} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={apExpanded} onChange={handleApChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}>AP</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <APTable apExpanded={apExpanded} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={vlanExpanded} onChange={handleVlanChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}>VLAN</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <VLANtable vlanExpanded={vlanExpanded} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
};

export default WLCHealthDashboardComponent;
