import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from "react";
import { interfaceStatus } from '../../../API/NetworkDevices/health';
import { UserContext } from '../../../context/UserContext/userContext';
import { toCamelizeWords } from "../../../utils/formatString";
import { useHealthContext } from './userContextHealth';


//------------------------------------------------------------------

//--------------------------------------------------------------

export default function InterfaceSelector({interfaceExpanded}) {
  const { customerId } = useContext(UserContext);
  const { healthDashDispatch, healthDashState, getStartEnd } = useHealthContext();
  const [isLoading, setLoadingData] = useState(false);
  const [interfaceData, setInterfaceData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const fetchInterfaceStatus = async () => {
    if (!healthDashState.selectedDevice || interfaceExpanded === false) {
        return '';
    }
    const data = getStartEnd();
    const payload = {
      start: data.start / 1000,
      end: data.end / 1000,
      step: data.step,
      // device_type: healthDashState.selectedDevice.l_dvc_typ_name,
      device_os: healthDashState.selectedDevice.r_dvc_os
    };
    setLoadingData(true);
    try {
      const res = await interfaceStatus(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload);
      setInterfaceData(res?.data);
      setLoadingData(false);
      if (res?.data?.length > 0) {
        const defaultSelectedValue = res.data[0];
        healthDashDispatch({ type: 'SET_Interface', payload: defaultSelectedValue });
        setSelectedValue(defaultSelectedValue);
      }else{
        healthDashDispatch({ type: 'SET_Interface', payload: null });
      }
    } catch (error) {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchInterfaceStatus();
  }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, interfaceExpanded]);

  const handleSelectType = (event, selectedOptions) => {
    if (selectedOptions) {
      healthDashDispatch({ type: 'SET_Interface', payload: selectedOptions });
      setSelectedValue(selectedOptions);
    } else {
      healthDashDispatch({ type: 'SET_Interface', payload: interfaceData[0] });
      setSelectedValue(interfaceData[0]);
    }
  };


  return (
    <>
      <Autocomplete
        size="small"
        handleHomeEndKeys
        loading={isLoading}
        options={interfaceData}
        value={selectedValue}
        sx={{ maxwidth: 400, minWidth: 350 }}
        isOptionEqualToValue={(option, value) => option?.name === value?.name}
        getOptionLabel={(option) => `${toCamelizeWords(option.name)}`}
        renderOption={(props, option) => (
          <li {...props} key={option.name} style={{display:'flex',gap:2}}>
            {toCamelizeWords(option?.name)} 
            {option?.status === 0 ? <ArrowDownward color="error" fontSize="inherit" /> : <ArrowUpward color="success" fontSize="inherit" />}
          </li>
        )}
        onChange={handleSelectType}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Choose interface"
            label="Interface"
            type="text"
            InputLabelProps={{ shrink: true }}
            autoComplete='new-password'
            InputProps={{
              ...params.InputProps,
              autoComplete: 'new-password',
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
}

InterfaceSelector.propTypes = {
  interfaceExpanded: PropTypes.bool.isRequired,
};
