import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Box, Breadcrumbs, Container, Typography } from '@mui/material';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { updateLocation } from '../actions/updateLocation';
import { InventoryComponent } from '../components/InventoryComponent';
import LocationSelect from '../components/NetworkHealthComponents/LocationSelect';
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function Inventory() {
  const location = useLocation();
  const dispatch = useDispatch();
  const locationData = useSelector((state) => state.locationSelectReducer.inventory.locationData);

  const selectLocation = async (_locations) => {
    dispatch(updateLocation({ page: 'inventory', locationUuid: _locations }));
  };

  useEffect(() => {
    if (location.search) {
      const parsed = queryString.parse(location.search);
      if (parsed.locationUuid) {
        dispatch(updateLocation({ page: 'inventory', locationUuid: [parsed.locationUuid] }));
      }
    }
  }, [location]);

  return (
    <Page title="Network devices">
      <Container maxWidth={false}>
        <div
          role="presentation"
          style={{ cursor: 'pointer', paddingBottom: '17px', display: 'flex', justifyContent: 'space-between' }}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
              <HomeOutlinedIcon />
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/Inventory">
              Inventory
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/network-device">
              Network Devices
            </Typography>
          </Breadcrumbs>
          <Box style={{display:'flex',gap:'35px'}}>
          <Typography component={Link} underline="hover" color="inherit" to="/dashboard/network-device/inactive" marginTop='8px'>
            View Inactive
          </Typography>
          <LocationSelect selectLocation={selectLocation} locationData={locationData}   />
          </Box>
        </div>
         <hr  style={{ marginBottom: '26px', border: 'none', borderBottom: '1px solid rgb(141 158 171 / 24%)' }} />
        <div>
        <InventoryComponent locationData={locationData} />
        </div>
      </Container>
    </Page>
  );
}
