import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Box, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Backupdialog from './Backupdialog';

const Backuprendere = (props) => {
  const { data } = props;
  const [openChonkyDialog, setOpenChonkyDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenChonkyDialog(true);
  };

  if (data === undefined) {
    return '';
  }
  if (data?.r_scan_status === 'SCAN_FAILED') {
    return <Box>{data?.r_failed_reason}</Box>;
  }
if(data.r_scan_status === 'SCAN_SUCCESS'){
  if (data.r_path.length > 0) {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <Tooltip title="View backup">
        <IconButton onClick={handleClickOpen}>
          <DescriptionOutlinedIcon />
        </IconButton>
        </Tooltip>
        {openChonkyDialog && (
          <Backupdialog openDialog={openChonkyDialog} data={data} handleClose={() => setOpenChonkyDialog(false)} />
        )}
      </Box>
    );
  }
}
  return 'Not available';
};


Backuprendere.propTypes = {
  data: PropTypes.object,
};

export default Backuprendere;
