// @mui
import CloseIcon from '@mui/icons-material/Close';
import { useState, useContext, useEffect } from 'react';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Stack,
  Divider,
  createFilterOptions,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFTextField, FormProvider, RHFSubnetSelector } from '../../hook-form';
import { UserContext } from '../../../context/UserContext';
// import SubnetSelector from './SubnetSelector';
import { getZones } from '../../../API/Zones/zones';
import ZoneSelector from './ZoneSelector';
import { addLocation } from '../../../API/Locations/showLocations';

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
});

export default function AddLocationDialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog, handleClose, gridApi } = props;
  const { customerId } = useContext(UserContext);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [zoneLoading, setZoneLoading] = useState(false);
  const [selectedSubnets, setSelectedSubnets] = useState([]);
  const [zoneValue, setZoneValue] = useState(null);
  const [page , setPage]=useState(0)


  useEffect(() => {
    setZoneLoading(true);
    getZones(customerId , page).then((res) => {
      setPage(res.current_page - 1);
      setZoneOptions(res.data);
      setZoneLoading(false);
    });
  }, []);

  const subnetRegex = /^(([12]?[0-9]{1,2}|2[0-4][0-9]|25[0-5])(\.|\/)){4}([1-2]?[0-9]|3[0-2])$/;

  const membersSchema = Yup.object().shape({
    locationName: Yup.string().required('Location name required'),
    locationAddress: Yup.string(),
    locationContact: Yup.string(),
    subnets: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().matches(subnetRegex, "Invalid subnet"),
          label: Yup.string()
        })
      )
      .min(1, "Min 1 subnet is required"),
    zoneValue: Yup.string(),
  });

  const defaultValues = {
    locationName: '',
    locationAddress: '',
    locationContact: '',
    subnets: [],
    zoneValue: '',
  };

  const methods = useForm({
    resolver: yupResolver(membersSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (value) => {
    const data = {
      name: value.locationName,
      subnets: value.subnets.map(item => item.value),
      address: value.locationAddress,
      contact: value.locationContact,
      zone_uuid: zoneValue?.id,
    };
    setLoading(true);
    addLocation(customerId, data)
      .then((res) => {
        setLoading(false);
        handleClose();
        gridApi.purgeInfiniteCache();       
        enqueueSnackbar(
          'Location added sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        style={{margin:0}}
        aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
            Add location
            <IconButton onClick={handleClose} sx={{ float: 'right' }}>
              <CloseIcon sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </DialogTitle>
          <Divider />
          {loading && <LinearProgress />}
          <DialogContent >
            <Stack spacing={3} sx={{ py: 2 }}>
              <RHFTextField name="locationName" label="Location name *" placeholder="Enter location name" />
              <RHFTextField name="locationAddress" label="Location address *" placeholder="Enter location address" />
              <RHFTextField name="locationContact" label="Location contact *" placeholder="Enter location contact" />
              <RHFSubnetSelector name="subnets" setSelectedSubnets={setSelectedSubnets} selectedSubnets={selectedSubnets}/>
              <ZoneSelector
                zoneOptions={zoneOptions}
                zoneLoading={zoneLoading}
                setZoneValue={setZoneValue}
                zoneValue={zoneValue}
              />
            </Stack>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Add location
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
}
