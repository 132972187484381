import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Breadcrumbs, Container, Divider, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateLocation } from '../actions/updateLocation';
import LocationSelect from '../components/NetworkHealthComponents/LocationSelect';
import Page from '../components/Page';
import { UnkownDevicesComponents } from '../components/UnknownDevicesComponents';

// ----------------------------------------------------------------------

export default function UnknownDevices() {
  const dispatch = useDispatch();
  const locationData = useSelector((state) => state.locationSelectReducer.unknowndevice.locationData);

  const selectLocation = async (_locations) => {
    dispatch(updateLocation({ page: 'unknowndevice', locationUuid: _locations }));
  };
  return (
    <Page title="Unknown Devices">
      <Container maxWidth={false}>

        <Grid container direction="column" spacing={3}>
          <Grid item container>
            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
              <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
                    <HomeOutlinedIcon />
                  </Typography>
                  <Typography component={Link} underline="hover" color="inherit" to="/dashboard/inventory">
                    Inventory
                  </Typography>
                  <Typography component={Link} underline="hover" color="inherit" to="/dashboard/unknown-devices">
                    Unknown Devices
                  </Typography>
                </Breadcrumbs>
              </div>
            </Grid>

            <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center',marginTop:'-20px' }}>
              <LocationSelect selectLocation={selectLocation} locationData={locationData}/>
            </Grid>
            <Divider sx={{ marginTop: '5px', width:'100%' }} />
          </Grid>


          <Grid item container>
            <Grid item xs={12}>
              <UnkownDevicesComponents locationData={locationData} />
            </Grid>
          </Grid>
        </Grid>

      </Container>
    </Page>
  );
}
