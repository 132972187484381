import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";

export default function RHFSubnetSelector({ name }) {
  const { control } = useFormContext();

  const filter = createFilterOptions();
  return (
    <Controller
      name={name}
      control={control}
      autoSelect
      selectOnFocus
      defaultValue={[]}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          disableClearable
          disablePortal
          filterSelectedOptions
          multiple
          freeSolo
          autoHighlight
          getOptionDisabled={(option) => option.disabled}
          getOptionLabel={(option) => option.label || option}
          onChange={(e, newValue, reason, details) => {
            if (details.option.create && reason !== "removeOption") {
              field.onChange([...field.value, { label: details.option.name, value: details.option.value }])
            } else {
              field.onChange(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue.toLowerCase() === option.name.toLowerCase());
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                name: inputValue,
                label: `Add "${inputValue}"`,
                value: inputValue,
                create: true,
              });
            }
            return filtered;
          }}
          options={[]}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Enter subnets"
              InputLabelProps={{ shrink: true }}
              error={!!error}
              helperText={error?.message}
              label="Enter Subnets"
              inputRef={ref}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
      )}
    />
  );
}
