import modem from '../modem';

export const getEndpoints = async (id, data) => {
  const newPro =  modem.post(`/${id}/inventory/endpoints`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};

// new imports

export const getEndpointByStatistics = async (id, data) => {
  const newPro =  modem.post(`/${id}//statistics/endpoints`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};  

export const getCountByVendor = async (id, locations) => {
  const payload = {
    location_uuids : locations
  }
  const newPro =  modem.post(`/${id}/inventory/endpoints/count-by-vendor`, payload).then((response) => {
    return response.data.data;   
  });
  return newPro;
};

export const getCountByOs = async (id, locations) => {
  const payload = {
    location_uuids : locations
  }
  const newPro =  modem.post(`/${id}/inventory/endpoints/count-by-os`, payload).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const getCountByFamily = async (id, locations) => {
  const payload = {
    location_uuids : locations
  }
  const newPro =  modem.post(`/${id}/inventory/endpoints/count-by-family`, payload).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const getEndpointStatistics = async (id, data) => {
  const newPromise =  modem.post(`/${id}/inventory/endpoints/count-by-family`, data).then((response) => {
    return response.data.data;
  })
  return newPromise;
};
