
import { Breadcrumbs, Container,Typography,IconButton } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import SummaryComponents from '../components/SummaryComponents/SummaryComponents';
//---------------------------------------------------------------------


export default function Summary() {

  return (
    <Page title="Summary/Events">
      <Container maxWidth={false} >
        <div role="presentation" style={{ display: 'flex', alignItems: 'center',position:'relative', paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
          <IconButton component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 , cursor:'pointer'}}>
              <HomeOutlinedIcon />
            </IconButton>
            <Typography component={Link} underline="hover" color="inherit" sx= {{cursor:'pointer'}} to="/dashboard/summary-events">
              CMDB
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" sx= {{cursor:'pointer'}} to="/dashboard/summary-events">
              Change events
            </Typography>
          </Breadcrumbs>
        </div>

        <SummaryComponents />
      </Container>
    </Page>
  );
}
