import { Autocomplete, Box, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback } from 'react';
import { toCamelizeWords } from "../../../utils/formatString";
import { timeRangeOptions } from "./timeRangeOptions";
import { useHealthContext } from './userContextHealth';


//-----------------------------------------


const useStyles = makeStyles({
    root: {
        "& .MuiSvgIcon-root": {
            fontSize: 17,
        },
        "& .MuiFormControlLabel-label": {
            fontSize: "1rem",
            marginLeft: -6,
        }
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row',
        gap: 3,
        padding: 6,
    },
    overlayCard: {
        width: '260px',
        height: 'auto',
        opacity: 1,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        flexDirection: 'column',
    },
    popover: {
        pointerEvents: 'none',
    },
    popoverContent: {
        pointerEvents: 'auto',
        zIndex: 9999,
    },
    singleButtonStyle: {
        color: 'black',
        fontWeight: 600,
        marginBottom: '8px',
    },
    buttonGrpStyle: {
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        padding: '2px',
    },
    buttonHeaderText: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 7,
    },
    oneGrpContainer: {
        padding: '5px',
        border: '1px solid',
        borderColor: '#eeeeee',
        borderRadius: 5
    }
});


//--------------------------------------------------
export default function TimeRangeComponent() {
    const classes = useStyles();
    const { healthDashDispatch, healthDashState } = useHealthContext();

    const handleChange = useCallback((event) => {
        healthDashDispatch({ type: 'SET_TIME_RANGE', payload: event.target.value });
    });

    const handleCustomChange = (event, value) => {
        healthDashDispatch({ type: 'SET_TIME_RANGE', payload: value?.id });
    };

    // ----------------------------------
    return (
        <>
            <Box>
                <FormControl>
                    <RadioGroup
                        row
                        variant="standard"
                        onChange={handleChange}
                        value={healthDashState.timeRange}
                    >
                        {timeRangeOptions?.slice(0, 7).map((range) => (
                            <FormControlLabel
                                key={range.id}
                                value={range.id}
                                label={range.id}
                                className={classes.root}
                                control={<Radio size="small" className={classes.root} />}
                            />
                        ))}

                        {/* <Autocomplete
                            size="small"
                            handleHomeEndKeys
                            sx={{ width: 230 }}
                            onChange={handleCustomChange}
                            value={timeRangeOptions?.slice(7, 36).find((i) => i.id === healthDashState?.timeRange) || null}
                            options={timeRangeOptions?.slice(7, 36)}
                            isOptionEqualToValue={(option, value) => option?.label === value?.label}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id}>
                                        {toCamelizeWords(option.label)}
                                    </li>
                                )
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    placeholder="Choose custom range"
                                    label="custom range"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                    autoComplete='new-password'
                                    InputProps={{
                                        ...params.InputProps,
                                        autoComplete: 'new-password',
                                    }}
                                />
                            }
                        /> */}
                    </RadioGroup>
                </FormControl>
            </Box>
        </>
    );
}
