import { Breadcrumbs, Container, Typography, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Page from '../components/Page';
import CredentialComponent from '../components/Credentials/CredentialComponents';


// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Credential() {
  return (
    <Page title="Credentials">
      <Container maxWidth={false}>
        <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '32px' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
              <HomeOutlinedIcon />
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/dc-agent">
              Onboarding
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/credential">
              Credentials
            </Typography>
          </Breadcrumbs>
          <Divider sx={{ marginTop: '15px', width: '100%' }} />
        </div>

        <CredentialComponent />
      </Container>
    </Page>
  );
}
