import { Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useHealthContext } from '../../../userContextHealth';


// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    padding: theme.spacing(1),
}));

const useStyles = makeStyles({
    headerTextStyle: {
        textAlign: 'center',
        color: '#9e9e9e'
    },
    subtitleTextStyle: {
        textAlign: 'center',
        color: '#212121',
        fontSize: '1.3rem'
    }
});

// ---------------------------------------


const HAinfo = () => {
    const classes = useStyles();
    const { healthDashState } = useHealthContext()

    return (
        <>
            <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12}>
                    <Paper sx={{ padding: '10px' }} variant="outlined">
                        <ChartWrapperStyle>
                            <Grid container spacing={2} direction="row">
                                <Grid item lg={6} md={6} sm={6}>
                                    <Typography gutterBottom variant='h5' className={classes.headerTextStyle}>
                                       Module Type
                                    </Typography>
                                    <Typography gutterBottom variant='subtitle1' className={classes.subtitleTextStyle}>
                                        {healthDashState?.selectedDevice?.r_dvc_ha_type || '--' }
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6}>
                                    <Typography gutterBottom variant='h5' className={classes.headerTextStyle}>
                                        Module Count
                                    </Typography>
                                    <Typography gutterBottom variant='subtitle1' className={classes.subtitleTextStyle}>
                                        {healthDashState?.selectedDevice?.r_dvc_ha_item_count || '--'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ChartWrapperStyle>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};
export default HAinfo;
