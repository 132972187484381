import { Close } from '@mui/icons-material';
import { IconButton, List, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AccessIcon from '../../../../Assets/ap.svg';
import StackIcon from '../../../../Assets/dsw.svg';
import FirewallIcon from '../../../../Assets/firewall-r1.svg';
import InternetIcon from '../../../../Assets/internet.svg';
import SwitchIcon from '../../../../Assets/switch.svg';
import WVCIcon from '../../../../Assets/wlc.svg';
import Scrollbar from '../../../Scrollbar';
import { useMapSettingContext } from './mapSettingProvider';


const useStyles = makeStyles(() => ({
  remoteContainer: {
    position: 'relative',
    width: '160px',
    display: 'flex',
    flexDirection: 'column',
  },
  circleDown: {
    border: "3px solid red",
    animationName: '$blinkerDown',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  '@keyframes blinkerDown': {
    from: { borderColor: "rgb(255 0 0)" },
    to: { borderColor: "#ffffff" },
  },
  legendContainer: {
    display: "flex",
    alignItems: "center",
    "& span": {
      fontSize: 14
    }
  },
  iconContainer: {
    borderRadius: '6px',
    padding: '4px',
    backgroundColor: '#81d4fa',
    height: 30,
    width: 30,
    margin: "5px 10px"
  }
}));

function LegendControl() {
  const classes = useStyles()
  const { mapSettingDispatch } = useMapSettingContext()

  const handleShowLegend = () => {
    mapSettingDispatch({ type: 'TOGGLE_SHOW_LEGEND' })
  };

  return (
    <Paper elevation={0} className={classes.remoteContainer}>
      <div style={{textAlign:'right', position:"absolute", right:0, zIndex:1}}>
        <IconButton onClick={handleShowLegend}>
          <Close />
        </IconButton>
      </div>

      <Scrollbar>
        <List>
          <div style={{ padding: '0px' }}>
            <div className={classes.legendContainer} style={{ display: "flex" }}>
              <div className={classes.iconContainer}>
                <img src={InternetIcon} width="20px" height="20px" alt="internet" />
              </div>
              <span>
                Internet
              </span>
            </div>
          </div>

          <div style={{ padding: '0px' }}>
            <div className={classes.legendContainer} style={{ display: "flex" }}>
              <div className={classes.iconContainer}>
                <img src={FirewallIcon} width="20px" height="20px" alt="firewall" />
              </div>
              <span>
                Firewall
              </span>
            </div>
          </div>

          <div style={{ padding: '0px' }}>
            <div className={classes.legendContainer} style={{ display: "flex" }}>
              <div className={classes.iconContainer}>
                <img src={WVCIcon} width="20px" height="20px" alt="WLC" />
              </div>
              <span>
                Wireless LAN Controller
              </span>
            </div>
          </div>

          <div style={{ padding: '0px' }}>
            <div className={classes.legendContainer} style={{ display: "flex" }}>
              <div className={classes.iconContainer}>
                <img src={SwitchIcon} width="20px" height="20px" alt="Core Switch" />
              </div>
              <span>
                Core Switch
              </span>
            </div>
          </div>

          <div style={{ padding: '0px' }}>
            <div className={classes.legendContainer} style={{ display: "flex" }}>
              <div className={classes.iconContainer}>
                <img src={StackIcon} width="20px" height="20px" alt="Access Switch" />
              </div>
              <span>
                Access Switch
              </span>
            </div>
          </div>

          <div style={{ padding: '0px' }}>
            <div className={classes.legendContainer} style={{ display: "flex" }}>
              <div className={classes.iconContainer}>
                <img src={AccessIcon} width="20px" height="20px" alt="Access Point" />
              </div>
              <span>
                Access Point
              </span>
            </div>
          </div>

          <div style={{ padding: '0px' }}>
            <div className={classes.legendContainer} style={{ display: "flex" }}>
              <div className={`${classes.iconContainer} ${classes.circleDown}`}>
                {/* <img src={AccessIcon}  width="20px" height="20px" alt="access" /> */}
              </div>
              <span>
                Down Indicator 
              </span>
            </div>
          </div>

        </List>
      </Scrollbar>
    </Paper>
  );
}

export default LegendControl;