// @mui
import { Grid } from '@mui/material';
import { useState } from 'react';
import DevicesByTypeChart from './DevicesByTypeChart';
import VendorChart from './VendorChart';

const DashboardCharts = (props) => {
  const { locationData } = props;
  const [showGraph, setShowGraph] = useState(true);
  const [openedOnce, setOpenedOnce] = useState(true);
  
  const toggleShowGraph = () => {
    setOpenedOnce(true)
    setShowGraph(!showGraph)
  }

  return (
    <>
    
      <Grid container item spacing={4}>
        <Grid item lg={6} sm={12} xs={12} md={6}>
          <VendorChart 
            showGraph={showGraph} 
            openedOnce={openedOnce}
            setShowGraph={toggleShowGraph} 
            locationData={locationData} />
        </Grid>
        <Grid item lg={6} sm={12} xs={12} md={6}>
          <DevicesByTypeChart 
            showGraph={showGraph} 
            openedOnce={openedOnce}
            setShowGraph={toggleShowGraph} 
            locationData={locationData} />
        </Grid>
      </Grid>
    </>
  );
};
export default DashboardCharts;
