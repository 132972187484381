import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { updateLocation } from '../../../API/Locations/showLocations';
import { getZones } from '../../../API/Zones/zones';
import { UserContext } from '../../../context/UserContext';
import { FormProvider, RHFTextField } from '../../hook-form';
import ZoneSelector from './ZoneSelector';

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
});

export default function EditLocationDialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog, handleClose, api, locationData } = props;
  const { customerId } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [selectedSubnets, setSelectedSubnets] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [zoneLoading, setZoneLoading] = useState(false);
  const [zoneValue, setZoneValue] = useState(null);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setSelectedSubnets(
      locationData.subnets?.map((item) => {
        return { id: item.uuid, name: item.r_subnet_value };
      })
    );
    if (locationData.zone) {
      setZoneValue({ id: locationData.zone._uid, name: locationData.zone.r_zone_name });
    }
    setZoneLoading(true);
    getZones(customerId, page)
      .then((res) => {
        setZoneOptions(res.data);
        setZoneLoading(false);
        setPage(res.current_page - 1);
      })
      .catch(() => {
        setZoneLoading(false);
      });
  }, []);

  const membersSchema = Yup.object().shape({
    locationName: Yup.string().required('Location name required'),
    locationAddress: Yup.string(),
    locationContact: Yup.string(),
  });

  const defaultValues = {
    locationName: locationData?.r_location_name || '',
    locationAddress: locationData?.r_location_address || '',
    locationContact: locationData?.r_location_contact || '',
  };

  const methods = useForm({
    resolver: yupResolver(membersSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (value) => {
    const data = {
      name: value.locationName,
      subnets: selectedSubnets.map((item) => item.name),
      address: value.locationAddress,
      contact: value.locationContact,
      zone_uuid: zoneValue?.id,
    };
    setLoading(true);
    updateLocation(customerId, locationData._uid, data)
      .then((res) => {
        setLoading(false);
        handleClose();
        api.api.purgeInfiniteCache(); 
        enqueueSnackbar(
          'Location updated sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
            Edit location
            <IconButton onClick={handleClose} sx={{ float: 'right' }}>
              <CloseIcon sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </DialogTitle>
          <Divider />
          {loading && <LinearProgress />}
          <DialogContent>
            <Stack spacing={3}>
              <RHFTextField name="locationName" label="Location name" placeholder="Enter location name"/>

              <RHFTextField name="locationAddress" label="Location address" placeholder="Enter location address" />

              <RHFTextField name="locationContact" label="Location contact" placeholder="Enter location contact" />

              <ZoneSelector
                zoneOptions={zoneOptions}
                zoneLoading={zoneLoading}
                setZoneValue={setZoneValue}
                zoneValue={zoneValue}
              />
            </Stack>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Save
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
}
