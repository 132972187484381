import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import InterfaceSelector from '../InterfaceSelector';
import { useHealthContext } from '../userContextHealth';
import ClientsTable from './components/Client/ClientsTable';
import NetworksTable from './components/Client/NetworksTable';
import InterfaceStatusTable from './components/Interface/InterfaceStatusTable';
import ThroughputBandwidth from './components/Interface/ThroughputBandwidth';
import CPUChart from './components/Overview/CPUChart';
import MemoryFreeChart from './components/Overview/MemoryFreeChart';
import ThroughputChart from './components/Overview/ThroughputChart';


//-------------------------------------------------------------

const useStyles = makeStyles({
    skeletonLoader: {
        top: 0,
        left: 0,
        width: "100%",
        display: 'flex',
    },
    noDataSelectedContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px',
        border: '2px solid',
        borderColor: '#cfd8dc'
    }
});

//----------------------------------------------------

const WMMHealthDashboardComponent = () => {
    const classess = useStyles();
    const { healthDashState } = useHealthContext()
    const [overviewExpanded, setOverviewExpanded] = useState(true);
    const [clientsExpanded, setClientsExpanded] = useState(true);
    const [interfaceExpanded, setInterfanceExpanded] = useState(false)
    

    const handleOverviewChange = () => {
        setOverviewExpanded(!overviewExpanded);
    };

    const handleClientsChange = () => {
        setClientsExpanded(!clientsExpanded);
    };

    const handleInterfaceChange = () => {
        setInterfanceExpanded(!interfaceExpanded);
    };

    if (!healthDashState.selectedDevice) {
        return <Stack direction="column" className={classess.noDataSelectedContainer}>
            <Typography variant="h3" display="block" gutterBottom>
                Please select device
            </Typography>
            <Typography variant="subtitle1" display="block" gutterBottom>
                to view dashboard
            </Typography>
        </Stack>
    }

    return (
        <>
            <Box>
                <Accordion expanded={overviewExpanded} onChange={handleOverviewChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}>Overview</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" spacing={1}>
                                <Grid item sm={4} md={4} lg={4}>
                                    <CPUChart overviewExpanded={overviewExpanded} />
                                </Grid>
                                <Grid item sm={4} md={4} lg={4}>
                                    <MemoryFreeChart overviewExpanded={overviewExpanded} />
                                </Grid>
                                <Grid item sm={4} md={4} lg={4}>
                                    <ThroughputChart overviewExpanded={overviewExpanded} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={interfaceExpanded} onChange={handleInterfaceChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                            <Typography sx={{ fontWeight: 600 }}>Interface</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            <Grid container item sm={12} md={12} lg={12} sx={{pb:2}}>
                                <Box sx={{width: '100%', display:'flex', justifyContent:'right', alignItems:'center'}}>
                                    <InterfaceSelector interfaceExpanded={interfaceExpanded}/>
                                </Box>
                            </Grid>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={8} md={8} lg={8}>
                                    <ThroughputBandwidth interfaceExpanded={interfaceExpanded}/>
                                </Grid>
                                <Grid item sm={4} md={4} lg={4}>
                                    <InterfaceStatusTable interfaceExpanded={interfaceExpanded}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={clientsExpanded} onChange={handleClientsChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}>Clients</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <ClientsTable clientsExpanded={clientsExpanded} />
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <NetworksTable clientsExpanded={clientsExpanded} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
};

export default WMMHealthDashboardComponent;
