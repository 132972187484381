/* eslint-disable */
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useContext, useEffect, useMemo, useState } from 'react';
import { getLocationsWithoutPagination } from '../../API/Locations/showLocations';
import { UserContext } from '../../context/UserContext';

 // ------------------------------------------------------------------------
export default function LocationSelectGraph(props) {
  const { customerId } = useContext(UserContext);
  const { locationData } = props;
  const [locationOptions, setLocationOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');

  const defaultValues = useMemo(() => {
    if (!locationOptions || !locationData || typeof locationData !== 'object') {
      return [];
    }
    const locationDataUid = locationData.toString();
    const defaultValue = locationOptions.filter((option) => option._uid.toString() === locationDataUid);
    return defaultValue.map((option) => option._uid);
  }, [locationOptions, locationData]);

  const [selectedLocations, setSelectedLocations] = useState(defaultValues);

  const loadLoactionOptions = async () => {
    try {
      setLoading(true);
      getLocationsWithoutPagination(customerId).then((_locationOption) => {
        setLocationOptions(_locationOption.data.sort((a, b) => a.r_location_name - b.r_location_name));
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSelectedLocations(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    loadLoactionOptions();
  }, []);

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSelectLocation = (event, selectedOptions) => {
    if (selectedOptions && selectedOptions._uid) {
      const selectedLocationUids = [selectedOptions._uid];
      props.selectLocation(selectedLocationUids);
    } else {
      setSelectedLocations([]);
      props.selectLocation([]);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'end',
      }}
    >
      <Autocomplete
        disablePortal={true}
        sx={{ maxwidth: 900, minWidth: 300 }}
        limitTags={2}
        loading={loading}
        size="small"
        handleHomeEndKeys
        value={selectedLocations.length > 0 ? locationOptions.find((option) => option._uid === defaultValues[0]) : null}
        options={locationOptions}
        onChange={handleSelectLocation}
        groupBy={(option) => option.zone?.r_zone_name}
        getOptionLabel={(option) => option.r_location_name}
        renderOption={(props, option) => (
          <li {...props}>
            {option.r_location_name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Choose Locations"
            label="Locations"
            value={query}
            onChange={handleQueryChange}
            InputLabelProps={{ shrink: true }}
            autoComplete="new-password"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
    </div>
  );
}
