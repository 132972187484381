/* eslint-disable*/
import { Close, WarningAmberOutlined } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert, Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { toLower } from 'lodash';
import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import { updateInventory } from '../../../API/NetworkDevices/networkDevices';

  // -----------------------------------------------------------------------
  const useStyles = makeStyles({
    topScrollPaper: {
      alignItems: 'flex-start',
    },
    topPaperScrollBody: {
      verticalAlign: 'top',
    },
    stackContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  });
  
  // --------------------------------------------------------------------------
  function DeleteDialog(props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { customerId } = useContext(UserContext);
    const { openDialog, handleClose, data } = props;
    const [ deleteValue, setDeleteValue] = useState('');
    const [ loading, setLoading ] = useState(false);
  // ------------------------------------------------------------------------------
    const handleTextfield = (e) => {
        setDeleteValue(e.target.value);
    };

    const handleDeleteIpAddress = (value) => {
      setLoading(true);
      const payload = {
        _uid: data?._uid,
        type: 'NETWORK',
        name: data?.r_dvc_name,
        ip_address: data?.r_dvc_ip_address,
        serial_number: data?.r_dvc_serial_number,
        mac_address: data?.r_dvc_mac_address,
        host: data?.r_dvc_host,
        status: 'DELETED',
        type_name: data?.deviceType.r_dvc_typ_name,
        vendor: data?.deviceType.r_dvc_typ_vendor,
        os: data?.deviceType.r_dvc_typ_os,
      };
  
      updateInventory(customerId, data?._uid, payload)
        .then((res) => {
          setLoading(false);
          enqueueSnackbar(
            'IP Address deleted successfully',
            {
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            },
            500
          );
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          enqueueSnackbar(
            err.response.data.message,
            {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            },
            500
          );
          handleClose();
        });
    };

    // -------------------------------------------------------------------------------
    return (
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
          <DialogTitle>
            <Stack direction="row" className={classes.stackContainer}>
            <Box style={{ textAlign: 'left' }}>
                <WarningAmberOutlined
                  sx={{ color: '#ffc107', fontSize: '1.7rem', paddingRight: '5px', marginBottom: '-5px' }}
                />
                Information <br />
                <Typography variant='body2' gutterBottom>
                  {data.r_dvc_ip_address} - {data.r_dvc_serial_number}
                </Typography>
              </Box> 
              <IconButton onClick={handleClose}>
                <Close sx={{ fontSize: '1.5rem' }} />
              </IconButton>
            </Stack>
          </DialogTitle>
  
          <Divider />
          {loading && <LinearProgress />}
  
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Alert severity="warning" icon={false}>
              Are you sure you want to delete ?
            </Alert>

            <TextField
              fullWidth
              autoFocus
              type="text"
              label="Message"
              variant="outlined"
              placeholder="Type DELETE to delete"
              onChange={handleTextfield}
            />
          </DialogContent>

          <Divider />

          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton 
              color="primary"
              variant="contained"
              disabled={toLower(deleteValue) !== 'delete'}
              onClick={handleDeleteIpAddress}
              loading={loading}
            >
              Yes
            </LoadingButton>
          </DialogActions>
      </Dialog>
    );
  }
  export default DeleteDialog;
  