/* eslint-disable */
import {
  Refresh,
  Search,
  Settings
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Skeleton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getGroupSummaryChangeEvents } from '../../../API/NetworkDevices/networkDevices';
import { UserContext } from '../../../context/UserContext';
import { defaultAgGridProps } from '../../../utils/agGridProps';
import { getSortedColumnDef, mapColumnDefinitionToStore } from "../../../utils/columnDef";
import { toCamelizeWords } from '../../../utils/formatString';
import { fDateTime } from '../../../utils/formatTime';
import ColumnSettingsDialog from './filters/ColumnSettingsDialog';
import ActionRenderer from './renderer/ActionRenderer';
import AffectedRenderer from './renderer/AffectedRenderer';
import StatusRenderer from './renderer/StatusRenderer';




// ----------------------------------------------------------------------------------------

const useStyles = makeStyles({
  downHigh: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    gap: '5px',
    marginTop: 5,
    textAlign: 'center',
  },
  titleDown: {
    textTransform: 'uppercase',
    color: 'red',
    fontSize: '16px',
    marginLeft: '5px',
  },
  countProgress: {
    position: 'absolute',
    left: 22,
    top: 22,
  },
  icon: {
    visibility: 'hidden',
  },
  field: {},
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  progressStyle: {
    position: 'absolute',
    top: 20,
    left: 14,
  },
  criticalChip: {
    fontSize: '16px',
    width: '150px',
  },
  cellCenter: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    textAlign: 'center',
    marginTop: '8px',
  },
});

// ---------------------------------------------------------------------------------
export default function GroupedSummaryTable(props) {
  const classes = useStyles();
  const { locationData } = props;
  const { customerId } = useContext(UserContext);
  const [gridApi, setGridApi] = useState(null);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(null);
  const [query, setQuery] = useState('');
  const [columnDefs, setColumnDefs] = useState([]);
  const dispatch = useDispatch();
  const [openColSettingsDialog, setOpenColSettingsDialog] = useState(false);

  // ------------------------------------------------------------------------------

  const handleRefresh = () => {
    // dispatch({type:"TOGGLE"})
    setRefreshLoading(true);
    gridApi?.refreshInfiniteCache();
    setTimeout(() => setRefreshLoading(false), 2000);
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.gridOptionsWrapper.gridOptions.context = {
        ...gridApi.gridOptionsWrapper.gridOptions.context,
        location_uuids: locationData,
      };
      gridApi.purgeInfiniteCache();
    }
  }, [locationData]);

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem('nms-groupsummary-inventory-column'));
    if (stored === null) {
      setColumnDefs(columnHeaders);
    } else {
      setColumnDefs(getSortedColumnDef(columnHeaders, stored));
    }
  }, []);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    return params.api.setDatasource({
      rowCount: null,
      context: {
        global_keyword: query,
      },
      getRows: async (_params) => {
        setRefreshLoading(true);
        params.api.hideOverlay();
        const payload = {
          ..._params,
          global_keyword: _params.context.global_keyword,
          location_uuids: _params.context.location_uuids,
          filterModel: {
            ..._params.filterModel
          },
        };
        delete payload.context;
        getGroupSummaryChangeEvents(customerId, payload).then((inventoryData) => {
          let lastRow = -1;
          if (inventoryData.total <= _params.endRow) {
            lastRow = inventoryData.total;
          }
          setTotalItems(inventoryData.total);
          if (inventoryData.total === 0) {
            params.api.showNoRowsOverlay();
          }
          _params.successCallback(inventoryData.data, lastRow);
          setRefreshLoading(false);
        })
          .catch((e) => {
            setRefreshLoading(false);
            params.failCallback();
          });
      },
    });
  }, []);

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    gridApi?.purgeInfiniteCache();
  };

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      floatingFilter: true,
      suppressMenu: true,
      filter: true,
    };
  }, []);

  const columnHeaders = [
    {
      headerName: 'Event Date',
      colId: 'g_created_at',
      sortable: false,
      filter: false,
      width: 188,
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return <Skeleton variant="rectangular" height={30} style={{ margin: "7px 0" }} />
        }
        return params.data?.g_created_at;
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.g_created_at;
      },
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Device Type',
      colId: 'l_dvc_typ_name',
      sortable: true,
      width: 200,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return toCamelizeWords(params.data?.r_grp_smry_device_type);
      },
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Affected Devices',
      colId: 'r_dvc_description',
      sortable: true,
      width: 300,
      filter: false,
      floatingFilter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellRenderer: 'affectedRenderer',
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Status',
      colId: 'r_dvc_up_down',
      width: 200,
      sortable: false,
      filter: false,
      floatingFilter: false,
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
      cellRenderer: 'statusRenderer',
    },
    {
      headerName: 'Details',
      colId: 'details',
      width: 600,
      sortable: false,
      filter: false,
      floatingFilter: false,
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params.data?.r_criticality_message) {
          return params.data?.r_criticality_message;
        }
        return '';
      }
    },
    {
      headerName: 'Change Since',
      colId: 'r_config_change_since',
      width: 188,
      // pinned: 'right',
      filter: false,
      sortable: false,
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        let since = '';
        if (params.data?.r_config_change_since) {
          since = `${moment(new Date(params.data?.r_config_change_since)).calendar().split(" at")[0]}`;
          return (<div>
            <Tooltip title={fDateTime((params.data?.r_config_change_since))}>
              <Typography sx={{ fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' }}>{since}</Typography>
            </Tooltip>
          </div>
          )
        }
      },
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Actions',
      colId: 'r_dvc_eos',
      // pinned: 'right',
      sortable: false,
      filter: false,
      width: 200,
      floatingFilter: false,
      cellRenderer: 'actionRenderer',
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
    },
  ];

  const components = useMemo(() => ({
    actionRenderer: ActionRenderer,
    affectedRenderer: AffectedRenderer,
    statusRenderer: StatusRenderer,
  }), []);

  const resetColumnSetting = () => {
    gridApi.setColumnDefs([]);
    setColumnDefs([...columnHeaders]);
    gridApi.setColumnDefs([...columnHeaders]);
    localStorage.setItem('nms-groupsummary-inventory-column', JSON.stringify(mapColumnDefinitionToStore(columnHeaders)));
    setOpenColSettingsDialog(false);
  };

  const onDragStopped = (params) => {
    const columnState = params.columnApi.getColumnState();
    const _afterDragColumnDefs = columnState.reduce((a, b) => {
      const a1 = columnHeaders.find((e) => e.colId === b.colId);
      return a.concat(Object.assign(a1, b));
    }, []);
    setColumnDefs([..._afterDragColumnDefs]);
    localStorage.setItem(
      'nms-groupsummary-inventory-column',
      JSON.stringify(mapColumnDefinitionToStore(_afterDragColumnDefs))
    );
  };

  const handleClickOpenColSettingsDialog = () => {
    setOpenColSettingsDialog(true);
  };

  return (
    <>
      <Card elevation={0} sx={{ borderColor: 'none', boxShadow: 'none' }}>
        <CardHeader
          style={{ padding: '10px' }}
          avatar={
            <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '5px' }}>
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Chip label={totalItems || 0} />
                {refreshLoading && <CircularProgress size={32} className={classes.progressStyle} />}
              </Grid>
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Typography variant="h5" gutterBottom sx={{ marginTop: '8px' }}>
                  Change events
                </Typography>
              </Grid>
            </Grid>
          }
          action={
            <Grid
              container
              spacing={0}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
                paddingTop: '10px',
              }}
            >
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <TextField
                  focused
                  autoFocus
                  type="text"
                  size="small"
                  value={query}
                  variant="outlined"
                  name='new-password'
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search devices"
                />
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <IconButton color="primary" onClick={handleRefresh} sx={{ mt: '-5px' }}>
                  {refreshLoading ? (
                    <CircularProgress size={27} sx={{ mr: '5px' }} />
                  ) : (
                    <Refresh sx={{ fontSize: '2rem' }} />
                  )}
                </IconButton>
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px', ml: -1, mr: 1 }}
              >
                <Tooltip title="Column settings">
                  <IconButton color="primary" onClick={handleClickOpenColSettingsDialog}>
                    <Settings sx={{ fontSize: '2rem' }} />
                  </IconButton>
                </Tooltip>
                {openColSettingsDialog && (
                  <ColumnSettingsDialog
                    gridApi={gridApi}
                    columnDefs={columnDefs}
                    openDialog={openColSettingsDialog}
                    resetColumnSetting={resetColumnSetting}
                    handleClose={() => setOpenColSettingsDialog(false)}
                  />
                )}
              </Grid>
            </Grid>
          }
        />

        <Divider />
        {refreshLoading && <LinearProgress />}

        <Box style={{ height: '500px', width: '100%' }} className="ag-theme-material">
          <AgGridReact
            onGridReady={onGridReady}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onDragStopped={onDragStopped}
            components={components}
            {...defaultAgGridProps}
            context={{
              global_keyword: query,
              location_uuids: locationData,
            }}
          />
        </Box>
      </Card>
    </>
  );
}
