// @mui
import {
    Grid,
  } from '@mui/material';
import ISPDownTable from '../../ISPManagementTable/ISPManagementDashboard/ISPDownTable';
  // components
  // ----------------------------------------------------------------------
  
  export default function SidewiseDown() {
    return (
      <>
           <Grid item lg={12} sm={12} xs={12} md={12} xl={12} sx={{py:2}}>
             <ISPDownTable />
           </Grid>
      </>
    );
  }
  