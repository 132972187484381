import { Delete, Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from 'notistack';
import PropTypes from "prop-types";
import { useContext, useState } from 'react';
import { deleteMember } from '../../../../API/Members/members';
import { UserContext } from '../../../../context/UserContext';
import { WithConfirm } from '../../../WithConfirm';
import EditMemberDialog from '../EditMemberDialog';

// -------------------------------------------------------------------------------

const ActionsRenderer = (props) => {
  const { data, confirm } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [editMembersData, setEditMembersData] = useState({});
  const [editMembersDialog, setEditMembersDialog] = useState(false);

  // -------------------------------------------------------------------------------

  const handleDelete = (value) => {
    deleteMember(customerId, value.uuid)
      .then(() => {
        props.api.purgeInfiniteCache();
        enqueueSnackbar(
          'Member deleted sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  const handleEdit = (value) => {
    setEditMembersData(value);
    setEditMembersDialog(true);
  };

  // -------------------------------------------------------------------------------

  if (data === undefined) {
    return '';
  }
  return (
    <>
      <Tooltip title="Edit">
        <IconButton onClick={() => handleEdit(data)}>
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          onClick={confirm(() => handleDelete(data), {
            title: 'Information',
            confirmationText: 'Yes',
            disableOkBtn: false,
            description: 'Are you sure you want to delete this member ?',
          })}
        >
          <Delete />
        </IconButton>
      </Tooltip>
      {editMembersDialog && (
        <EditMemberDialog
          member={editMembersData}
          openDialog={editMembersDialog}
          handleClose={() => setEditMembersDialog(false)}
          gridApi={props.api}
        />
      )}
    </>
  );
};

ActionsRenderer.propTypes = {
  data: PropTypes.object,
  api: PropTypes.object, 
  confirm: PropTypes.func.isRequired,
};

export default WithConfirm(ActionsRenderer);
