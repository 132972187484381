import { Link } from 'react-router-dom';
import { Breadcrumbs, Container, Typography } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Page from '../components/Page';
import { EndpointsComponents } from '../components/EndpointsComponents';

// ----------------------------------------------------------------------

export default function Endpoint() {
  return (
    <Page title="Endpoint">
      <Container maxWidth={false}>
        <div role="presentation" style={{   paddingBottom: '20px', display: 'flex', alignItems: 'center',position:'relative'}}>
          <Breadcrumbs aria-label="breadcrumb">
          <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5, cursor: 'pointer' }}>
              <HomeOutlinedIcon />
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/inventory" sx={{ cursor: 'pointer'}}>
              Inventory
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/endpoint" sx={{ cursor: 'pointer'}}>
            Client devices
            </Typography>
          </Breadcrumbs>
        </div>
        <EndpointsComponents />
      </Container>
    </Page>
  );
}
