import { Add, CheckBox, CheckBoxOutlineBlank, Close, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import { DataGrid, useGridApiContext } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { getDeviceTypes } from '../../API/DeviceTypes/index';
import { getLocationsWithoutPagination } from '../../API/Locations/showLocations';
import { createNewDevice } from '../../API/NetworkDevices/networkDevices';
import { UserContext } from '../../context/UserContext';
import { toCamelizeWords } from '../../utils/formatString';
// ---------------------------------------------------------------------------------------

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

// ------------------------------------------------------------------------------------
const CustomDeviceTypeComponent = (props) => {
  const filter = createFilterOptions();
  const apiRef = useGridApiContext();
  const [deviceType, setDeviceType] = useState(props.deviceType)

  useEffect(() => {
    setDeviceType(props.deviceType)
  }, [props.deviceType])

  return (
    <Autocomplete
      size="small"
      fullWidth
      clearOnBlur
      limitTags={4}
      handleHomeEndKeys
      disableCloseOnSelect
      options={deviceType}
      loading={props.loading}
      onChange={(event, newValue) => {
        apiRef.current.setEditCellValue({ id: props.id, field: "type_name", value: newValue });
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered;
      }}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected }, ind) => {
        if (option?.label) {
          return (
            <li key={`${option._uid}${option.r_dvc_typ_vendor}`} {...props} style={{ width: '600px' }}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {option.label}
              {option.r_dvc_typ_vendor ? ` (${option.r_dvc_typ_vendor})` : ''}
            </li>
          );
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select device type"
          InputLabelProps={{ shrink: true }}
          autoComplete="off"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

// ----------------------------------------------------------------------------------
const CustomLocationSelectComponent = (props) => {
  const filter = createFilterOptions();
  const apiRef = useGridApiContext();
  const [locationValue, setLocationValue] = useState(props.locationData);

  useEffect(() => {
    setLocationValue(props.locationData)
  }, [props.locationData])

  return (
    <Autocomplete
    size="small"
    fullWidth
    limitTags={4}
    clearOnBlur
    handleHomeEndKeys
    loading={props.loading}
    disableCloseOnSelect
      options={locationValue}
      onChange={(event, newValue) => {
        apiRef.current.setEditCellValue({ id: props.id, field: "location", value: newValue });
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered;
      }}
      getOptionLabel={(option) => option.r_location_name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.r_location_name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select device type"
          InputLabelProps={{ shrink: true }}
          autoComplete="off"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
// ----------------------------------------------------------------------------------

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  addButton: {
    fontSize: '1.7rem',
    padding: '2px',
    borderRadius: '0px',
    backgroundColor: '#f5f5f5',
    color: '#616161'
  },
  stackContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  CustomeHeaderBgcolor: {
    backgroundColor: '#E2E2E2',
    fontWeight: 900,
    fontSize: '1rem',
  },
  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none",
    focus: "none",
  },
});


const rows = {
  id: 1,
  name: '',
  model: '',
  mac_address: '',
  serial_number: '',
  ip_address: '',
  description: '',
  type: 'NETWORK',
  type_source: 'MANUAL',
  type_name: '',
  os: '',
  vendor: '',
  location: '',
  location_uuid: ''
}

// -------------------------------------------------------------------------------
export default function AddNewDeviceDialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const { openDialog, handleClose } = props;
  const [rowData, setRowData] = useState([rows]);
  const [loading, setLoading] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [deviceType, setDeviceType] = useState([]);
  const ipValidatorRegexp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;


  // ----------------------------------------------------------------------------
  const fetchDeviceType = async () => {
    getDeviceTypes(customerId)
      .then((res) => {
        setDeviceType(
          res
            .sort((a, b) => (a.r_dvc_typ_name > b.r_dvc_typ_name ? 1 : -1))
            .map((item) => {
              return {
                label: toCamelizeWords(item.r_dvc_typ_name),
                ...item,
              };
            })
            .filter(
              (item) =>
                item.r_dvc_typ_vendor !== '' && item.r_dvc_typ_name !== 'STACK' && item.r_dvc_typ_name !== 'SWITCH_OSCX'
            )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadLoactionOptions = async () => {
    getLocationsWithoutPagination(customerId)
      .then((res) => {
        setLocationData(res.data.sort((a, b) => a.r_location_name - b.r_location_name));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchDeviceType();
    loadLoactionOptions();
  }, [customerId]);

  const columns = [
    {
      headerName: 'Id',
      field: 'params.row.id',
      width: 65,
      key: 1,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1
      },
      headerClassName: classes.CustomeHeaderBgcolor,
    },
    {
      field: 'ip_address',
      headerName: 'IP address *',
      width: 200,
      key: 6,
      editable: true,
      sortable: false,
      renderCell: (params) => {
        const invalid = ipValidatorRegexp.test(params.row.ip_address);
        if (invalid === true) {
          return params.row.ip_address;
        }
        if (invalid === false) {
          params.row.ip_address = '';
          return <span style={{ color: 'red' }}>Enter valid ip address</span>;
        }
      },
      headerClassName: classes.CustomeHeaderBgcolor,
    },
    {
      field: 'mac_address',
      headerName: 'Mac address *',
      width: 200,
      key: 4,
      editable: true,
      sortable: false,
      headerClassName: classes.CustomeHeaderBgcolor,
    },
    {
      field: 'serial_number',
      headerName: 'Serial number *',
      width: 200,
      key: 5,
      editable: true,
      sortable: false,
      headerClassName: classes.CustomeHeaderBgcolor,
    },
    {
      field: 'type_name',
      headerName: 'Device type *',
      width: 250,
      key: 9,
      editable: true,
      sortable: false,
      headerClassName: classes.CustomeHeaderBgcolor,
      renderCell: (params) => {
        return params.row.type_name.r_dvc_typ_name;
      },
      renderEditCell: (params) => <CustomDeviceTypeComponent {...params} loading={loading} deviceType={deviceType} />,
    },
    {
      field: 'location',
      headerName: 'Location *',
      width: 250,
      key: 12,
      editable: true,
      sortable: false,
      headerClassName: classes.CustomeHeaderBgcolor,
      renderCell: (params) => {
        return params.row.location.r_location_name;
      },
      renderEditCell: (params) => <CustomLocationSelectComponent {...params} loading={loading} locationData={locationData} />,
    },
    {
      field: 'name',
      headerName: 'Device name',
      width: 200,
      key: 2,
      editable: true,
      sortable: false,
      headerClassName: classes.CustomeHeaderBgcolor,
    },
    {
      field: 'model',
      headerName: 'Device model',
      width: 200,
      key: 3,
      editable: true,
      sortable: false,
      headerClassName: classes.CustomeHeaderBgcolor,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 200,
      key: 7,
      editable: true,
      sortable: false,
      headerClassName: classes.CustomeHeaderBgcolor,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 200,
      key: 8,
      hide: true,
      editable: false,
      sortable: false,
      headerClassName: classes.CustomeHeaderBgcolor,
    },
    {
      field: 'type_source',
      headerName: 'Type source',
      width: 200,
      hide: true,
      editable: false,
      sortable: false,
      headerClassName: classes.CustomeHeaderBgcolor,
    },
    {
      field: 'vendor',
      headerName: 'Vendor',
      sortable: false,
      width: 200,
      key: 10,
      editable: false,
      renderCell: (params) => {
        return params.row.type_name.r_dvc_typ_vendor;
      },
      headerClassName: classes.CustomeHeaderBgcolor,
    },
    {
      field: 'os',
      sortable: false,
      headerName: 'OS',
      width: 200,
      key: 11,
      editable: false,
      renderCell: (params) => {
        return params.row.type_name.r_dvc_typ_os;
      },
      headerClassName: classes.CustomeHeaderBgcolor,
    },
    {
      field: 'location_uuid',
      headerName: 'Location UUID *',
      width: 200,
      key: 13,
      hide: true,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return params.row.location._uid;
      },
      headerClassName: classes.CustomeHeaderBgcolor,
    },
    {
      field: 'action',
      headerName: '',
      width: 40,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return <IconButton onClick={() => { handleDeleteRow(params.row.id) }}>
          <Tooltip title="Delete this row">
            <Delete sx={{ fontSize: '1.5rem' }} />
          </Tooltip>
        </IconButton>
      },
      headerClassName: classes.CustomeHeaderBgcolor,
    },
  ];

  const handleAddRow = (params) => {
    const newId = rowData.length + 1;
    setRowData([...rowData, {
      id: newId, name: '', model: '', type: 'NETWORK', mac_address: '', serial_number: '', ip_address: '',
      description: '', os: '', type_source: 'MANUAL', type_name: '', location: '', vendor: '', location_uuid: ''
    }]);
  };

  const handleDeleteRow = (id) => {
    setRowData(rowData.filter((row) => row.id !== id));
  };

  const updateRowItem = ({ id, field, value }) => {
    setRowData((_rowData) => {
      return [..._rowData.map((item) => {
        if (item.id === id) {
          item[field] = value
        }
        return item
      })]
    });
  };

  const _addNewDevice = async (value) => {
    const data = {
      ip_address: value.ip_address,
      serial_number: value.serial_number,
      mac_address: value.mac_address,
      name: value.name,
      model: value.model,
      type: value.type,
      description: value.description,
      type_source: value.type_source,
      type_name: value.type_name.r_dvc_typ_name,
      os: value.type_name.r_dvc_typ_os,
      vendor: value.type_name.r_dvc_typ_vendor,
      location: value.location.r_location_name,
      location_uuid: value.location._uid,
    };
    return createNewDevice(customerId, data)
  };

  const handleSubmit = async (value) => {
    setLoading(true)
    const mapRequst = rowData.map((item) => {
      return _addNewDevice(item)
    })
    Promise.all(mapRequst)
      .then((res) => {
        setLoading(false)
        handleClose();
        enqueueSnackbar(
          'Device added sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setLoading(false)
        handleClose();
        enqueueSnackbar(
          res.response.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  }
  // -------------------------------------------------------------------------------
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <DialogTitle>
          <Stack direction="row" spacing={1} className={classes.stackContainer}>
            <Box>
              Add new device as network
              <Typography variant='subtitle2' gutterBottom>
                Fields with * are mandatory
              </Typography>
            </Box>
            <IconButton onClick={handleClose} sx={{ float: 'right' }}>
              <Close sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </Stack>
        </DialogTitle>

        {loading && <LinearProgress />}
        <Divider />

        <DialogContent sx={{ px: 1.5, py: 0 }}>
          <Stack>
            <Box>
              <IconButton onClick={handleAddRow} sx={{ float: 'right' }}>
                <Tooltip title="Add new row">
                  <Add className={classes.addButton} />
                </Tooltip>
              </IconButton>
            </Box>
            <Box sx={{ height: 250 }}>
              <DataGrid
                rows={rowData}
                columns={columns}
                disableColumnMenu
                getRowId={(rows) => rows.id}
                onCellEditCommit={(params, event) => {
                  updateRowItem(params)
                }}
              />
            </Box>
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton variant="contained" color="primary" type="submit" loading={loading} onClick={() => { handleSubmit() }}>
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}