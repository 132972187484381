import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { UserContextProvider } from '../../context/UserContext';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import Support from './Support/Support';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  display: 'flex',
  height: 'calc(100vh - 10px)',
  overflow: 'hidden',
});

const NavStyle = styled('div')(({ theme, menu }) => ({
  top: 0,
  zIndex: 100,
  flexGrow: 1,
  flexShrink: 2,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  width: '96%',
  overflow: 'hidden',
  position: 'fixed',
  backgroundColor: theme.palette.background.default,
  ...(menu
    ? {
        width: 'calc(100vw - 90px)',
      }
    : {
        width: 'calc(100vw - 280px)',
      }),
}));

const MainStyle = styled('div')({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
});

const OutletStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(14),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {

  const [menu, setMenu] = useState(false);

  const menuHandler = () => {
    setMenu(!menu);
    localStorage.setItem("sidebarCollapsed" , !menu );
  };

  const stored = JSON.parse(localStorage.getItem('sidebarCollapsed'));

  useEffect(() => {
    if (stored === null) {
      setMenu(false);
    } else {
      setMenu(menu);
    }
  }, [menu, stored]);


  return (
    <RootStyle>
      <UserContextProvider>
        <DashboardSidebar menu={menu} menuHandler={menuHandler} />
        <MainStyle>
          <NavStyle menu={menu}>
            <DashboardNavbar menuHandler={menuHandler} menu={menu} />
          </NavStyle>
          <OutletStyle>
            <Outlet />
          </OutletStyle>
        </MainStyle>
        <Support />
      </UserContextProvider>
    </RootStyle>
  );
}
