// material
import { Breadcrumbs, Container, Typography } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import AlertComponents from '../components/AlertsComponents/AlertsComponents';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Alerts() {
  return (
    <Page title="Device up-down">
      <Container maxWidth={false}>
        <div role="presentation" style={{ display: 'flex', alignItems: 'center',position:'relative', paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5, cursor: 'pointer'}}>
              <HomeOutlinedIcon />
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" sx={{ cursor: 'pointer'}} to="/dashboard/device-up-down">
              Logs
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" sx={{ cursor: 'pointer'}} to="/dashboard/device-up-down">
              Device Up-Down
            </Typography>
          </Breadcrumbs>
        </div>

        <AlertComponents />
      </Container>
    </Page>
  );
}
