import { Close, HorizontalRule, MyLocation } from '@mui/icons-material';
import { IconButton, List, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Scrollbar from '../../../Scrollbar';
import { useMapSettingContext } from '../../components/RelatedToGraph/mapSettingProvider';



const useStyles = makeStyles(() => ({
  remoteContainer: {
    position: 'relative',
    width: '200px',
    display: 'flex',
    flexDirection: 'column',
  },
  legendContainer: {
    display: "flex",
    alignItems: "center",
    "& span": {
      fontSize: 14
    }
  },
  iconContainer: {
    padding: '4px',
    borderRadius: '50px',
    height: 30,
    width: 30,
    margin: "5px 10px"
  },
  circleDown: {
    animationName: '$blinkerDown',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  circleDanggled: {
    animationName: '$blinkerFewDown',
    animationDuration: '2s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  '@keyframes blinkerDown': {
    from: { fill: "rgb(255 0 0)" },
    to: { fill: "#000000" },
  },
  '@keyframes blinkerFewDown': {
    from: { fill: "rgb(255, 191, 0)" },
    to: { fill: "#000000" },
  },
}));

function LegendControl() {
  const classes = useStyles()
  const { mapSettingDispatch } = useMapSettingContext()

  const handleShowLegend = () => {
    mapSettingDispatch({ type: 'TOGGLE_GLOBAL_SHOW_LEGEND' })
  };

  return (
    <Paper elevation={0} className={classes.remoteContainer}>
      <div style={{textAlign:'right', position:"absolute", right:0, zIndex:1}}>
        <IconButton onClick={handleShowLegend}>
          <Close />
        </IconButton>
      </div>

      <Scrollbar>
        <List>
          <Typography gutterBottom sx={{ pl: 2  }}>Locations:</Typography>
          <div style={{ padding: '0px' }}>
            <div className={classes.legendContainer}>
              <div className={classes.iconContainer}>
                <MyLocation color='success' />
              </div>
              <span>
                All devices up
              </span>
            </div>
          </div>

          <div style={{ padding: '0px' }}>
            <div className={classes.legendContainer}>
              <div className={classes.iconContainer}>
                <MyLocation color='error' className={classes.circleDown}/>
              </div>
              <span>
                All devices down
              </span>
            </div>
          </div>

          <div style={{ padding: '0px' }}>
            <div className={classes.legendContainer}>
              <div className={classes.iconContainer}>
                <MyLocation color='warning' className={classes.circleDanggled}/>
              </div>
              <span>
                Few devices down
              </span>
            </div>
          </div>

          <Typography gutterBottom sx={{ pt: 1, pl: 1 }}>VPN connections:</Typography>

          <div style={{ padding: '0px' }}>
            <div className={classes.legendContainer}>
              <div className={classes.iconContainer}>
                <HorizontalRule color='success' />
              </div>
              <span>
                All up
              </span>
            </div>
          </div>

          <div style={{ padding: '0px' }}>
            <div className={classes.legendContainer}>
              <div className={classes.iconContainer}>
                <HorizontalRule color='error' />
              </div>
              <span>
                All down
              </span>
            </div>
          </div>

          <div style={{ padding: '0px' }}>
            <div className={classes.legendContainer}>
              <div className={classes.iconContainer}>
                <HorizontalRule color='warning' />
              </div>
              <span>
                Few down
              </span>
            </div>
          </div>
        </List>
      </Scrollbar>
    </Paper>
  );
}

export default LegendControl;