import React from 'react';
import { Typography, Breadcrumbs, Container, Grid, Divider } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import RolesComponents from '../components/Roles/RolesComponents';

// ----------------------------------------------------------------------

export default function Roles() {
  return (
    <Page title="Roles">
      <Container maxWidth={false}>
        <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '32px' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
              <HomeOutlinedIcon />
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/settings">
              Settings
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/roles">
              Roles
            </Typography>
          </Breadcrumbs>
          <Divider sx={{ marginTop: '15px', width: '100%' }} />
        </div>

        <Grid container>
          <RolesComponents />
        </Grid>
      </Container>
    </Page>
  );
}
