import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Breadcrumbs, CircularProgress, Container, Grid, Typography, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getUser } from '../API/User/User';
import IgnoreRuleComponents from '../components/IgnoreRuleComponents/IgnoreRuleComponents';
import NotAuthorized from '../components/NotAuthorized';
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function IgnoreRule() {
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});

  const getUsers = async () => {
    getUser().then((res) => {
      setUserInfo(res.user);
      setLoading(false)
    });
  };
  
  useEffect(() => {
    getUsers();
  }, [])

  const isAccessible = () => {
    if (userInfo?.roles) {
      if (userInfo?.roles[0]?.permissions) {
        return userInfo?.roles[0].permissions.filter(t => t.name === "NMS_PORTAL_DEVICE_CONFIG_IGNORE_RULES_READ").length
      }
    }
    return true;
  }

  const renderTable = () => {
    if (isAccessible()) {
      return <IgnoreRuleComponents />
    }
    return <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12}>
        <NotAuthorized />
      </Grid>
    </Grid>

  }

  return (
    <Page title="Ignore Rule">
      <Container maxWidth={false}>
        <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '32px' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
              <HomeOutlinedIcon />
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/settings">
              Settings
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/ignore rule">
              Ignore Rule
            </Typography>
          </Breadcrumbs>
          <Divider sx={{ marginTop: '15px', width: '100%' }} />
        </div>

        <Grid container>
          {loading && <CircularProgress />}
          {!loading && renderTable()}
        </Grid>
      </Container>
    </Page>
  );
}
