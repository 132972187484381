import { Grid } from '@mui/material';
import SummarySuccessFailChart from './SummarySuccessFailChart';
import DeviceTypeChart from './DeviceTypeChart';



export default function CMDBCharts(props) {
  const { locationData, selectDeviceType } = props;
    

    return (
      <Grid container item spacing={4}>
        <Grid item lg={6} sm={12} xs={12} md={6}>
          <DeviceTypeChart
           locationData={locationData}
           selectDeviceType={selectDeviceType}
          />
        </Grid>
  
        <Grid item lg={6} sm={12} xs={12} md={6}>
          <SummarySuccessFailChart />
        </Grid>
      </Grid>
    );
  }
  
