import modem from '../modem';

export const changePassword = async (data) => {
  const newPromise =  modem
  .post(`/user/change-password?current_password=${data?.current}&password=${data?.password}&password_confirmation=${data?.confirm}`, data)
    .then((response) => {
      localStorage.setItem('AuthorizationToken', JSON.stringify(response.data?.data?.authorization));
      return response.data.data;
    })
    .catch((response) => {
      throw response;
    });
  return newPromise;
};
export const checkUserEmail = async (id,data) => {
  const newPromise =  modem
  .post(`${id}/members/email-availability`, data)
    .then((response) => {
      return response;
    })
    .catch((response) => {
      throw response;
    });
  return newPromise;
};
