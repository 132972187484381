import { yupResolver } from '@hookform/resolvers/yup';
import { Close } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, LinearProgress, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { editMember } from '../../../API/Members/members';
import { UserContext } from '../../../context/UserContext';
import { FormProvider, RHFTextField } from '../../hook-form';

//----------------------------------------------------

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: "flex-start"
  },
  topPaperScrollBody: {
    verticalAlign: "top"
  }
});

//----------------------------------------------------

export default function EditMemberDialog(props) {
  const classes = useStyles();
  const { openDialog, handleClose, member, gridApi } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  //----------------------------------------------------

  const membersSchema = Yup.object().shape({
    name: Yup.string().required('Name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const defaultValues = {
    name: member.name,
    email: member.email,
  };

  const methods = useForm({
    resolver: yupResolver(membersSchema),
    defaultValues,
  });

  const {
    handleSubmit,
  } = methods;

  const onSubmit = async (formData) => {
    const data = {
      name: formData.name,
      email: formData.email
    };
    setLoading(true);
    editMember(customerId, member.uuid, data)
      .then(() => {
        setLoading(false);
        gridApi.purgeInfiniteCache();
        handleClose();
        enqueueSnackbar(
          'Member updated sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  //----------------------------------------------------

  return (
    <>
      <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="sm" aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
            Edit member
            <IconButton onClick={handleClose} sx={{ float: 'right' }}>
              <Close sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </DialogTitle>
          <Divider />
          {loading && <LinearProgress />}
          <DialogContent sx={{ py: 3, px: 3 }}>
            <Stack spacing={3}>
              <RHFTextField name="name" label="Name *" placeholder="Enter name" />
              <RHFTextField name="email" label="Email address *" placeholder="Enter email address" />
            </Stack>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Save
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
}

EditMemberDialog.propTypes = {
  member: PropTypes.object,
  gridApi: PropTypes.object,
  openDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
