export const locationsData = [
    {
        id: 1, 
        series: [70, 30]
    },
    {
        id: 2, 
        series: [20, 80]
    },
    {
        id: 3, 
        series: [30, 70]
    },
    {
        id: 4, 
        series: [90, 10]
    },
    {
        id: 5, 
        series: [75, 25]
    },
]

  export const vendorLocationsData = [
    {
        id: 1, 
        series: [200, 300, 400]
    },
    {
        id: 2, 
        series: [100, 200, 300]
    },
    {
        id: 3, 
        series: [400, 100, 50]
    },
    {
        id: 4, 
        series: [200, 300, 400]
    },
    {
        id: 5, 
        series: [300, 100, 700]
    },
]

export const endPoinrByOSLocationsData = [
    {
        id: 1, 
        series: [10,15,2,15,8,8,50]
    },
    {
        id: 2, 
        series: [20,35,2,1,8,8,75]
    },
    {
        id: 3, 
        series: [3,45,20,5,8,28,40]
    },
    {
        id: 4, 
        series: [20,5,14,28,20,20,30]
    },
    {
        id: 5, 
        series: [10,15,2,15,8,8,50]
    },
]

export const endPoinrByFamilyLocationsData = [
    {
        id: 1, 
        series: [12,4,20,15,8,30,50]
    },
    {
        id: 2, 
        series: [10,40,2,1,8,8,50]
    },
    {
        id: 3, 
        series: [23,5,20,25,28,8,40]
    },
    {
        id: 4, 
        series: [20,5,14,28,20,20,30]
    },
    {
        id: 5, 
        series: [10,15,2,15,8,8,50]
    },
]

export const sitewiseLocationData = [
    {
        id: 1, 
        jitterSeries: [12,4,20,15,8,30,50],
        latenctySeries: [12,4,20,15,8,30,50],
        upDownSeries: [1,0,1,0.1,0,1],
        utilizationSeries: [12,4,20,15,8,30,50],
    },
    {
        id: 2, 
        jitterSeries: [10,40,2,1,8,8,50],
        latenctySeries: [10,40,2,1,8,8,50],
        upDownSeries: [1,0,1,0.1,0,1],
        utilizationSeries: [12,4,20,15,8,30,50],
    },
    {
        id: 3, 
        jitterSeries: [23,5,20,25,28,8,40],
        latenctySeries: [23,5,20,25,28,8,40],
        upDownSeries: [1,0,1,0.1,0,1],
        utilizationSeries: [12,4,20,15,8,30,50],
    },
    {
        id: 4, 
        jitterSeries: [20,5,14,28,20,20,30],
        latenctySeries: [20,5,14,28,20,20,30],
        upDownSeries: [1,0,1,0.1,0,1],
        utilizationSeries: [12,4,20,15,8,30,50],
    },
    {
        id: 5, 
        jitterSeries: [10,15,2,15,8,8,50],
        latenctySeries: [10,15,2,15,8,8,50],
        upDownSeries: [1,0,1,0.1,0,1],
        utilizationSeries: [12,4,20,15,8,30,50],
    },
]

  