import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Box, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import BackupDialog from './BackupDialog';


const BackupRenderer = (props) => {
    const { data } = props;
    const [openChonkyDialog, setOpenChonkyDialog] = useState(false);

    const handleClickOpen = () => {
        setOpenChonkyDialog(true);
    };

    if (props.data === undefined) {
        return '';
    }

    if (data.r_path.length > 0) {
        return (
            <Box sx={{ textAlign: 'center' }}>
                <Tooltip title="View backup">
                    <IconButton onClick={handleClickOpen}>
                        <DescriptionOutlinedIcon />
                    </IconButton>
                </Tooltip>
                {openChonkyDialog && (
                    <BackupDialog openDialog={openChonkyDialog} data={data} handleClose={() => setOpenChonkyDialog(false)} />
                )}
            </Box>
        );
    }
    return 'Not available'
};


BackupRenderer.propTypes = {
    data: PropTypes.object,
};

export default BackupRenderer;
