import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import { BUCKET_NAME, downloadS3FileContent, fetchS3FileContent } from '../../../API/S3';
import IgnoreLineDialog from '../../IgnoreRuleComponents/Components/IgnoreLineDialog';
import SummaryDiff from '../../DevicewiseSummary/Components/renderer/ButtonDialogs/SummaryDiff';

//---------------------------------------------------------------------

const newStyles = {
  line: {
    wordBreak: 'break-word',
    padding: '10px 2px',
    '&:hover': {
      background: 'yellow',
    },
  },
  diffContainer: {
    borderStyle: 'solid',
    borderColor: 'black',
    borderWidth: '1px',
    overflowX: 'auto',
    display: 'block',
    '& pre': { whiteSpace: 'pre-wrap' },
  },
  diffRemoved: {
    overflowX: 'auto',
    maxWidth: 300,
  },
  diffAdded: {
    overflowX: 'auto',
    maxWidth: 300,
  },
};

//----------------------------------------------------------------------

export default function DiffDialog001(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { sourcePath, targetPath, data, cmdbGroupSummaryEvent } = props;
  const [loading, setLoading] = useState(true);
  const [sourceData, setSourceData] = useState('');
  const [targetData, setTargetData] = useState('');
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  const [openIgnore, setOpenIgnore] = useState(false);
  const [gridApi, setGridApi] = useState(null);

  const [diffExpanded, setDiffExpanded] = useState(true);
  const toggleDiffExpander = () => {
    setDiffExpanded(!diffExpanded)
  }

  const loadRemoteData = async (path) => {
    return fetchS3FileContent(BUCKET_NAME, path);
  };

  const loadSourceData = async (sourcePath) => {
    const _sourceData = await loadRemoteData(sourcePath);
    setSourceData(_sourceData);
    return Promise.resolve();
  };

  const loadTargetData = async (targetPath) => {
    const _targetData = await loadRemoteData(targetPath);
    setTargetData(_targetData);
    return Promise.resolve();
  };

  const sourceFileName = sourcePath.split('\\').pop().split('/').pop();
  const targetFileName = targetPath.split('\\').pop().split('/').pop();

  useEffect(() => {
    Promise.all([loadSourceData(sourcePath), loadTargetData(targetPath)]).then(() => {
      setLoading(false);
    });
  }, []);

  const downloadSrcTxtFile = async () => {
    const response = await downloadS3FileContent(BUCKET_NAME, sourcePath);
    const texts = [response.Body.toString()];
    const file = new Blob(texts, { type: 'text/plain' });
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = sourceFileName;
    document.body.appendChild(element);
    element.click();
    enqueueSnackbar('Downloaded successfully', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
    setTimeout(() => { }, 500);
  };

  const downloadTarTxtFile = async () => {
    const response = await downloadS3FileContent(BUCKET_NAME, targetPath);
    const texts = [response.Body.toString()];
    const file = new Blob(texts, { type: 'text/plain' });
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = targetFileName;
    document.body.appendChild(element);
    element.click();
    enqueueSnackbar('Downloaded successfully', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
    setTimeout(() => { }, 500);
  };

  const handleLineNumberClick = (lineId, event) => {
    // Handle line number click event here
    if (lineId) {
      setOpenIgnore(true)
    }
  };


  return (
    <>
      {loading && <LinearProgress />}
      <Box sx={{ p: 2, width: '100%' }}>

        <SummaryDiff cmdbGroupSummaryEvent={cmdbGroupSummaryEvent} />

        <Grid container>
          <Grid item lg={6} md={6} sm={6}>
            <Typography sx={{ pt: 1, pb: 1 }}>File name: {sourceFileName}
              <Tooltip title="Download file">
                <IconButton onClick={downloadSrcTxtFile} size="small">
                  <DownloadIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={6}>
            <Typography sx={{ pt: 1, pb: 1 }}>File name: {targetFileName}
              <Tooltip title="Download file">
                <IconButton onClick={downloadTarTxtFile} size="small">
                  <DownloadIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>

        <Box>
          {sourceData.length > 0 && targetData.length > 0 ? (
            <ReactDiffViewer
              compareMethod={DiffMethod.WORDS}
              oldValue={sourceData}
              newValue={targetData}
              styles={newStyles}
              leftTitle={'Golden configuration'}
              rightTitle={'Current configuration'}
              onLineNumberClick={handleLineNumberClick}
              splitView
            />
          ) : (
            ''
          )}
          {openIgnore && (
            <IgnoreLineDialog
              gridApi={gridApi}
              openDialog={openIgnore}
              handleCloses={() => setOpenIgnore(false)}
              data={data}
            />
          )}
        </Box>

      </Box>
    </>
  );
}
