/* eslint-disable */
import {
    Box,
    LinearProgress, Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { networkTable } from '../../../../../../API/NetworkDevices/health';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';



// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    height: 480,
}));

// ---------------------------------------


const NetworksTable = ({ networksExpanded }) => {
    const { healthDashState, getStartEnd } = useHealthContext()
    const { customerId } = useContext(UserContext);
    const [resData, setResData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [columnHeaders, setColumnHeaders] = useState([])

    // ---------------------------------

    const fetchData = useCallback(() => {
        if (!healthDashState.selectedDevice || networksExpanded === false) {
            return '';
        }
        const data = getStartEnd();
        const payload = {
            start: data.start / 1000,
            end: data.end / 1000,
            step: data.step,
            // device_type: healthDashState.selectedDevice.l_dvc_typ_name,
            device_os: healthDashState.selectedDevice.r_dvc_os,
        };
        setLoading(true);
        networkTable(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
            .then((res) => {
                setLoading(false);
                setResData(res?.data);
                if (res?.data?.length) {
                    setColumnHeaders(Object.keys(res?.data[0]).map((key) => {
                        return {
                            headerName: key,
                            field: key,
                            width: 180,
                            sortable: true,
                            filter: true,
                            cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 },
                        }
                    }))
                }
            })
            .catch(() => {
                setLoading(false);
            });
    }, [healthDashState.selectedDevice, networksExpanded, customerId]);

    useEffect(() => {
        fetchData();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, networksExpanded]);

    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            filter: true,
            sortable: true,
            suppressMenu: true,
            floatingFilter: false,
        };
    }, []);

    // -----------------------------------------------------------------------------------------------


    return (
        <>
            {loading && <LinearProgress sx={{ height: '2px' }} />}
            <Paper variant="outlined">
                <ChartWrapperStyle dir="ltr">
                    <Box style={{ height: '480px', width: '100%' }} className="ag-theme-material">
                        <AgGridReact
                            rowData={resData}
                            columnDefs={columnHeaders}
                            onGridReady={fetchData}
                            defaultColDef={defaultColDef}
                        />
                    </Box>
                </ChartWrapperStyle>
            </Paper>
        </>
    );
};
export default NetworksTable;
