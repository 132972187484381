import { ArrowCircleDownOutlined, ArrowCircleUpOutlined, KeyboardArrowDown, KeyboardArrowUp, Refresh } from '@mui/icons-material';
import { AppBar, Box, CircularProgress, Grid, IconButton, Paper, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { getNetworkStat } from '../../API/NetworkDevices/networkDevices';
import { getUser } from '../../API/User/User';
import AddNewDeviceDialog from '../../components/AddNewDevice/AddNewDeviceDialog';
import DecathlonLogo from '../../components/DecathlonLogo';
import { UserContext } from '../../context/UserContext';
import { EndPointIcon } from '../../icons';
import AccountPopover from './AccountPopover';
import Searchbar from './Searchbar';


// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  width: '100%',
  position: 'relative',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 1),
  },
}));

const useStyles = makeStyles({
  paperalignment: {
    minWidth: '10vw',
    textDecoration: 'none',
  },
  paperalignmentMobile: {
    minWidth: '5vw',
    textDecoration: 'none',
  },
  PaperStyling: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxPadding: {
    padding: '10px',
  },
  boxPaddingMobile: {
    padding: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardsContainer: {
    display: 'flex',
    gap: 10,
    marginRight: '8px',
    flexWrap: 'wrap',
  },
  cardsContainerMobile: {
    display: 'flex',
    gap: 6,
    marginRight: '8px',
    flexWrap: 'wrap',
  },
  icon: {
    visibility: 'hidden',
  },
  field: {},
  root: {
    padding: '5px',
    '&:hover $cardsContainer $icon': {
      visibility: 'visible',
    },
  },
  deviceUpIconButton: {
    color: 'green',
    background: '#c3e6c3',
    borderRadius: '12px',
    mr: 2,
  },
  deviceDownIconButton: {
    color: 'red',
    background: '#e6b8bf',
    borderRadius: '12px',
    mr: 2,
  },
  endpointsIconButton: {
    color: '#112627',
    background: '#56562f54',
    borderRadius: '12px',
    mr: 2,
  },
  loaderBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoBox: {
    padding: '15px',
    display: 'inline-flex',
  },
  logoBoxMobile: {
    padding: '2px',
    display: 'inline-flex',
  },
  gridCardsContainerMobile: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
  },
  gridCardsContainerDesktop: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    padding: '5px',
  },
  sidebarIconGridContainerMobile: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'left',
    padding: '5px',
  },
  sidebarIconGridContainerDesktop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    alignItems: 'center',
    paddingLeft: '5px',
    paddingRight: '15px',
  },
});

const DeviceUp = (props) => <Link to="/dashboard/dashboard/device-up" {...props} />;
const DeviceDown = (props) => <Link to="/dashboard/dashboard/device-down" {...props} />;
const Endpoints = (props) => <Link to="/dashboard/endpoint" {...props} />;

// ----------------------------------------------------------------------

export default function DashboardNavbar() {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [userInfo, setUserInfo] = useState({});
  const [userCustomer, setUserCustomer] = useState({});
  const [networkData, setNetworkData] = useState([]);
  const [cardLoading, setCardLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [online, setOnline] = useState(window.navigator.onLine);
  const onlineRef = useRef(window.navigator.onLine);
  const [openAddDeviceDialog, setOpenAddDeviceDialog] = useState(false);
  const refreshStats = useSelector(state => state.statCounter.refreshStats);
  const dispatch = useDispatch();
  const [showCards, setShowCards] = useState(true);

  const getUsers = async () => {
    getUser().then((res) => {
      setUserInfo(res.user);
      setUserCustomer(res.customer);
    });
  };

  useEffect(() => {
    getNetworkData();
    getUsers();
  }, [refreshStats])

  useEffect(() => {
    if (onlineRef.current) {
      closeSnackbar("online-snackbar")
    } else {
      enqueueSnackbar('You are offline', {
        key: "online-snackbar",
        variant: 'error',
        persist: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        action: (key) => (
          <Refresh onClick={() => { window.location.reload() }} />
        ),
      }
      );
    }
  }, [onlineRef.current])

  const handleRefresh = () => {
    if (onlineRef.current) {
      dispatch({ type: "TOGGLE" })
    }
  };

  const getNetworkData = useCallback(() => {
    setCardLoading(true);
    setRefreshLoading(true);
    getNetworkStat(customerId)
      .then((res) => {
        setNetworkData(res);
        setCardLoading(false);
        setRefreshLoading(false);
      })
      .catch(() => {
        setCardLoading(false);
        setRefreshLoading(false);
      });
  });

  const isOnline = () => {
    setOnline(true);
    onlineRef.current = true
    handleRefresh();
  };

  const isOffline = () => {
    onlineRef.current = false
    setOnline(false);
  };

  useEffect(() => {
    window.addEventListener('online', isOnline);
    window.addEventListener('offline', isOffline);
    const intervalId = setInterval(() => {
      handleRefresh();
    }, 45000);
    return () => {
      clearInterval(intervalId);
      window.removeEventListener('online', isOnline);
      window.removeEventListener('offline', isOffline);
    };
  }, []);

  const handleCloseAddDeviceDialog = () => {
    setOpenAddDeviceDialog(false);
  };

  const isBigScreen = useMediaQuery({ query: '(min-width: 1180px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1180px)' });

  // ------------------------------------------------------------------------
  return (
    <RootStyle>
      <ToolbarStyle>
        {isTabletOrMobile && (
          <Grid container id="topBar">
            <Grid item container className={classes.sidebarIconGridContainerMobile}>
              <Grid
                item
                container
                direction="row"
                spacing={1}
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                {/* <Button variant='outlined'onClick={(() => setOpenAddDeviceDialog(true))} >Add new device</Button> */}
                <Grid item>
                  <Searchbar />
                </Grid>
                {userInfo.email !== "rakesh@filoffee.ai" && <Grid item>
                  <Box className={classes.logoBoxMobile}>{userCustomer.logo ? <DecathlonLogo /> : ''}</Box>
                </Grid>}
                <Grid item>
                  <AccountPopover userInfo={userInfo} />
                </Grid>
                <Grid item>
                  <Tooltip title={"click to view devices status"}>
                    <IconButton onClick={() => setShowCards(!showCards)}>
                      {showCards ? <KeyboardArrowUp sx={{ fontSize: '1.8rem' }} /> : <KeyboardArrowDown sx={{ fontSize: '1.8rem' }} />}
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>

            {showCards && (
              <Grid item xs={12} className={classes.gridCardsContainerMobile}>
                <div className={classes.root}>
                  <div className={classes.cardsContainerMobile}>

                    <div className={classes.PaperStyling}>
                      <Paper className={classes.paperalignmentMobile} elevation={5} component={DeviceUp}>
                        <Stack className={classes.boxPaddingMobile} direction="row">
                          {online ? (
                            <Box>
                              <IconButton size="small" className={classes.deviceUpIconButton}>
                                {cardLoading ? <CircularProgress size={23} /> : <ArrowCircleUpOutlined />}
                              </IconButton>
                            </Box>
                          ) : (
                            <Box>
                              <IconButton size="small" className={classes.deviceUpIconButton}>
                                <ArrowCircleUpOutlined />
                              </IconButton>
                            </Box>
                          )}
                          <Typography color="green" variant="subtitle1" sx={{ px: '3px' }}>
                            {networkData.network_device_up}
                          </Typography>
                        </Stack>
                      </Paper>
                    </div>

                    <div className={classes.PaperStyling}>
                      <Paper className={classes.paperalignmentMobile} elevation={5} component={DeviceDown}>
                        <Stack className={classes.boxPaddingMobile} direction="row">
                          {online ? (
                            <Box>
                              <IconButton size="small" className={classes.deviceDownIconButton}>
                                {cardLoading ? <CircularProgress size={23} /> : <ArrowCircleDownOutlined />}
                              </IconButton>
                            </Box>
                          ) : (
                            <Box>
                              <IconButton size="small" className={classes.deviceDownIconButton}>
                                <ArrowCircleUpOutlined />
                              </IconButton>
                            </Box>
                          )}
                          <Typography color="error" variant="subtitle1" sx={{ px: '3px' }}>
                            {networkData.network_device_down}
                          </Typography>
                        </Stack>
                      </Paper>
                    </div>

                    <div className={classes.PaperStyling}>
                      <Paper className={classes.paperalignmentMobile} elevation={5} component={Endpoints}>
                        <Stack className={classes.boxPaddingMobile} direction="row">
                          {online ? (
                            <Box>
                              <IconButton size="small" className={classes.endpointsIconButton}>
                                {cardLoading ? <CircularProgress size={23} /> : <EndPointIcon />}
                              </IconButton>
                            </Box>
                          ) : (
                            <Box>
                              <IconButton size="small" className={classes.endpointsIconButton}>
                                <EndPointIcon />
                              </IconButton>
                            </Box>
                          )}
                          <Typography variant="subtitle1" sx={{ px: '3px' }}>
                            {networkData.total_endpoints}
                          </Typography>
                        </Stack>
                      </Paper>
                    </div>
                    {online && refreshLoading ? (
                      <Box className={classes.loaderBox}>
                        {refreshLoading ? (
                          <CircularProgress size={30} sx={{ ml: '3px', mr: '5px' }} />
                        ) : (
                          <IconButton
                            color="primary"
                            onClick={handleRefresh}
                            sx={{ mt: '-5px', ml: '-5px' }}
                            className={classes.icon}
                          >
                            <Refresh sx={{ fontSize: '2rem' }} />
                          </IconButton>
                        )}
                      </Box>
                    ) : (
                      <Box className={classes.loaderBox}>
                        <IconButton
                          color="primary"
                          onClick={handleRefresh}
                          sx={{ mt: '-5px', ml: '-5px' }}
                          className={classes.icon}
                        >
                          <Refresh sx={{ fontSize: '2rem' }} />
                        </IconButton>
                      </Box>
                    )}
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        )}

        {isBigScreen && (
          <Grid container id="topBar">

            <Grid item className={classes.gridCardsContainerDesktop}>
              <div className={classes.root}>
                <div className={classes.cardsContainer}>
                  <div className={classes.PaperStyling}>
                    <Paper className={classes.paperalignment} elevation={5} component={DeviceUp}>
                      <Stack className={classes.boxPadding} direction="row">
                        {online ? (
                          <Box>
                            <IconButton size="small" className={classes.deviceUpIconButton}>
                              {cardLoading ? <CircularProgress size={23} /> : <ArrowCircleUpOutlined />}
                            </IconButton>
                          </Box>
                        ) : (
                          <Box>
                            <IconButton size="small" className={classes.deviceUpIconButton}>
                              <ArrowCircleUpOutlined />
                            </IconButton>
                          </Box>
                        )}
                        <Box sx={{ px: 2 }}>
                          <Typography variant="subtitle1" component="div">
                            Devices Up
                          </Typography>
                          <Typography color="green" variant="subtitle1">
                            {networkData.network_device_up}
                          </Typography>
                        </Box>
                      </Stack>
                    </Paper>
                  </div>

                  <div className={classes.PaperStyling}>
                    <Paper className={classes.paperalignment} elevation={5} component={DeviceDown}>
                      <Stack className={classes.boxPadding} direction="row">
                        {online ? (
                          <Box>
                            <IconButton size="small" className={classes.deviceDownIconButton}>
                              {cardLoading ? <CircularProgress size={23} /> : <ArrowCircleDownOutlined />}
                            </IconButton>
                          </Box>
                        ) : (
                          <Box>
                            <IconButton size="small" className={classes.deviceDownIconButton}>
                              <ArrowCircleUpOutlined />
                            </IconButton>
                          </Box>
                        )}
                        <Box sx={{ px: 2 }}>
                          <Typography variant="subtitle1" component="div">
                            Devices Down
                          </Typography>
                          <Typography color="error" variant="subtitle1">
                            {' '}
                            {networkData.network_device_down}
                          </Typography>
                        </Box>
                      </Stack>
                    </Paper>
                  </div>

                  <div className={classes.PaperStyling}>
                    <Paper className={classes.paperalignment} elevation={5} component={Endpoints}>
                      <Stack className={classes.boxPadding} direction="row">
                        {online ? (
                          <Box>
                            <IconButton size="small" className={classes.endpointsIconButton}>
                              {cardLoading ? <CircularProgress size={23} /> : <EndPointIcon />}
                            </IconButton>
                          </Box>
                        ) : (
                          <Box>
                            <IconButton size="small" className={classes.endpointsIconButton}>
                              <EndPointIcon />
                            </IconButton>
                          </Box>
                        )}
                        <Box sx={{ px: 2 }}>
                          <Typography variant="subtitle1" component="div">
                            Client devices{' '}
                          </Typography>
                          <Typography>{networkData.total_endpoints}</Typography>
                        </Box>
                      </Stack>
                    </Paper>
                  </div>
                  {online && refreshLoading ? (
                    <Box className={classes.loaderBox}>
                      {refreshLoading ? (
                        <CircularProgress size={30} sx={{ ml: '3px', mr: '5px' }} />
                      ) : (
                        <IconButton
                          color="primary"
                          onClick={handleRefresh}
                          sx={{ mt: '-5px', ml: '-5px' }}
                          className={classes.icon}
                        >
                          <Refresh sx={{ fontSize: '2rem' }} />
                        </IconButton>
                      )}
                    </Box>
                  ) : (
                    <Box className={classes.loaderBox}>
                      <IconButton
                        color="primary"
                        onClick={handleRefresh}
                        sx={{ mt: '-5px', ml: '-5px' }}
                        className={classes.icon}
                      >
                        <Refresh sx={{ fontSize: '2rem' }} />
                      </IconButton>
                    </Box>
                  )}
                </div>
              </div>
            </Grid>

            <Grid item className={classes.sidebarIconGridContainerDesktop}>
              {/* <Grid item>
                  <Button variant='outlined'onClick={(() => setOpenAddDeviceDialog(true))}>Add new device as network</Button>
                </Grid> */}

              <Grid item>
                <Searchbar />
              </Grid>

              {userInfo.email !== "rakesh@filoffee.ai" && <Grid item>
                <Box className={classes.logoBox}>{userCustomer.logo ? <DecathlonLogo /> : ''}</Box>
              </Grid>
              }

              <Grid item>
                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                  <AccountPopover userInfo={userInfo} />
                </Stack>
              </Grid>
            </Grid>
            {openAddDeviceDialog && (
              <AddNewDeviceDialog openDialog={openAddDeviceDialog} handleClose={handleCloseAddDeviceDialog} />
            )}
          </Grid>
        )}
      </ToolbarStyle>
    </RootStyle>
  );
}
