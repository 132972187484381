/* eslint-disable */
import ShareIcon from '@mui/icons-material/Share';
import { Box, Card, CardContent, CardHeader, Skeleton, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';
import { getConfigChangeCards } from '../../API/S3/ConfigEvents';
import { UserContext } from '../../context/UserContext';
import cardStyles from '../../styles/cardsstyles';
import { fDateOnly } from '../../utils/formatTime';
import NoDataComponent from '../NoDataComponent';
import { toCamelizeWords } from '../../utils/formatString';


// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(0),
}));

const useStyles = makeStyles({
  skeletonLoader: {
    top: 0,
    left: 0,
    width: "100%",
    display: 'flex',
    height: 400,
  },
  noDataContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
});
// ---------------------------------------


const SummarySuccessFailChart = (props) => {
  const navigate = useNavigate();
  const { customerId } = useContext(UserContext);
  const classes = cardStyles();
  const classess = useStyles();
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [chartLoading, setChartLoading] = useState(true);
  const [data, setData] = useState([]);


  const fetchSummaryVendor = async () => {
    setChartLoading(true);
    const payload = fDateOnly(new Date());
    getConfigChangeCards(customerId, payload)
      .then((res) => {
        setData(res.data.date)
        delete res.data.date
        const stasts = {
          success: res.data?.success || 0,
          failed: res.data?.failed || 0
        }
        const toArray = Object.keys(stasts).map((item) => ({ x: toCamelizeWords(item), y: stasts[item] }))
        setLabels(toArray.map(x => x.x));
        setSeries(toArray.map(x => x.y));
        setChartLoading(false);
      })
      .catch(() => {
        setChartLoading(false);
      });
  };

  useEffect(() => {
    fetchSummaryVendor();
  }, [customerId]);

  const chartSeries = series;

  const chartOptions = {
    labels,
    chart: {
      height: 'auto',
      width: '100%',
      events: {
        dataPointMouseEnter: (event, chartContext, config) => {
          event.target.style.cursor = 'pointer';
        },
        dataPointSelection(event, chartContext, config) {
          if (config.dataPointIndex === 1) {
            navigate(`/dashboard/config-change-backup-table/backup-fail/${data}`, {state: data});
          } else {
            navigate(`/dashboard/config-change-backup-table/backup-success/${data}`, {state: data});
          }
          // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
        }
      },
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      formatter(label, opts) {
        return [label, " - ", opts.w.globals.series[opts.seriesIndex]]
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light'
      },
    },

    // colors: ['#00e676', '#e53935'],
    colors: [function({ value, seriesIndex, w }) {
      if (seriesIndex === 0) {
          return '#00e676'
      }if (seriesIndex === 1) {
        return '#e53935'
      }
    }],

    dataLabels: {
      enabled: false,
      dropShadow: { enabled: true },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '80%',
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: '14px',
              fontWeight: 400,
            },
            total: {
              show: true,
              label: 'Total',
              color: '#373d3f',
              fontWeight: 900,
              fontSize: '20px',
            },
          },
        },
      },
    },
  };

  return (
    <>
      <Card elevation={10}>
        <CardHeader
          sx={{ py: 2, px: 2 }}
          color="text.error"
          title={'CMDB scan status'}
          avatar={<ShareIcon className={classes.boxTitleIcon} />}
          action={
            <Tooltip title="Last Scanned" placement="bottom">
              <Typography style={{margin: "20px", cursor:'pointer'}} color="green">
                {data}
              </Typography>
            </Tooltip>
          }
        />
        <CardContent sx={{ padding: '0px', pl: '10px', pr: '10px', pb: '0px' }}>
          {series?.length === 0 && !chartLoading ? (
            <Box className={classess.noDataContainer}>
              <NoDataComponent noDataFoundText="CMDB scan status" />
            </Box>
          ) : (
            <ChartWrapperStyle dir="ltr">
              {chartLoading ? (
                <Skeleton animation="wave" variant="rectangular" className={classess.skeletonLoader} />
              ) : (
                <ReactApexChart series={chartSeries} options={chartOptions} type="donut" height={463} />
              )}
            </ChartWrapperStyle>
          )}
        </CardContent>
      </Card>
    </>
  );
};
export default SummarySuccessFailChart;
