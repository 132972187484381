import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { addCredentials } from '../../../API/Credentials/listCredentials';
import { UserContext } from '../../../context/UserContext';
import { FormProvider, RHFTextField } from '../../hook-form';
import Iconify from '../../Iconify';


const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  fieldContainer: { minHeight: 190 },
});

export default function AddCredentials(props) {
  const { openDialog, handleClose } = props;
  const classes = useStyles();
  const { customerId } = useContext(UserContext);
  const [currentPage, setCurrentPage] = React.useState('SNMP');
  const [loading, setLoading] = useState(false);
  const [currentVersion, setCurrentVersion] = React.useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordauth, setShowPasswordauth] = useState(false);
  const [showPasswordprivacy, setShowPasswordprivacy] = useState(false);

  const credentialsSchema = Yup.object().shape({
    versioning: Yup.string(),
    snmpv1string: Yup.string().when('versioning', {
      is: (version) => version === 'SNMPV1',
      then: Yup.string().required('SNMPv1 string is required'),
      otherwise: Yup.string().nullable(),
    }),
    snmpcommunity: Yup.string().when('versioning', {
      is: (version) => version === 'SNMPV2',
      then: Yup.string().required('SNMPv2 string is required'),
      otherwise: Yup.string().nullable(),
    }),
    snmpv3username: Yup.string().when('versioning', {
      is: (version) => version === 'SNMPV3',
      then: Yup.string().required('SNMPv3 username is required'),
      otherwise: Yup.string().nullable(),
    }),
    securitylevelsnpmv3: Yup.string().when('versioning', {
      is: 'SNMPV3',
      then: Yup.string().oneOf(['authNoPriv', 'authPriv', 'noAuthNoPriv'], 'Security level is required'),
      otherwise: Yup.string().nullable(),
    }),
    authtypesnmpv3: Yup.string().when(['versioning', 'securitylevelsnpmv3'], {
      is: (version, securitylevelsnmpv3) =>
        version === 'SNMPV3' && (securitylevelsnmpv3 === 'authNoPriv' || securitylevelsnmpv3 === 'authPriv'),
      then: Yup.string().required('Auth type is required'),
      otherwise: Yup.string().nullable(),
    }),
    authtypepassword: Yup.string().when(['versioning', 'securitylevelsnpmv3'], {
      is: (version, securitylevelsnmpv3) => version === 'SNMPV3' && (securitylevelsnmpv3 === 'authNoPriv' || securitylevelsnmpv3 === 'authPriv'),
      then: Yup.string().required('Auth password is required'),
      otherwise: Yup.string().nullable(),
    }),
    privtypesnmpv3: Yup.string().when(['versioning', 'securitylevelsnpmv3'], {
      is: (version, securitylevelsnmpv3) =>
        version === 'SNMPV3' && (securitylevelsnmpv3 === 'authPriv'),
      then: Yup.string().required('Privacy type is required'),
      otherwise: Yup.string().nullable(),
    }),
    privtypepasswordsnmpv3: Yup.string().when(['versioning', 'securitylevelsnpmv3'], {
      is: (version, securitylevelsnmpv3) => version === 'SNMPV3' && (securitylevelsnmpv3 === 'authPriv'),
      then: Yup.string().required('Privacy password is required'),
      otherwise: Yup.string().nullable(),
    }),
    sshusername: Yup.string().when('versioning', {
      is: (version) => version !== 'SNMP' && currentPage === 'SSH',
      then: Yup.string().required('SSH username is required'),
      otherwise: Yup.string().nullable(),
    }),
    sshpassword: Yup.string().when('versioning', {
      is: (version) => version !== 'SNMP' && currentPage === 'SSH',
      then: Yup.string().required('SSH password is required'),
      otherwise: Yup.string().nullable(),
    }),
    sshport: Yup.string().when('versioning', {
      is: (version) => version !== 'SNMP' && currentPage === 'SSH',
      then: Yup.string().required('SSH port is required'),
      otherwise: Yup.string().nullable(),
    }),
    apistring: Yup.string().when('versioning', {
      is: (version) => version !== 'SNMP' && currentPage === 'API_KEY',
      then: Yup.string().required('API string is required'),
      otherwise: Yup.string().nullable(),
    }),
    apiendpoint: Yup.string().when('versioning', {
      is: (version) => version !== 'SNMP' && currentPage === 'API_KEY',
      then: Yup.string().required('API endpoint is required'),
      otherwise: Yup.string().nullable(),
    }),
  });

  const defaultValues = {
    // location: "",
    versioning: '',
    snmpv1string: '',
    snmpcommunity: '',
    snmpv3username: '',
    securitylevelsnpmv3: 'authPriv',
    authtypesnmpv3: '',
    authtypepassword: '',
    privtypesnmpv3: '',
    privtypepasswordsnmpv3: '',
    apistring: '',
    apiendpoint: '',
    sshusername: '',
    sshpassword: '',
    sshport: '',
  };

  const methods = useForm({
    resolver: yupResolver(credentialsSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = methods;

  watch('currentVersion');
  watch('currentPage');

  const onSubmit = async (value) => {
    let data = {
      type: currentPage,
      // location_uuid: selectedLocation?._uid,
    };
    if (currentPage === 'SNMP' && currentVersion === 'SNMPV1') {
      data = {
        ...data,
        snmp_version: currentVersion,
        snmp_string: value.snmpv1string,
      };
    }
    if (currentPage === 'SNMP' && currentVersion === 'SNMPV2') {
      data = {
        ...data,
        snmp_version: currentVersion,
        snmp_community: value.snmpcommunity,
      };
    }
    if (currentPage === 'SNMP' && currentVersion === 'SNMPV3') {
      data = {
        ...data,
        snmp_version: currentVersion,
        user_snmpv3: value.snmpv3username,
        security_level_snmpv3: value.securitylevelsnpmv3,
        auth_type_snmpv3: isEmpty(value.authtypesnmpv3) ? '' : value.authtypesnmpv3 ,
        auth_type_passphrase_snmpv3: isEmpty(value.authtypepassword) ? '':value.authtypepassword,
        priv_type_snmpv3: isEmpty(value.privtypesnmpv3) ? '':value.privtypesnmpv3,
        priv_type_passphrase_snmpv3: isEmpty(value.privtypepasswordsnmpv3)? '':value.privtypepasswordsnmpv3,
      };
    }
    if (currentPage === 'SSH') {
      data = {
        ...data,
        ssh_user: value.sshusername,
        ssh_password: value.sshpassword,
        ssh_port: value.sshport,
      };
    }
    if (currentPage === 'API_KEY') {
      data = {
        ...data,
        api_key: value.apistring,
        api_endpoint: value.apiendpoint,
      };
    }
    setLoading(true);
    addCredentials(customerId, data)
      .then((res) => {
        setLoading(false);
        handleClose();
      })
      .catch((res) => {
        setLoading(false);
      });
  };

  const handleRadioClick = (value) => {
    setCurrentPage(value);
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setCurrentVersion(selectedValue);
    setValue('versioning', selectedValue);
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            Add Credential
            <IconButton onClick={handleClose} sx={{ float: 'right' }}>
              <CloseIcon sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </DialogTitle>
          <Divider />
          {loading && <LinearProgress />}
          <DialogContent>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={currentPage}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <div>
                <FormControlLabel
                  value="SNMP"
                  control={<Radio />}
                  label="SNMP"
                  checked={currentPage === 'SNMP'}
                  onClick={() => handleRadioClick('SNMP')}
                />
                <FormControlLabel
                  value="SSH"
                  control={<Radio />}
                  label="SSH"
                  checked={currentPage === 'SSH'}
                  onClick={() => handleRadioClick('SSH')}
                />
                <FormControlLabel
                  value="API_KEY"
                  control={<Radio />}
                  label="API"
                  checked={currentPage === 'API_KEY'}
                  onClick={() => handleRadioClick('API_KEY')}
                />
              </div>
              {currentPage === 'SNMP' && (
                <>
                  <Typography variant="h6" gutterBottom sx={{ marginTop: '10px' }}>
                    SNMP Credentials
                  </Typography>
                  <Stack spacing={3} sx={{ py: 2 }}>
                    <Controller
                      name="versioning"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Version *</InputLabel>
                          <Select
                            {...field}
                            name="versioning"
                            label="Version *"
                            required={Boolean(true)}
                            placeholder="Select SNMP version"
                            value={currentVersion}
                            onChange={handleChange}
                            labelId="demo-simple-select-required-label"
                            error={!!errors.versioning}
                          >
                            <MenuItem value={'SNMPV1'}>Version 1</MenuItem>
                            <MenuItem value={'SNMPV2'}>Version 2</MenuItem>
                            <MenuItem value={'SNMPV3'}>Version 3</MenuItem>
                          </Select>
                          {fieldState?.error && <FormHelperText>{fieldState?.error?.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                    {['SNMPV1', 'SNMPV2', 'SNMPV3'].includes(currentVersion) && (
                      <>
                        {currentVersion === 'SNMPV1' && (
                          <Stack spacing={3} sx={{ py: 2 }}>
                            <RHFTextField
                              name="snmpv1string"
                              label="SNMPv1 string"
                              placeholder="Enter SNMPv1 string"
                              autoComplete="off"
                            />
                          </Stack>
                        )}
                        {currentVersion === 'SNMPV2' && (
                          <Stack spacing={3} sx={{ py: 2 }}>
                            <RHFTextField
                              name="snmpcommunity"
                              label="SNMPv2 string"
                              placeholder="Enter SNMPv2 string"
                              autoComplete="off"
                            />
                          </Stack>
                        )}
                        {currentVersion === 'SNMPV3' && (
                          <>
                            <Grid item lg={6} xs={6} sx={{ paddingLeft: '0px!important' }}>
                              <RHFTextField
                                name="snmpv3username"
                                label="SNMPv3 username"
                                placeholder="Enter SNMPv3 username"
                              />
                            </Grid>
                            <Grid item lg={6} xs={6} sx={{ paddingLeft: '0px!important' }}>
                              <Controller
                                control={control}
                                name="securitylevelsnpmv3"
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    label="Security level"
                                    select
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    error={!!errors.securitylevelsnpmv3}
                                    helperText={errors.securitylevelsnpmv3?.message}
                                  >
                                    <MenuItem value="noAuthNoPriv">NO Auth, No Privacy</MenuItem>
                                    <MenuItem value="authNoPriv">Auth, No Privacy</MenuItem>
                                    <MenuItem value="authPriv">Auth with Privacy</MenuItem>
                                  </TextField>
                                )}
                              />
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                marginTop: '10px!important',
                                padding: '13px',
                                paddingLeft: '0px',
                                paddingTop: '0px',
                              }}
                            >
                              <Grid item lg={6} xs={6} sx={{ paddingLeft: '0px!important' }}>
                                <Controller
                                  control={control}
                                  name="authtypesnmpv3"
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      label="Auth type"
                                      select
                                      fullWidth
                                      InputLabelProps={{ shrink: true }}
                                      error={!!errors.authtypesnmpv3}
                                      helperText={errors.authtypesnmpv3?.message}
                                      disabled={watch('securitylevelsnpmv3') === 'noAuthNoPriv'}
                                    >
                                      <MenuItem value="SHA">SHA</MenuItem>
                                      <MenuItem value="MD5">MD5</MenuItem>
                                    </TextField>
                                  )}
                                />
                              </Grid>
                              <Grid item lg={6} xs={6}>
                                <RHFTextField
                                  label="Auth password"
                                  name="authtypepassword"
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                  placeholder="Enter password"
                                  type={showPasswordauth ? 'text' : 'password'}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPasswordauth(!showPasswordauth)} edge="end">
                                          <Iconify icon={showPasswordauth ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                  disabled={watch('securitylevelsnpmv3') === 'noAuthNoPriv'}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                marginTop: '-7px!important',
                                padding: '13px',
                                paddingLeft: '0px',
                                paddingTop: '0px',
                              }}
                            >
                              <Grid item lg={6} xs={6} sx={{ paddingLeft: '0px!important' }}>
                                <Controller
                                  control={control}
                                  name="privtypesnmpv3"
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      label="Privacy type"
                                      select
                                      fullWidth
                                      InputLabelProps={{ shrink: true }}
                                      error={!!errors.privtypesnmpv3}
                                      helperText={errors.privtypesnmpv3?.message}
                                      disabled={watch('securitylevelsnpmv3') === 'noAuthNoPriv'} // Disable based on security level
                                    >
                                      <MenuItem value="AES">AES</MenuItem>
                                      <MenuItem value="DES">DES</MenuItem>
                                    </TextField>
                                  )}
                                />
                              </Grid>
                              <Grid item lg={6} xs={6}>
                                <RHFTextField
                                  label="Privacy password"
                                  name="privtypepasswordsnmpv3"
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                  placeholder="Enter password"
                                  type={showPasswordprivacy ? 'text' : 'password'}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPasswordprivacy(!showPasswordprivacy)} edge="end">
                                          <Iconify icon={showPasswordprivacy ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                  disabled={watch('securitylevelsnpmv3') === 'noAuthNoPriv' }
                                />
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </Stack>
                </>
              )}
              {currentPage === 'SSH' && (
                <>
                  <Typography variant="h6" gutterBottom sx={{ marginTop: '10px' }}>
                    SSH Credentials
                  </Typography>
                  <Stack spacing={3} sx={{ py: 2 }}>
                    <RHFTextField name="sshusername" label="SSH username" placeholder="Enter SSH username" />
                    <RHFTextField
                      label="Password"
                      name="sshpassword"
                      fullWidth
                      placeholder="Enter password"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <RHFTextField name="sshport" label="SSH port" autoComplete="off" placeholder="Enter SSH port" />
                  </Stack>
                </>
              )}
              {currentPage === 'API_KEY' && (
                <>
                  <Typography variant="h6" gutterBottom sx={{ marginTop: '10px' }}>
                    API Credentials
                  </Typography>
                  <Stack spacing={3} sx={{ py: 2 }}>
                    <RHFTextField
                      name="apiendpoint"
                      label="API endpoint(IP)"
                      placeholder="Enter API endpoint"
                      autoComplete="off"
                    />
                    <RHFTextField
                      name="apistring"
                      label="API string"
                      placeholder="Enter API string"
                      autoComplete="off"
                    />
                  </Stack>
                </>
              )}
            </RadioGroup>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Add
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
}
