import { Refresh } from '@mui/icons-material';
import ShareIcon from '@mui/icons-material/Share';
import { Box, Card, CardContent, CardHeader, CircularProgress, IconButton, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getCountByVendor } from '../../../API/Endpoints/showEndpoints';
import { UserContext } from '../../../context/UserContext';
import cardStyles from '../../../styles/cardsstyles';
import { toCamelizeWords } from '../../../utils/formatString';
import NoDataComponent from '../../NoDataComponent';



// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const useStyles = makeStyles({
  skeletonLoader: {
    top: 0,
    left: 0,
    width: "100%",
    display:'flex',
    height: 480,
  },
  noDataContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
});
// ---------------------------------------


const EndpointsByVendor = (props) => {
  const { locationData,selectEndpointVendor } = props;
  const classes = cardStyles();
  const classees = useStyles();
  const { customerId } = useContext(UserContext);
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [chartLoading, setChartLoading] = useState(true);
  const [refreshLoading, setRefreshLoading] = useState(false);


  const fetchEndpointsByVendor = async () => {
    const locations = locationData;
    getCountByVendor(customerId, locations)
      .then((res) => {
        const toArray = Object.keys(res)
          .map((item) => ({ x: toCamelizeWords(item), y: res[item] }))
          .sort((a, b) => b.y - a.y);
        setLabels(toArray.map((x) => x.x));
        setSeries(toArray);
        setChartLoading(false)
        setRefreshLoading(false);
      })
      .catch((error) => {
        setRefreshLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    fetchEndpointsByVendor();
  }, [customerId, locationData]);


  const handleRefresh = () => {
    setRefreshLoading(true);
    fetchEndpointsByVendor()
  };


  const chartLabels = labels;


  const options = {
    labels: chartLabels,
    chart: {
      type: 'bar',
      height: 'auto',
      width:'100%',
      padding:'0px',
      toolbar: { show: false },
      events: {
        dataPointMouseEnter: (event, chartContext, config) => {
          event.target.style.cursor = 'pointer';
        },
        dataPointSelection: (event, chartContext, config) => {
          const selectedIndex = config.dataPointIndex;
          if (config.dataPointIndex === selectedIndex) {
            const val = config.w.config.labels[selectedIndex];
            const tableNode = document.getElementById("ndTable")
            let scrollTo= 700
            if (tableNode){
              scrollTo=tableNode.offsetTop-document.getElementById("topBar").offsetHeight
            }
            selectEndpointVendor(val);
            window.scrollTo({top:scrollTo, left:0, behavior:"smooth"});
          }
        },
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
        columnWidth: '10%',
        barHeight: '70%',
        distributed: false,
        rangeBarOverlap: true,
        rangeBarGroupRows: false,
        colors: {
            ranges: [{
                from: 0,
                to: 0,
                color: undefined
            }],
            backgroundBarColors: [],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 0,
        },
        dataLabels: {
            position: 'top',
            maxItems: 100,
            hideOverflowingLabels: true,
            orientation: "horizontal",
            total: {
              enabled: false,
              formatter: undefined,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: '#373d3f',
                fontSize: '12px',
                fontFamily: undefined,
                fontWeight: 600
              }
            }
        }
      },
    },
    fill: {
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 0, 100, 100]
      }
    },
    dataLabels: {
      enabled: false,
      dropShadow: { enabled: true } 
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        title: {
          formatter: (val, opts) => {
            return opts.w.globals.labels[opts.dataPointIndex];
          },
        },
        show: false
      },
      x: {
        show: false,
      },
    },
    series: [
      {
        data: series,
      },
    ],
  };

  return (
    <>
      <Card elevation={10}>
        <CardHeader
          color="text.error"
          title={'Client devices by vendor'}
          avatar={<ShareIcon className={classes.boxTitleIcon} />}
          action={
            <Box>
                {refreshLoading ? (
                  <CircularProgress
                    size={30}
                    sx={{ mr: '5px' }}
                  />
                ) : (
                  <IconButton color="primary" onClick={handleRefresh} sx={{ mt: '-5px' }}>
                    <Refresh sx={{ fontSize: '2rem' }} />
                  </IconButton>
                )}
            </Box>
          }
        />
        <CardContent>
          {series?.length === 0 && !chartLoading ? (
            <Box className={classees.noDataContainer}>
              <NoDataComponent noDataFoundText="Client devices by vendor" />
            </Box>
          ) : (
            <ChartWrapperStyle dir="ltr">
              {chartLoading ? (
                <Skeleton animation="wave" variant="rectangular" className={classees.skeletonLoader} />
              ) : (
                <ReactApexChart options={options} series={options.series} type="bar" height={500}/>
              )}
            </ChartWrapperStyle>
          )}
        </CardContent>
      </Card>
    </>
  );
};
export default EndpointsByVendor;
