import modem from '../modem';

// ----------------------firewall - fortinet--------------------------------
// --------------------system-----------------------------------------

export const networkHealthOsVersion = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/system/os_version`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const networkHealthVPNClients = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/system/vpn_client`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const networkHealthSessions = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/system/sessions`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const networkHealthVDOMusage = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/system/vdom_usage`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const networkHealthCpuUsage = async (id, serialNumber, payload) => {
  // -------also used for aruba_os and access_point - cpu utilization
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/system/cpu_usage`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const networkHealthMemoryUsage = async (id, serialNumber, payload) => {
  // -------also used for aruba_os and access_point - memory free
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/system/memory_usage_latest`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const networkHealthMemoryUsageDetailed = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/system/memory_usage`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

// --------------------HA Cluster-----------------------------------------


export const haClusterCPUusageRatio = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/ha_cluster/cpu_usage_ratio`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};


export const haClusterSessions = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/ha_cluster/sessions`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const haClusterNetworkUsageRatio = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/ha_cluster/network_usage_ratio`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const haClusterMemberPacketsTotal = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/ha_cluster/member_packets_total`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const haClusterMemoryUsageRatio = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/ha_cluster/memory_usage_ratio`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const haClusterMemberBytesTotal = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/ha_cluster/member_bytes_total`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const haClusterMemberStatus = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/ha_cluster/member_status`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const haClusterVirusEventsTotal = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/ha_cluster/virus_events_total`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const haClusterIPSeventsTotal = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/ha_cluster/ips_events_total`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const haClusterMEMusageRatio = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/ha_cluster/memory_usage_ratio`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

// -------------------------Interface -----------------------------------------------------------


export const interfaceThroughputBandwidth = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/interface/throughput_bandwidth`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const interfaceStatus = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/interface/interface_status`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};



// -----------------VPN--------------------------------------------

export const clientVpnConnections = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/vpn/client_vpn_connections`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const VpnBandwidthReceive = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/vpn/bandwidth_receive`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const VpnBandwidthTransmit = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/vpn/bandwidth_transmit`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const VpnStatus = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/vpn/vpn_status`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

// ----------------SD-WAN----------------------------------------------------

export const SdwanVirtualStatus = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/sd_wan/virtual_wan_status`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const SdwanVirtualLatency = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/sd_wan/virtual_wan_latency`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const SdwanVirtualSentReceivedTotal = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/sd_wan/virtual_wan_packet_sent_total`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const SdwanVirtualJitter = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/sd_wan/virtual_wan_jitter`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const SdwanVirtualActiveSessions = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/sd_wan/virtual_wan_active_sessions`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const SdwanVirtualPacketLoss = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/sd_wan/virtual_wan_packet_loss`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const SdwanAllBandwidths = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/sd_wan/all_bandwidth`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const SdwanStateChange = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/sd_wan/state_change`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

// --------------------cerificate------------------------------

export const CertificateValidity = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/certificate/certificate_valid`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

// ---------------------access point - aruba_os ----------------------------

 // -----------aruba_os - client ---------------------------

export const clientsTable = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/client/client`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const networkTable = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/client/network`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

 // -----------------aruba_os - overview ---------------------------


export const throughputBandwidth = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/interface/throughput_bandwidth`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const clientTotal = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/client/client_total`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const macTotal = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/mac_table_total`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const networkTotal = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/client/network_total`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const vlanTable = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/vlans`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const macTable = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/mac_table`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

 // -----------------aruba_os - radio ---------------------------

 export const radioUtilization = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/radio/utilization`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const radioNoiseFloor = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/radio/noise_floor`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const radioRadioPower = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/radio/radio_power`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const radioRadioChannel = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/radio/radio_channel`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};


 // ----------------------------switch-------------------------

 export const haTable = async (id, serialNumber, payload) => {
  const newPro = modem.post(`/${id}/network/health/${serialNumber}/ha_cluster/members`, payload)
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};