/* eslint-disable */
import {
    Box,
    LinearProgress, Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { interfaceStatus } from '../../../../../../API/NetworkDevices/health';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';



// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    height: 650,
    backgroundColor: 'yellow'
}));

// ---------------------------------------


const InterfaceStatusTable = ({ interfaceExpanded }) => {
    const { healthDashState, getStartEnd } = useHealthContext()
    const { customerId } = useContext(UserContext);
    const [statusData, setStatusData] = useState([]);
    const [loading, setLoading] = useState(true);


    // ------------------------------------------------------------------------------------------------
    const columnHeaders = [
        {
            headerName: 'Interface',
            field: 'name',
            width: 200,
            sortable: false,
            filter: false,
            cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 },
        },
        {
            headerName: 'Status',
            field: 'status',
            width: 150,
            sortable: true,
            filter: false,
            cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 },
            cellRenderer: (params) => {
                if (params.value === 0) {
                    return <span style={{ color: 'red' }}>Down</span>;
                }
                return <span style={{ color: 'green' }}>Up</span>;
            },
        },
        {
            headerName: 'alias',
            field: 'alias',
            width: 180,
            sortable: false,
            filter: false,
            cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 },
        },
        {
            headerName: 'Parent',
            field: 'parent',
            width: 180,
            sortable: false,
            filter: false,
            cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 },
        },
    ];

    const fetchData = useCallback(() => {
        if (!healthDashState.selectedDevice || interfaceExpanded === false) {
            return '';
        }
        const data = getStartEnd();
        const payload = {
            start: data.start / 1000,
            end: data.end / 1000,
            step: data.step,
            // device_type: healthDashState.selectedDevice.l_dvc_typ_name,
            device_os: healthDashState.selectedDevice.r_dvc_os,
        };
        setLoading(true);
        interfaceStatus(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
            .then((res) => {
                setLoading(false);
                const sortedStatusData = res.data.sort((a, b) => a.status - b.status);
                setStatusData(sortedStatusData);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [healthDashState.selectedDevice, interfaceExpanded, customerId]);

    useEffect(() => {
        fetchData();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, interfaceExpanded]);

    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            filter: true,
            sortable: true,
            suppressMenu: true,
            floatingFilter: false,
        };
    }, []);

    // -----------------------------------------------------------------------------------------------


    return (
        <>
            {loading && <LinearProgress sx={{ height: '2px' }} />}
            <Paper variant="outlined">
                <ChartWrapperStyle dir="ltr">
                    <Box style={{ height: '650px', width: '100%' }} className="ag-theme-material">
                        <AgGridReact
                            rowData={statusData}
                            columnDefs={columnHeaders}
                            onGridReady={fetchData}
                            defaultColDef={defaultColDef}
                        />
                    </Box>
                </ChartWrapperStyle>
            </Paper>
        </>
    );
};
export default InterfaceStatusTable;
