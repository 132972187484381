import { Visibility } from '@mui/icons-material';
import { IconButton, Tooltip, Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect, useState, useCallback } from 'react';
import { getRoles } from '../../../API/Roles/roles';
import { UserContext } from '../../../context/UserContext';
import ReadPermissionsDialog from './ReadPermissionsDialog';

// ------------------------------------------------------------------------

const useStyles = makeStyles({
  icon: {
    visibility: 'hidden',
  },
  field: {},
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
});

// ----------------------------------------------------------------------

export default function MemberRoleSelector(props) {
  const classes = useStyles();
  const { member, roleObject, placeholder, gridApi } = props;
  const [permissionDialog, setPermissionDialog] = useState(false);
  const { customerId } = useContext(UserContext);
  const [roleOptions, setRoleOptions] = useState([]);
  const page = 0

  // ------------------------------------------------------------------------

  const fetchRoles = useCallback(async () => {
    const res = await getRoles(customerId, page + 1);
    setRoleOptions(res.data);
  }, [customerId, page]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  // ----------------------------------------------------------------------

  return (
    <>
      <Autocomplete
        fullWidth
        size="small"
        autoHighlight
        disableClearable
        className={classes.root}
        options={roleOptions}
        sx={{ maxwidth: 900, minWidth: 500 }}
        defaultValue={
          roleObject || roleOptions.find((item) => item.uuid === '97ab7ed0-0f0e-4e30-aa4a-d4e134f99481') || null
        }
        onChange={props.selectRole && ((e, option) => props.selectRole(option, member))}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        renderInput={(params) => (
          <TextField
            {...params}
            name="role"
            label="Select role"
            placeholder={placeholder}
            className={classes.field}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              autoComplete: 'new-password',
              endAdornment: (
                <>
                  {member && (
                    <Tooltip title={'Read permissions'}>
                      <IconButton onClick={() => setPermissionDialog(true)} className={classes.icon}>
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ), 
            }}
          />
        )}
      />

      {permissionDialog && (
        <ReadPermissionsDialog
          gridApi={gridApi}
          openDialog={permissionDialog}
          roleuuid={member.roles?.[0]?.uuid}
          handleClose={() => setPermissionDialog(false)}
        />
      )}
    </>
  );
}

MemberRoleSelector.propTypes = {
  member: PropTypes.object,
  roleObject: PropTypes.object,
  placeholder: PropTypes.string,
  gridApi: PropTypes.object,
  selectRole: PropTypes.func,
};