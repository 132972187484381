import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import decathlonLogo from '../Assets/decathlon-logo.png';
// ----------------------------------------------------------------------

DecathlonLogo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function DecathlonLogo({ disabledLink = false, sx }) {
  const DecathlonLogo = (
    <>
      <Box sx={{ width: 130, height: 35, ...sx }}>
        <img src={decathlonLogo} alt="decathlon" />
      </Box>
    </>
  );

  if (disabledLink) {
    return <>{DecathlonLogo}</>;
  }

  return <RouterLink to="/dashboard/dashboard">{DecathlonLogo}</RouterLink>;
}
