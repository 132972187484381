import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { DataGrid, useGridApiContext } from '@mui/x-data-grid';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { getDeviceTypes } from '../../API/DeviceTypes/index';
import { markAsInventory } from '../../API/NetworkDevices/networkDevices';
import { UserContext } from '../../context/UserContext';

import { toCamelizeWords } from '../../utils/formatString';

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  customHeader: {
    backgroundColor: '#e2e2e2',
    fontWeight: 900,
  },
});
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DeviceTypeSelectorEditor = (props) => {
  const filter = createFilterOptions();
  const apiRef = useGridApiContext();
  const [deviceTypesOptions, setDeviceTypesOptions] = useState(props.deviceTypesOptions);

  useEffect(() => {
    setDeviceTypesOptions(props.deviceTypesOptions);
  }, [props.deviceTypesOptions]);
  return (
    <Autocomplete
      size="small"
      fullWidth
      limitTags={4}
      // multiple
      options={deviceTypesOptions}
      onChange={(event, newValue) => {
        // apiRef.current.setEditCellValue({ id: props.id, field: 'r_dvc_vendor', value: newValue.r_dvc_typ_vendor });
        // apiRef.current.setEditCellValue({ id: props.id, field: 'l_dvc_typ_id', value: newValue._uid });
        // apiRef.current.setEditCellValue({ id: props.id, field: 'r_dvc_os', value: newValue.r_dvc_typ_os });
        apiRef.current.setEditCellValue({ id: props.id, field: 'deviceType', value: newValue });
      }}
      disableCloseOnSelect
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered;
      }}
      open
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      loading={props.loading}
      getOptionLabel={(option) => toCamelizeWords(option.label)}
      renderOption={(props, option, { selected }, ind) => {
        if (option?.label) {
          return (
            <li key={`${option.r_dvc_typ_os}${option.r_dvc_typ_name}`} {...props} style={{ width: '600px' }}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {toCamelizeWords(option.label)}
              {/* {`${option.r_dvc_typ_os}${option.r_dvc_typ_name}`} */}
              {/* {option.r_dvc_typ_vendor ? ` (${option.r_dvc_typ_vendor})` : ''} */}
            </li>
          );
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select device type"
          InputLabelProps={{ shrink: true }}
          autoComplete="off"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
export default function NetworkDevicesDialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog, handleClose, selectedRecords } = props;
  const { customerId } = useContext(UserContext);
  const [selectedItems, setSelectedItems] = useState(
    selectedRecords.map((item, ind) => ({
      ...item,
      key: ind,
      id: item._uid,
      r_dvc_os: '',
      r_dvc_vendor: '',
      l_dvc_typ_id: item.deviceType?._uid,
      _status: 'New',
      _isDirty: false
    }))
  );
  const [_isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cellLoading, setCellLoading] = useState(false);
  const [deviceTypesOptions, setDeviceTypesOptions] = useState([]);


  const fetchDeviceType = async () => {
    getDeviceTypes(customerId)
      .then((res) => {
        setDeviceTypesOptions(
          res
            .sort((a, b) => (a.r_dvc_typ_name > b.r_dvc_typ_name ? 1 : -1))
            .map((item) => {
              return {
                label: `${toCamelizeWords(item.r_dvc_typ_name)} - ${toCamelizeWords(item.r_dvc_typ_os)} ( ${toCamelizeWords(item.r_dvc_typ_vendor)} )`,
                ...item,
              };
            })
            .filter(
              (item) =>
                item.r_dvc_typ_vendor !== '' && item.r_dvc_typ_name !== 'STACK' && item.r_dvc_typ_name !== 'SWITCH_OSCX'
            )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchDeviceType();
  }, [customerId]);

  const columns = [
    {
      field: 'id',
      sortable: false,
      headerName: '#',
      width: 90,
      editable: false,
      renderCell: (params) => {
        // return params.row._status
        if (params.row?._status === 'Fail') {
          return (
            <Tooltip
              title={params.row._statusmessage || 'Mac serial number and device types are mandotory'}
              sx={{ color: 'red' }}
            >
              <IconButton size="small">
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          );
        }
        return cellLoading ? <CircularProgress size={20} /> : <span>{params.api.getRowIndex(params.row.id) + 1}</span>;
      },
      headerClassName: classes.customHeader,
    },
    {
      field: 'r_dvc_ip_address',
      sortable: false,
      headerName: 'IP Address',
      width: 230,
      editable: false,
      headerClassName: classes.customHeader,
    },
    {
      field: 'r_dvc_mac_address',
      sortable: false,
      headerName: 'Mac Address *',
      width: 230,
      editable: true,
      headerClassName: classes.customHeader,
    },
    {
      field: 'r_dvc_serial_number',
      sortable: false,
      headerName: 'Serial Number *',
      width: 230,
      editable: true,
      headerClassName: classes.customHeader,
    },
    {
      field: 'deviceType',
      sortable: false,
      headerName: 'Device Type *',
      width: 230,
      editable: true,
      headerClassName: classes.customHeader,
      renderCell: (params) => {
        return toCamelizeWords(params.row?.deviceType?.r_dvc_typ_name);
      },
      renderEditCell: (params) => <DeviceTypeSelectorEditor {...params} deviceTypesOptions={deviceTypesOptions} />,
    },
    {
      field: 'r_dvc_os',
      sortable: false,
      headerName: 'OS',
      width: 90,
      // hide:true,
      editable: false,
      headerClassName: classes.customHeader,
      renderCell: (params) => {
        return params.row?.deviceType?.r_dvc_typ_os;
      },
    },
    {
      field: 'r_dvc_vendor',
      sortable: false,
      headerName: 'Vendor',
      width: 90,
      // hide:true,
      editable: false,
      headerClassName: classes.customHeader,
      renderCell: (params) => {
        return params.row?.deviceType?.r_dvc_typ_vendor;
      },
    },

    {
      field: 'r_dvc_name',
      headerName: 'Device Name',
      sortable: false,
      width: 230,
      editable: true,
      headerClassName: classes.customHeader,
    },
    {
      field: 'r_dvc_host',
      headerName: 'Model',
      width: 230,
      editable: true,
      headerClassName: classes.customHeader,
    },
  ];

  // ----------------------------------------------------------------------------------------------------------

  const handleSubmit = async () => {
    const _selectedItems = selectedItems.map((value) => {
      return {
        ...value,
        _isDirty: value._isDirty,
        r_dvc_vendor: value?.deviceType?.r_dvc_typ_vendor,
        l_dvc_typ_id: value?.deviceType?._uid,
        r_dvc_os: value?.deviceType?.r_dvc_typ_os,
        _status:
          isEmpty(value.r_dvc_mac_address) ||
            isEmpty(value.r_dvc_serial_number) ||
            isEmpty(value?.deviceType?.r_dvc_typ_vendor)
            ? 'Fail'
            : 'Pass',
        _statusmessage:
          isEmpty(value.r_dvc_mac_address) ||
            isEmpty(value.r_dvc_serial_number) ||
            isEmpty(value.deviceType?.r_dvc_typ_vendor)
            ? 'MAC, serial number and device types are mandatory'
            : '',
      };
    });
    setSelectedItems(_selectedItems);
    const passedObjects= _selectedItems.filter((item) => item._status === 'Pass')
    const mapRequest = passedObjects
      .map((item) => {
        return _updateAsNetwork(item);
      });
    if (mapRequest.length === 0) {
      return;
    }

    setCellLoading(true);
    setLoading(true);

    const failedData = _selectedItems
      ?.filter((item) => item?._isDirty === undefined || item?._status === 'Fail')
      .map((item) => item);
    // setSelectedItems(failedData);

    Promise.all(mapRequest)
      .then((res) => {
        const filteredData = res.filter((item) => {
          return item.response?.status === 422;
        });
        setCellLoading(false);
        setLoading(false);

        res.forEach((item, index) => {
          if (item.response?.status === 422) {
            failedData.push({
              ...passedObjects[index],
              _status : "Fail",
              _statusmessage : item.response?.data?.message
            })
            enqueueSnackbar(
              item.response?.data?.message,
              {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              },
              500
            );
          }
          if (item?.status === 200) {
            enqueueSnackbar(
              'Device added as network successfully',
              {
                variant: 'success',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              },
              500
            );
            props.gridApi.purgeInfiniteCache();
          }
        });
        setSelectedItems(failedData)
      })
      .catch((res) => {
        enqueueSnackbar(
          res,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  const updateRowItem = ({ id, field, value }) => {
    setIsDirty(true);
    setSelectedItems((_selecetedItems) => {
      return [
        ..._selecetedItems.map((item) => {
          if (item._uid === id) {
            item[field] = value;
            item._isDirty = true;
          }
          return item;
        }),
      ];
    });
  };

  const _updateAsNetwork = async (value) => {
    const data = {
      _uid: value._uid,
      type: 'NETWORK',
      name: value.r_dvc_name,
      ip_address: value.r_dvc_ip_address,
      serial_number: value.r_dvc_serial_number,
      mac_address: value.r_dvc_mac_address,
      host: value.r_dvc_host,
      status: value.g_status,
      type_name: value.deviceType.r_dvc_typ_name,
      vendor: value.deviceType.r_dvc_typ_vendor,
      os: value.deviceType.r_dvc_typ_os,
      source:"MANUAL"
    };
    return markAsInventory(customerId, value._uid, data);
  };

  // ----------------------------------------------------------------------------------------------------------

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <DialogTitle id="responsive-dialog-title" sx={{ py: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Typography style={{ display: 'flex', flexDirection: 'column' }}>
            Add to network devices
            <span style={{ fontSize: '12px', fontWeight: 'lighter', color: 'red' }}>Fields with * are mandatory</span>
          </Typography>
          <IconButton onClick={handleClose} sx={{ float: 'right' }}>
            <CloseIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ py: 3, px: 3 }}>
          <DataGrid
            getRowId={(row) => {
              return row.id;
            }}
            rows={selectedItems}
            columns={columns}
            autoHeight
            disableColumnMenu
            pinnedColumns={{ right: ['action'] }}
            onCellEditCommit={(params, event) => {
              updateRowItem(params);
            }}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            type="submit"
            onClick={() => {
              handleSubmit();
            }}
          // disabled={!_isDirty}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
