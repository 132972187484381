import { IconButton, Tooltip } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import {toCamelizeWords } from './formatString';

const TooltipRendererForCmdb = (value) => {
    if (value?.data?.network_device?.r_dvc_mgmt_ip) {
      return (
        <Tooltip
          title={
            <div>
              <p>Management IP : {value?.data?.network_device?.r_dvc_mgmt_ip}</p>
              <p>Management Type : {toCamelizeWords(value?.data?.network_device?.r_dvc_mgmt_type)} </p>
            </div>
          }
        >
          <IconButton size="small">
            <StarIcon color="primary" fontSize="inherit" />
          </IconButton>
        </Tooltip>
      );
    }
    return null;
  };
  export default TooltipRendererForCmdb;
