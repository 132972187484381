import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useContext, useEffect, useState } from 'react';
import { CMDBSkipPause, CMDBUnskipResume } from '../../../API/CMDBOperations/index';
import { UserContext } from '../../../context/UserContext';
import { WithConfirm } from '../../WithConfirm';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------


const ActionRenderer = (props) => {
  const { data, confirm } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [skipLoading, setSkipLoading] = useState(false);
  const [unskipLoading, setUnskipLoading] = useState(false);

  const [ backupable, setBackupable] = useState(true)

  useEffect(()=>{
    if(data){
      if(Object.prototype.hasOwnProperty.call(data, "r_dvc_backupable")){
        setBackupable(data?.r_dvc_backupable)
      }
    }
  }, [data])
  const handleSkip = () => {
    setSkipLoading(true);
    CMDBSkipPause(customerId, data?._uid)
      .then((res) => {
        setSkipLoading(false);
        setBackupable(false)
        enqueueSnackbar(
          'CMDB scan is skipped sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setSkipLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  }

  const handleUnskip = () => {
    setUnskipLoading(true);
    CMDBUnskipResume(customerId, data?._uid)
      .then((res) => {
        setUnskipLoading(false);
        setBackupable(true)
        enqueueSnackbar(
          'CMDB scan is un-skipped sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setUnskipLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  }

  if (props.data === undefined) {
    return '';
  }

  if (props?.data) {
    return (
      <>
        {backupable === true ? (
          <LoadingButton
            variant="outlined"
            loading={skipLoading}
            startIcon={<PauseIcon style={{ color: "red" }} />}
            onClick={confirm(() => handleSkip(data), {
              title: 'Information',
              confirmationText: 'Yes',
              disableOkBtn: false,
              description: 'Are you sure you want to skip scan ?',
            })}
          >
            Skip scan
          </LoadingButton>

        ) : (
          <LoadingButton 
            variant="outlined"
            loading={unskipLoading}
            startIcon={<PlayArrowIcon style={{ color: "green" }} />}
            onClick={confirm(() => handleUnskip(data), {
              title: 'Information',
              confirmationText: 'Yes',
              disableOkBtn: false,
              description: 'Are you sure you want to unskip scan ?',
            })}
          >
            Resume scan
          </LoadingButton>
        )
        }
        
      </>
    )
  }
};


ActionRenderer.propTypes = {
  data: PropTypes.object,
};

export default WithConfirm(ActionRenderer);
