/* eslint-disable */
import modem from '../modem';

export const getSitewiseTopology = async (id, data, controller) => {
  const newPro = modem.post(`/${id}/network/topology/sitewise`, data, {
    signal: controller.signal
  }).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const getGlobalTopology = async (id, data, controller) => {
  const newPro = modem.post(`/${id}/network/topology/global`, data, {
    signal: controller.signal
  }).then((response) => {
    return response.data.data;
  });
  return newPro;
};


export const getChildrensL2 = async (id, locationData, parentId, parentType, except) => {
  const data = {
    location_uuids: locationData,
    parent_id:parentId,
    l_dvc_typ_name : parentType,
    except: except
  }
  const newPro = modem.post(`/${id}/network/topology/children`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const getSiblingsL2 = async (id, parentId, parentType, controller) => {
  const data = {
    parentId,
    parentType
  }
  const newPro = modem.post(`/${id}/network/topology/sibling`, data, {
    signal: controller.signal
  }).then((response) => {
    return response.data.data;
  });
  return newPro;
};