import { DeleteOutlineOutlined, NotificationsActiveOutlined } from '@mui/icons-material';
import { Box, Button, Card, Divider, Grid, IconButton, LinearProgress, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { deleteEmail, getSchedulerEmail } from '../../../API/Scheduler/Scheduler';
import { UserContext } from '../../../context/UserContext';
import NoDataComponent from '../../NoDataComponent';
import { WithConfirm } from '../../WithConfirm';
import AddEmail from './AddEmail';

// ---------------------------------------------------------------------------------

const useStyles = makeStyles({
    CardContent: {
        padding: '0px',
    },
    tableContainer: {
        maxHeight: 550,
        "&::-webkit-scrollbar": {
            width: 7,
            height: '12px',
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: '#f1f1f1',
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: 'rgb(181, 179, 179)',
            borderRadius: '30px',
        },
        "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: '#555'
        }
    },
});

// -----------------------------------------------------------------------------------------------------------

const AddDeleteEmailCard = (props) => {
    const classes = useStyles();
    const { confirm } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { customerId } = useContext(UserContext);
    const [emailData, setEmailData] = useState([]);
    const [emailLoading, setEmailLoading] = useState(false);
    const [addEmailDialog, setAddEmailDialog] = useState(false);


    const fetchEmails = async () => {
        setEmailLoading(true);
        const res = await getSchedulerEmail(customerId);
        setEmailData(res);
        setEmailLoading(false);
    };

    useEffect(() => {
        fetchEmails();
    }, [customerId]);

    const handleDelete = (value) => {
        setEmailLoading(true);
        deleteEmail(customerId, value.uuid)
            .then((res) => {
                setEmailLoading(false);
                fetchEmails();
                enqueueSnackbar(
                    'Email deleted sucessfully',
                    {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        },
                    },
                    500
                );
            })
            .catch((res) => {
                setEmailLoading(false);
                enqueueSnackbar(
                    res.response.data.message,
                    {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        },
                    },
                    500
                );
            });
    };


    return (
        <>
            <Card>
                {/* <CardHeader
                    color="text.error"
                    sx={{ mb: 2 }}
                    title="E-Mails for notifications"
                    action={
                        <>
                            <Box sx={{ flexGrow: 1 }} />
                            <Button color="primary" onClick={() => setAddEmailDialog(true)} variant="outlined">
                                Add new email
                            </Button>
                        </>
                    }
                    avatar={
                        <NotificationsActiveOutlined />
                    }
                /> */}

                <Box>
                    <Grid container spacing={1} sx={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '5px' }}>
                            <NotificationsActiveOutlined />
                            <Typography variant="h5" gutterBottom sx={{ marginLeft: '10px', marginTop: '8px' }}> E-Mails for notifications </Typography>
                            <Box sx={{ flexGrow: 1 }} />
                        </Grid>

                        <Grid container item xs={6} sx={{ display: 'flex', flexDirection: "row", justifyContent: 'right', alignItems: 'center', paddingTop: '10px' }}>
                            <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                                <Button color="primary" onClick={() => setAddEmailDialog(true)} variant="outlined">
                                    Add new email
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

                <Divider />
                {emailLoading && <LinearProgress />}

                <TableContainer className={classes.tableContainer}>
                    <Table>
                        <TableBody>
                            {emailData?.data?.length === 0 ? (
                                <TableRow>
                                    <TableCell>
                                        <NoDataComponent noDataFoundText="Emails" />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                emailData?.data?.map((item, index) => {
                                    return (
                                        <TableRow key={item.email} sx={{ justifyContent: 'space-between', display: 'flex' }}>
                                            <TableCell sx={{ width: '95%' }}>
                                                <Typography color="textSecondary" variant="body1" gutterBottom>
                                                    {item.email}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ width: '5%' }}>
                                                <Tooltip title="Delete">
                                                    <IconButton
                                                        onClick={confirm((e) => handleDelete(item), {
                                                            title: 'Information',
                                                            confirmationText: 'Yes',
                                                            disableOkBtn: false,
                                                            description: 'Are you sure you want to delete this Email',
                                                        })}>
                                                        <DeleteOutlineOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
            {addEmailDialog && (
                <AddEmail
                    openDialog={addEmailDialog}
                    handleClose={() => setAddEmailDialog(false)}
                    fetchEmails={fetchEmails}
                />
            )}
        </>
    );
};

export default WithConfirm(AddDeleteEmailCard);
