import { Difference } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import DiffDialogwithTabs from '../../../SummaryComponents/Components/DiffDialogwithTab';

// ----------------------------------------------------------------------
  


// ---------------------------------------------------------------------
const ActionRenderer = (props) => {
  const { data } = props;
  const [differenceDialog, setDifferenceDialog] = useState(false);

  const handleClickOpenDiffDialog = () => {
    setDifferenceDialog(true);
  };

    if (props.data === undefined) {
    return '';
  }

  if (data) {
    return (
      <Box>
        <Tooltip title={'View Diff'}>
          <IconButton onClick={handleClickOpenDiffDialog}>
            <Difference fontSize="inherit" />
          </IconButton>
        </Tooltip>
        {differenceDialog && (
          <DiffDialogwithTabs
            openDialog={differenceDialog}
            goldenConfigPath={data.r_golden_config_path}
            currentConfigPath={data.r_change_backup_path}
            previousConfigPath={data.r_change_previous_path}
            data={data}
            cmdbGroupSummaryEvent={data.latest_group_summary}
            handleClose={() => setDifferenceDialog(false)}
          />
        )}
      </Box>
    );
  }
};


ActionRenderer.propTypes = {
  data: PropTypes.object,
};

export default ActionRenderer;
