import { Box, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import AffectedDeviceDialog from '../AffectedDeviceDialog';


const AffectedRenderer = (props) => {
  const { data } = props;
  const [openAffectedDeviceDialog, setAffectedDeviceDialog] = useState(false);

  const handleClickOpen = () => {
    setAffectedDeviceDialog(true);
  };

  if (props.data === undefined) {
    return '';
  }

  if (data) {
    return (
      <Box sx={{ py: 1.4 }}>
        <Tooltip title="View affected devices">
          <Typography
            gutterBottom
            onClick={handleClickOpen}
            sx={{ cursor: 'pointer', fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' }}
          >
            {data.r_grp_smry_affected_device_count} devices
          </Typography>
        </Tooltip>
        {openAffectedDeviceDialog && (
          <AffectedDeviceDialog
            openDialog={openAffectedDeviceDialog}
            handleClose={() => {
              setAffectedDeviceDialog(false)
            }}
            groupSummaryEvent={data}
            groupedApi={props.api}
          />
        )}
      </Box>
    );
  }
};

AffectedRenderer.propTypes = {
  data: PropTypes.object,
};

export default AffectedRenderer;
