import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useHealthContext } from '../userContextHealth';
import CPUChart from './components/Overview/CPUChart';
import MemoryFreeChart from './components/Overview/MemoryFreeChart';
import NetworkClients from './components/Overview/NetworkClients';
import OverviewDetails from './components/Overview/OverviewDetails';
import UtilizationChart from './components/Radio/UtilizationChart';
import Radio0FramesChart from './components/Radio/Radio0FramesChart';
import NoiseFloorChart from './components/Radio/NoiseFloorChart';
import RadioMgtFrames from './components/Radio/RadioMgtFrames';
import ClientsTable from './components/Client/ClientsTable';
import NetworksTable from './components/Networks/NetworksTable';
import InterfaceStatusTable from './components/Interface/InterfaceStatusTable';
import ThroughputBandwidth from './components/Interface/ThroughputBandwidth';
import InterfaceSelector from '../InterfaceSelector';

//-------------------------------------------------------------
const useStyles = makeStyles({
    skeletonLoader: {
        top: 0,
        left: 0,
        width: "100%",
        display: 'flex',
    },
    noDataSelectedContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px',
        border: '2px solid',
        borderColor: '#cfd8dc'
    }
});

//----------------------------------------------------

const ArubaOsHealthDashboardComponent = () => {
    const classess = useStyles();
    const { healthDashState } = useHealthContext()
    const [overviewExpanded, setOverviewExpanded] = useState(true);
    const [radioExpanded, setRadioExpanded] = useState(false);
    const [clientsExpanded, setClientsExpanded] = useState(false);
    const [interfaceExpanded, setInterfanceExpanded] = useState(false)
    const [networksExpanded, setNetworksExpanded] = useState(false);



    const handleOverviewChange = () => {
        setOverviewExpanded(!overviewExpanded);
    };

    const handleRadioChange = () => {
        setRadioExpanded(!radioExpanded);
    };

    const handleClientsChange = () => {
        setClientsExpanded(!clientsExpanded);
    };

    const handleInterfaceChange = () => {
        setInterfanceExpanded(!interfaceExpanded);
    };

    const handleNetworksChange = () => {
        setNetworksExpanded(!networksExpanded);
    };

    if (!healthDashState.selectedDevice) {
        return <Stack direction="column" className={classess.noDataSelectedContainer}>
            <Typography variant="h3" display="block" gutterBottom>
                Please select device
            </Typography>
            <Typography variant="subtitle1" display="block" gutterBottom>
                to view dashboard
            </Typography>
        </Stack>
    }

    return (
        <>
            <Box>
                <Accordion expanded={overviewExpanded} onChange={handleOverviewChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}>Overview</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <OverviewDetails overviewExpanded={overviewExpanded} />
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <NetworkClients overviewExpanded={overviewExpanded} />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={1}>
                                <Grid item sm={6} md={6} lg={6}>
                                    <CPUChart overviewExpanded={overviewExpanded} />
                                </Grid>
                                <Grid item sm={6} md={6} lg={6}>
                                    <MemoryFreeChart overviewExpanded={overviewExpanded} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                        <Accordion expanded={radioExpanded} onChange={handleRadioChange}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography gutterBottom sx={{ fontWeight: 800 }}>Radio</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={1}>
                                    <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                        <Grid item sm={6} md={6} lg={6}>
                                            <UtilizationChart radioExpanded={radioExpanded} />
                                        </Grid>
                                        <Grid item sm={6} md={6} lg={6}>
                                            <Radio0FramesChart radioExpanded={radioExpanded} />
                                        </Grid>
                                    </Grid>
                                    <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                        <Grid item sm={6} md={6} lg={6}>
                                            <NoiseFloorChart radioExpanded={radioExpanded} />
                                        </Grid>
                                        <Grid item sm={6} md={6} lg={6}>
                                            <RadioMgtFrames radioExpanded={radioExpanded} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                <Accordion expanded={interfaceExpanded} onChange={handleInterfaceChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography sx={{ fontWeight: 800 }}>Interface</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            <Grid container item sm={12} md={12} lg={12} sx={{ pb: 2 }}>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <InterfaceSelector interfaceExpanded={interfaceExpanded} />
                                </Box>
                            </Grid>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={8} md={8} lg={8}>
                                    <ThroughputBandwidth expandedState={interfaceExpanded} />
                                </Grid>
                                <Grid item sm={4} md={4} lg={4}>
                                    <InterfaceStatusTable expandedState={interfaceExpanded} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={clientsExpanded} onChange={handleClientsChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}>Clients</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <ClientsTable clientsExpanded={clientsExpanded} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={networksExpanded} onChange={handleNetworksChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 700 }}>Networks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <NetworksTable networksExpanded={networksExpanded} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
};

export default ArubaOsHealthDashboardComponent;
