import modem from '../modem';

export const getUser = async (data) => {
  const newPromise =  modem
    .get(`user/profile`, data)
    .then((response) => {
      return response.data.data;
    })
    .catch((response) => {
      throw response;
    });
    return newPromise
};
