import modem from '../modem';

export const getInventory = async (id, data, controller) => {
  const newPro = modem
    .post(`/${id}/inventory/network-types`, data, {
      // signal: controller.signal,
    })
    .then((response) => {
      return response.data.data;
    });
  return newPro;
};

export const addInventory = async (id, data) => {
  const newPromise = modem.post(`${id}/inventory/network-types`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const deleteInventory = async (id, unknownId, data) => {
  const newPromise = modem.delete(`${id}/inventory/network-types/${unknownId}`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const getDeviceByVendor = async (id, locations) => {
  const payload = {
    location_uuids: locations,
  };
  const newPromise = modem.post(`${id}/inventory/network-types/count-by-vendor`, payload).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

let getNetworkStatRequest = null;
export const getNetworkStat = async (id, data) => {
  if (getNetworkStatRequest) {
    return getNetworkStatRequest;
  }
  getNetworkStatRequest = modem.post(`${id}/location-statistics/advanced`, data).then((response) => {
    getNetworkStatRequest = null;
    return response.data.data;
  }).catch((e)=>{
    getNetworkStatRequest = null;
    throw e
  })

  return getNetworkStatRequest;
};

export const getDeviceByType = async (id, locations) => {
  const payload = {
    location_uuids: locations,
  };
  const newPromise = modem.post(`${id}/inventory/network-types/count-by-type`, payload).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const updateInventory = async (id, _uid, data) => {
  const newPromise = modem.post(`${id}/network-devices/${_uid}`, data).then((response) => {
    // return response.data.data;
    return response;
  });
  return newPromise;
};
export const markAsInventory = async (id, _uid, data) => {
  const newPromise = modem
    // .post(`${id}/network-devices/:network_device_uuid`, data)
    .post(`${id}/network-devices/${_uid}/v2`, data)
    .then((response) => {
      // return response.data.data;
      return response;
    })
    .catch((response) => {
      // return response.data.data;
      return response;
    });
  return newPromise;
};
export const getDevicesCount = async (id, locations) => {
  const payload = {
    location_uuids: locations,
  };
  const newPromise = modem.post(`${id}/statistics/network-devices`, payload).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const getEventsNetworkUpDown = async (id, data) => {
  const newPro = modem.post(`/${id}/events/network-up-down-v2`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const getConfigChangeEvents = async (id, data) => {
  const newPro = modem.post(`/${id}/network-devices/config-change-events`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const goldenConfigChangeEvents = async (id, uuid, data) => {
  const newPro = modem.put(`/${id}/network-devices/config-change-events/${uuid}/status`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const createNewDevice = async (id, data) => {
  const newPro = modem.post(`/${id}/network-devices`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const getGroupSummaryChangeEvents = async (customerId, data) => {
  const newPro = modem.post(`/${customerId}/network-devices/group-change-events`, data).then((response) => {
    return response.data.data;
  });  
  return newPro;
};

export const getGroupSummaryChangeEventMultiple = async (customerId, _uid) => {
  const newPro = modem.get(`/${customerId}/network-devices/group-change-events/${_uid}`).then((response) => {
    return response.data.data;
  });
  return newPro;
};
export const setGroupSummaryChangeEvents = async (id, uuid, data) => {
  const newPro = modem.put(`/${id}/network-devices/group-change-events/${uuid}/status`, data).then((response) => {
    return response.data.data;
  });
  return newPro;
};

export const deviceByTypeChart = async (id, locations) => {
  const payload = {
    location_uuids: locations,
  };
  const newPro = modem.post(`/${id}/inventory/statistics/count-by-type`, payload).then((response) => {
    return response.data;
  });
  return newPro;
};

export const deleteDevice = async (id, unknownId, data) => {
  const newPromise = modem.delete(`${id}/network-devices/${unknownId}`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};
