import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from "prop-types";
import { useContext } from 'react';
import { assignRole } from '../../../../API/Roles/roles';
import { UserContext } from '../../../../context/UserContext';
import { WithConfirm } from '../../../WithConfirm';
import MemberRoleSelector from '../MemberRoleSelector';

// ----------------------------------------------------------------


// -----------------------------------------------------------------

const RoleSelectorRenderer = (props) => {
  const { data, api } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);

  // -----------------------------------------------------------

  const selectRole = (value) => {
    const payload = {
      role_uuid: value.uuid,
    };
    assignRole(customerId, data.uuid, payload)
      .then(() => {
        props.api.purgeInfiniteCache();
        enqueueSnackbar(
          'Role assigned sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  // -----------------------------------------------------------

  if (data === undefined) {
    return '';
  }
  return (
    <Box sx={{width:'100%',marginTop:'7px'}}>
      <MemberRoleSelector
        label="Role"
        member={data}
        placeholder="Select role"
        selectRole={selectRole}
        gridApi={api}
        roleObject={data.roles?.length && data.roles[0]}
      />
    </Box>
  );
};

RoleSelectorRenderer.propTypes = {
  data: PropTypes.object,
  api: PropTypes.object, 
};

export default WithConfirm(RoleSelectorRenderer);
