import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Paper, Typography } from '@mui/material';

import ReactApexChart from 'react-apexcharts';
import cardStyles from '../../styles/cardsstyles';

const appExperienceData = {
  chart: {
    type: 'bar',
    height: 350,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      horizontal: true,
      barHeight: '30%',
    },
  },
  dataLabels: {
    enabled: false,
  },
  series: [
    {
      data: [
        {
          x: 'DHCP',
          y: 410,
        },
        {
          x: 'DNS',
          y: 430,
        },
        {
          x: 'AAA',
          y: 480,
        },
      ],
    },
  ],
};

const ServicesChart = (props) => {
  const classes = cardStyles();
  return (
    <Paper elevation={15} sx={{ height: '100%' }}>
      <Box style={{ display: 'flex', flexDirection: 'row', padding: 12 }}>
        <DisplaySettingsIcon className={classes.boxTitleIcon} />
        <Typography variant="h6" component="div" className={classes.boxTitle}>
        Services
        </Typography>
        <MoreHorizIcon
          style={{
            fontSize: '1.5rem',
            color: '#e0e0e0',
            float: 'right',
            borderRadius: '2px',
            marginTop: '15px',
            marginRight: '15px',
            borderColor: '#eeeeee',
          }}
        />
      </Box>
      <Box sx={{ padding: 3 }}>
        <ReactApexChart options={appExperienceData} series={appExperienceData.series} type="bar" />
      </Box>
    </Paper>
  );
};
export default ServicesChart;
