// material
import { Box, IconButton, Paper,Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DevicesUpIcon, EndPointIcon, LinksDownIcon, LinksUpIcon } from '../../../icons';
// components
// ----------------------------------------------------------------------
const useStyles = makeStyles({
  boxPadding: {
    padding: '12px',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  cardsContainer: {
    display: 'flex',
    gap: 16,
    marginRight: '8px',
    flexWrap: 'wrap',
    paddingLeft:'15px',
  },
});

// ----------------------------------------------------------------------

export default function SidewiseSubCards() {
  const classes = useStyles();
  return (
    <>
        <div className={classes.cardsContainer}>
          <Paper sx={{ minWidth: 'fit-content' }} elevation={7}>
            <Box className={classes.boxPadding}>
              <Box className={classes.boxItems}>
                <IconButton size="small" sx={{ color: 'green', background: '#c3e6c3', borderRadius: '12px', mr: 2 }}>
                  <DevicesUpIcon />
                </IconButton>
                <div>
                  <Typography variant="subtitle1" component="div">
                    Packet Loss
                  </Typography>
                  <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
                    0 (%)
                  </Typography>
                </div>
              </Box>
            </Box>
          </Paper>
          <Paper sx={{ minWidth: 'fit-content' }} elevation={15}>
            <Box className={classes.boxPadding}>
              <Box className={classes.boxItems}>
                <IconButton size="small" sx={{ color: 'red', background: '#e6b8bf', borderRadius: '12px', mr: 2 }}>
                  <DevicesUpIcon />
                </IconButton>
                <div>
                  <Typography variant="subtitle1" component="div">
                    Latency
                  </Typography>
                  <Typography sx={{ color: 'red' }} variant="subtitle1" component="div">
                    0.567 (ms)
                  </Typography>
                </div>
              </Box>
            </Box>
          </Paper>
          <Paper sx={{ minWidth: 'fit-content' }} elevation={15}>
            <Box className={classes.boxPadding}>
              <Box className={classes.boxItems}>
                <IconButton size="small" sx={{ color: 'green', background: '#c3e6c3', borderRadius: '12px', mr: 2 }}>
                  <LinksUpIcon />
                </IconButton>
                <div>
                  <Typography variant="subtitle1" component="div">
                    Jitter
                  </Typography>
                  <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
                    0.346 (ms)
                  </Typography>
                </div>
              </Box>
            </Box>
          </Paper>
          <Paper sx={{ minWidth: 'fit-content' }} elevation={15}>
            <Box className={classes.boxPadding}>
              <Box className={classes.boxItems}>
                <IconButton size="small" sx={{ color: 'red', background: '#e6b8bf', borderRadius: '12px', mr: 2 }}>
                  <LinksDownIcon />
                </IconButton>
                <div>
                  <Typography variant="subtitle1" component="div">
                    Up Time
                  </Typography>
                  <Typography sx={{ color: 'red' }} variant="subtitle1" component="div">
                     1d 2h 12m 49s
                  </Typography>
                </div>
              </Box>
            </Box>
          </Paper>
        </div>
    </>
  );
}
