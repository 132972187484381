import { Box, LinearProgress, Skeleton } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { fetchCredentials } from '../../../API/Credentials/listCredentials';
import { UserContext } from '../../../context/UserContext';
import { defaultAgGridProps } from '../../../utils/agGridProps';
import { WithConfirm } from '../../WithConfirm';
import ActionRenderer from './Renderer/ActionRenderer';

const APITable = (props) => {
  const [query, setQuery] = useState('');
  const { customerId } = useContext(UserContext);
  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [credentialsLoading, setCredentialsLoading] = useState(false);

  const columnHeaders = [
    {
      headerName: 'Endpoint',
      field: 'e_credential_apiendpoint',
      width: 258,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return <Skeleton variant="rectangular" height={30} style={{ margin: "7px 0" }} />
        }
        return params.value;
      },
      // valueGetter: (params) => {
      //   if (params.data === undefined) {
      //     return '';
      //   }
      //   return params.value;
      // },
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'API Token',
      field: 'e_credential_apikey',
      maxWidth: 950,
      minWidth: 950,
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Action',
      field: 'action',
      width: 150,
      // pinned: 'right',
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
      cellRenderer: 'actionRenderer',
    },
  ];

  useEffect(() => {
    setColumnDefs(columnHeaders);
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      minWidth: 150,
      suppressMenu: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    return params.api.setDatasource({
      rowCount: null,
      context: {
        global_keywords: query,
      },
      getRows: async (_params) => {
        setCredentialsLoading(true);
        params.api.hideOverlay();
        const payload = {
          ..._params,
          global_keywords: _params.context.global_keywords,
          filterModel: {
            ..._params.filterModel,
            r_credential_type: {
              filterType: 'text',
              type: 'CONTAINS',
              filter: 'API_KEY',
            },
          },
        };
        await fetchCredentials(customerId, payload).then((credential) => {
          let lastRow = -1;
          if (credential.total <= _params.endRow) {
            lastRow = credential.total;
          }
          if (credential.total === 0) {
            params.api.showNoRowsOverlay();
          }
          _params.successCallback(credential.data, lastRow);
          setCredentialsLoading(false);
        });
      },
    });
  }, []);

  const components = useMemo(() => ({
    actionRenderer: ActionRenderer,
  }), []);


  return (
    <div>
      {credentialsLoading && <LinearProgress />}

      <Box style={{ height: '600px', width: '100%' }} className="ag-theme-material">
        <AgGridReact
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          {...defaultAgGridProps}
          components={components}
          context={{
            global_keyword: props.query,
          }}
        />
      </Box>
    </div>
  );
};

export default WithConfirm(APITable);
