import { Link } from 'react-router-dom';
import { Breadcrumbs, Container, Typography, Grid, Divider } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Page from '../components/Page';
import { EmailConfigComponents } from '../components/EmailConfigComponents';

// ----------------------------------------------------------------------

export default function Endpoint() {
  return (
    <Page title="Email Config">
      <Container maxWidth={false}>
        <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '32px' }}>
          <Breadcrumbs aria-label="breadcrumb">
          <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{pb:1.5}}>
              <HomeOutlinedIcon />
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/settings">
              Settings
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/email-config">
              Email configuration
            </Typography>
          </Breadcrumbs>
          <Divider sx={{ marginTop: '15px', width: '100%' }} />
        </div>

        <Grid container>
          <EmailConfigComponents />
        </Grid>
      </Container>
    </Page>
  );
}
