import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, InputAdornment, LinearProgress, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { changePassword } from '../../../API/UserAuth/changePassword';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

// ----------------------------------------------------------------------

export default function ChangePassword() {
  const { enqueueSnackbar } = useSnackbar();

  const [formLoading, setFormLoading] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const ChangePasswordSchema = Yup.object().shape({
    current: Yup.string().required('Password is required'),
    new: Yup.string().required('New Password is required'),
    confirm: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('new')], 'Passwords does not match'),
  });

  const defaultValues = {
    current: '',
    new: '',
    confirm: '',
  };

  const methods = useForm({
    resolver: yupResolver(ChangePasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const onSubmit = async (d) => {
    const data = {
      current: d.current,
      password: d.new,
      confirm: d.confirm,
    };
    try {
      setFormLoading(true);
      const res = await changePassword(data);
      setFormLoading(false);
      reset(res);
      enqueueSnackbar('Password updated sucessfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    } catch (error) {
      setFormLoading(false);
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  return (
    <>
      {formLoading && <LinearProgress style={{ width: '100%' }} />}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h6" gutterBottom sx={{ py: 2 }}>
          Change Password
        </Typography>

        <Stack spacing={3}>
          <RHFTextField
            required
            sx={{ width: '49%' }}
            name="current"
            label="Current password"
            type={showCurrentPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowCurrentPassword(!showCurrentPassword)} edge="end">
                    <Iconify icon={showCurrentPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
            <RHFTextField
              required
              name="new"
              label="New password"
              sx={{ marginRight: '5px' }}
              type={showNewPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                      <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <RHFTextField
              required
              name="confirm"
              label="Confirm password"
              sx={{ marginLeft: '5px' }}
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                      <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          {/* {errorMsg && errorMsg.length === 0 ? '' : <Typography gutterBottom sx={{ color: 'red'}}>
        {errorMsg}</Typography>} */}
        </Stack>

        <Box sx={{ py: 2 }}>
          <LoadingButton
            type="submit"
            loading={isSubmitting}
            color="primary"
            sx={{ mr: 1, height: '40px', float: 'right' ,width:'150px'}}
            variant="outlined"
          >
            Update password
          </LoadingButton>
        </Box>
      </FormProvider>
    </>
  );
}
