import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectSubnets() {
  const [asset, setAsset] = React.useState('');

  const handleChange = (event) => {
    setAsset(event.target.value);
  };

  return (
    <>
      <FormControl sx={{width:'250px'}}>
        <InputLabel id="demo-simple-select-label">Subnets</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={asset}
          label="Select Subnet"
          onChange={handleChange}
          sx={{height:'45px'}}
        >
          <MenuItem value={10}>10.10.1.1/24</MenuItem>
          <MenuItem value={20}>10.10.1.1/25</MenuItem>
          <MenuItem value={30}>10.10.1.1/26</MenuItem>
          <MenuItem value={40}>10.10.1.1/27</MenuItem>
          <MenuItem value={50}>10.10.1.1/28</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
