import {
  Delete, Refresh, Search
} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Card, CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton, InputAdornment,
  LinearProgress, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState, useCallback } from 'react';
import { deleteRole, getRoles } from '../../../API/Roles/roles';
import { UserContext } from '../../../context/UserContext';
import { toCamelizeWords } from '../../../utils/formatString';
import NoDataComponent from '../../NoDataComponent';
import { WithConfirm } from '../../WithConfirm';
import ReadPermissionsDialog from './ReadPermissionsDialog';




// ---------------------------------------------------------------------------------

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: 550,
    "&::-webkit-scrollbar": {
      width: 7,
      height: '12px',
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: '#f1f1f1',
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: 'rgb(181, 179, 179)',
      borderRadius: '30px',
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: '#555'
    }
  },
  cardAvatar: {
    width: '28px',
    height: '28px',
    fontSize: '12px',
    marginLeft: '10px',
  },
  circularprogress: {
    position: 'absolute',
    left: 12,
    top: 18,
  },
  cardActionButtons: {
    height: '40px'
  },
  AvatarBox: {
    alignItems: 'center',
    display: 'flex'
  },
  Avatar: {
    height: 42,
    width: 42,
  },
});

// -----------------------------------------------------------------------------------------------------------

const RolesTable = (props) => {
  const classes = useStyles();
  const { confirm } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  // const [permissionOptions, setPermissionOptions] = useState([]);
  const [rolesLoading, setRolesLoading] = useState(false);
  // const [rolePermission, setRolePermissions] = useState([]);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [countLoading, setCountLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [count, setCount] = useState(0);
  const [checked, setChecked] = useState({});
  const [deleteButton, showDeleteButton] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [roleData, setRoleData] = useState('');
  const [permissionDialog, setPermissionDialog] = useState(false);
  // const [value, setValue] = useState([]);


  const fetchRoles = useCallback(async () => {
    const data = {
      keyword: query,
    };
    setCountLoading(true);
    setRolesLoading(true)
    await getRoles(customerId, data)
      .then((res) => {
        setRoleOptions(res);
        setCount(res.data.length);
        setCountLoading(false);
        setRolesLoading(false);
        setRefreshLoading(false)
      })
  }, [customerId, query]);

  // const _getPermissions = useCallback(async () => {
  //   setRolesLoading(true);
  //   try {
  //     const permissions = await getPermissions(customerId);
  //     if (!props.role) {
  //       setRolePermissions(
  //         Object.keys(permissions)
  //           .map((group) => {
  //             return [
  //               ...Object.keys(permissions[group]).map((ability) => {
  //                 return {
  //                   ability,
  //                   group,
  //                   name: permissions[group][ability],
  //                 };
  //               }),
  //             ];
  //           })
  //           .reduce((value, pre) => [...pre, ...value])
  //       );
  //     } else {
  //       setRolePermissions(props.role.permissions);
  //     }
  //     // setPermissionOptions(permissions);
  //   } catch (error) {
  //     enqueueSnackbar("Couldn't recieve pemissios list, please try later");
  //     setRolesLoading(false);
  //   } finally {
  //     setRolesLoading(false);
  //   }
  // }, [customerId, props.role, enqueueSnackbar]);

  useEffect(() => {
    fetchRoles();
  }, [customerId, fetchRoles]);

  const filteredRoles = roleOptions?.data?.filter((customer) =>
    customer?.name.toLowerCase()?.includes(query?.toLowerCase())
  );

  const handleRefresh = () => {
    setRefreshLoading(true);
    // _getPermissions();
    fetchRoles();
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    const data = {
      keyword: event.target.value,
    };
    setCountLoading(true);
    setRolesLoading(true);
    getRoles(customerId, data)
      .then((res) => {
        setRoleOptions(res);
        setCount(res.data.total);
        setCountLoading(false);
        setRolesLoading(false);
        setRefreshLoading(false)
      })
  };

  // const handleCheckMultipleRows = (event) => {
  //   const _checked = { ...checked, [event.target.name]: event.target.checked };
  //   setChecked(_checked);
  //   if (Object.keys(_checked).filter((uid) => _checked[uid]).length) {
  //     showDeleteButton(true);
  //   } else {
  //     showDeleteButton(false);
  //   }
  //   setValue(event.target.name);
  // };

  const handleMultipleDelete = (value) => {
    const data = {
      role_name: value.name,
    };
    setDeleteLoading(true);
    setRolesLoading(true);
    setRefreshLoading(true);
    Promise.all(
      Object.keys(checked)
        .filter((id) => checked[id] === true)
        .map((id) => {
          return deleteRole(customerId, id, data);
        })
    )
      .then(() => {
        enqueueSnackbar(
          'selected roles deleted sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        setDeleteLoading(false);
        showDeleteButton(false);
        setRolesLoading(false);
        setRefreshLoading(false);
        fetchRoles();
        setChecked({});
      })
      .catch((res) => {
        setDeleteLoading(false);
        showDeleteButton(false);
        setRolesLoading(false);
        setRefreshLoading(false);
        setChecked({});
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  const handleDelete = (value) => {
    setDeleteLoading(true);
    setRolesLoading(true);
    setRefreshLoading(true);
    const data = {
      role_name: value.name,
    };
    deleteRole(customerId, value.uuid, data)
      .then(() => {
        setDeleteLoading(false);
        setRolesLoading(false);
        setRefreshLoading(false);
        fetchRoles();
        enqueueSnackbar(
          'Roles deleted sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setDeleteLoading(false);
        setRolesLoading(false);
        setRefreshLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  const handleEdit = (value) => {
    setRoleData(value);
    setPermissionDialog(true);
  };


  // ----------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Card>
        <CardHeader
          style={{ padding: '10px' }}
          avatar={
            <Grid
              container
              spacing={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
              }}
            >
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                <Chip label={count || 0} />
                {countLoading && (
                  <CircularProgress size={30} className={classes.circularprogress} />
                )}
              </Grid>
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                <Typography variant="h5" gutterBottom sx={{ marginTop: '8px' }}> Roles </Typography>
              </Grid>
            </Grid>
          }
          action={
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
                paddingTop: '10px',
              }}
            >
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                {deleteButton ? (
                  <Tooltip title="Delete">
                    <LoadingButton
                      onClick={confirm(() => handleMultipleDelete(roleOptions), {
                        title: 'Information',
                        confirmationText: 'Yes',
                        disableOkBtn: false,
                        description: 'Are you sure you want to delete selected Roles?',
                      })}
                      loading={deleteLoading}
                      variant="outlined"
                      sx={{ height: '40px' }}
                    >
                      Delete
                    </LoadingButton>
                  </Tooltip>
                ) : (
                  ''
                )}
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <TextField
                  focused
                  autoFocus
                  type="text"
                  size="small"
                  value={query}
                  variant="outlined"
                  name='new-password'
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search roles"
                />
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: -1.8 }}
              >
                <IconButton color="primary" onClick={handleRefresh} sx={{ mt: '-5px' }}>
                  {refreshLoading ? (
                    <CircularProgress size={27} sx={{ mr: '5px' }} />
                  ) : (
                    <Refresh sx={{ fontSize: '2rem' }} />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          }
        />

        <Divider />
        {rolesLoading && <LinearProgress />}

        <CardContent style={{ padding: '0px' }}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader>
              {filteredRoles?.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <NoDataComponent noDataFoundText="Roles" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '90%' }}>Role name</TableCell>
                      {/* <TableCell style={{ width: '70%' }}>Permission</TableCell> */}
                      <TableCell style={{ width: '10%' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRoles?.map((item, key) => {
                      return (
                        <TableRow hover key={key}>
                          {/* <TableCell>
                            <MultipleCheckbox
                              name={item.uuid}
                              checked={checked[item.uuid]}
                              onChange={handleCheckMultipleRows}
                            />
                          </TableCell> */}
                          <TableCell style={{ width: '90%' }}>
                            <Box
                              sx={{
                                alignItems: 'center',
                                display: 'flex',
                              }}
                            >
                              <Box sx={{ ml: 1 }}>
                                {toCamelizeWords(item.name)}
                              </Box>
                            </Box>
                          </TableCell>

                          <TableCell style={{ width: '10%' }}>
                            <Tooltip title="Edit">
                              <IconButton onClick={() => handleEdit(item)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={confirm(() => handleDelete(item), {
                                  title: 'Information',
                                  confirmationText: 'Yes',
                                  disableOkBtn: false,
                                  description: 'Are you sure you want to delete this member',
                                })}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      {permissionDialog && (
        <ReadPermissionsDialog
          handleRefresh={handleRefresh}
          openDialog={permissionDialog}
          roleuuid={roleData.uuid}
          handleClose={() => setPermissionDialog(false)}
          fetchRoles={fetchRoles}
        />
      )}
    </>
  );
};
export default WithConfirm(RolesTable);

RolesTable.propTypes = {
  confirm: PropTypes.func.isRequired,
  // role: PropTypes.object, 
  // permissionOptions: PropTypes.array, 
  // rolePermission: PropTypes.array, 
  // value: PropTypes.any, 
};