// @mui
import { Box, Grid } from '@mui/material';
import { useState, useContext } from 'react';
import { alpha, styled } from '@mui/material/styles';
import LocationSelect from '../NetworkHealthComponents/LocationSelect';
import { InventoryCharts, InventoryTable } from './components';
import { UserContext } from '../../context/UserContext';
// -------------------------------------------------------------------------------------------

const DRAWER_WIDTH = 0;
const BoxStyle = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 20,
  zIndex: 5,
  flexWrap: 'nowrap',
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

// ----------------------------------------------------------------------------------------------

const InventoryComponent = (props) => {
  const [deviceTypes, setDeviceTypes] = useState('');
  const [vendorsType, setVendorsType] = useState('');
  const [devicesData, setDevicesData] = useState({});
  const { customerId } = useContext(UserContext);
  const { locationData } = props;


  const selectDeviceType = async (_deviceType) => {
    setDeviceTypes(_deviceType);
  };

  const selectVendors = async (_vendor) => {
    setVendorsType(_vendor);
  };

  return (
    <Grid container spacing={4}>

      <Grid container item spacing={3}>
        <InventoryCharts
          locationData={locationData}
          devicesData={devicesData}
          selectDeviceType={selectDeviceType}
          selectVendors={selectVendors}
        />
      </Grid>

      <Grid item lg={12} md={12} sm={12}>
        <InventoryTable
          locationData={locationData}
          deviceTypes={deviceTypes}
          setDeviceTypes={setDeviceTypes}
          vendorsType={vendorsType}
          setVendorsType={setVendorsType}
        />
      </Grid>
    </Grid>
  );
};
export default InventoryComponent;
