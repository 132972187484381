import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import AgGridHealthDashboard from './AgGridHealthDashboard';
import ArubaOsHealthDashboardComponent from './components/ArubaOs/ArubaOsHealthDashboardComponent';
import FortinetHealthDashboardComponent from './components/Fortinet/FortinetHealthDashboardComponent';
import SwitchHealthDashboardComponent from './components/Switch/SwitchHealthDashboardComponent';
import WLCHealthDashboardComponent from './components/WLC/WLCHealthDashboardComponent';
import WMMHealthDashboardComponent from './components/WMM/WMMHealthDashboardComponent';
import WirelessHealthDashboardComponent from './components/Wireless/WirelessHealthDashboardComponent';
import { useHealthContext } from './components/userContextHealth';

//----------------------------------------------------

const useStyles = makeStyles({
    skeletonLoader: {
        top: 0,
        left: 0,
        width: "100%",
        height: 600,
        display: 'flex',
    },
    noDataSelectedContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px',
        border: '2px solid',
        borderColor: '#cfd8dc'
    }
});

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    width: "100%",
    height: 500,
}));

//----------------------------------------------------

const HealthDashboardComponent = () => {

    const classess = useStyles();
    const { healthDashState } = useHealthContext();

    if (!healthDashState.locationuuid) {
        return (
            <ChartWrapperStyle dir="ltr">
                <Stack direction="column" className={classess.noDataSelectedContainer}>
                    <Typography variant="h3" display="block" gutterBottom>
                        Please select location
                    </Typography>
                    <Typography variant="subtitle1" display="block" gutterBottom>
                        to view dashboard
                    </Typography>
                </Stack>
            </ChartWrapperStyle>
        )
    }
    if (healthDashState?.selectedDevice?.l_dvc_typ_name === "FIREWALL") {
        return <FortinetHealthDashboardComponent />
    }
    if (healthDashState?.selectedDevice?.l_dvc_typ_name === "ACCESS_POINT") {
        return <ArubaOsHealthDashboardComponent />
    }
    if (healthDashState?.selectedDevice?.l_dvc_typ_name === "SWITCH") {
        return <SwitchHealthDashboardComponent />
    }
    if (healthDashState?.selectedDevice?.l_dvc_typ_name === "WIRELESS_MOBILITY_MASTER") {
        return <WMMHealthDashboardComponent />
    }
    if (healthDashState?.selectedDevice?.l_dvc_typ_name === "WIRELESS_VIRTUAL_CONTROLLER") {
        return <WLCHealthDashboardComponent />
    }
    if (healthDashState?.selectedDevice?.l_dvc_typ_name === "WIRELESS") {
        return <WirelessHealthDashboardComponent />
    }
    return (
        <AgGridHealthDashboard />
    );
};

export default HealthDashboardComponent;
