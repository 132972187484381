import { IconButton, Tooltip } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import {toCamelizeWords } from './formatString';

const TooltipRenderer = (rDvcMgmtIp, rDvcMgmtType) => {
  if (rDvcMgmtIp && rDvcMgmtIp.length) {
    return (
      <Tooltip
        title={
          <div>
            <p>Management IP : {rDvcMgmtIp}</p>
            <p>Management Type : {toCamelizeWords(rDvcMgmtType)} </p>
          </div>
        }
      >
        <IconButton size="small">
          <StarIcon color="primary" fontSize="inherit" />
        </IconButton>
      </Tooltip>
    );
  }
  return null;
};
export default TooltipRenderer;
