/* eslint-disable */
import { ContentCopy, Refresh, Search, Settings } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getUnknownDevices } from '../../API/Inventory/Inventory';
import { UserContext } from '../../context/UserContext';
import { defaultAgGridProps } from '../../utils/agGridProps';
import { getSortedColumnDef, mapColumnDefinitionToStore } from '../../utils/columnDef';
import EndpointsDialog from './EndpointsDialog';
import NetworkDevicesDialog from './NetworkDevicesDialog';
import ColumnSettingsDialog from './filters/ColumnSettingsDialog';
import { KeyRenderer } from './renderer';



const useStyles = makeStyles(() => ({
  containerHieght: {
    height: '100%',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    visibility: 'hidden',
  },
  field: {},
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  downHight: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    marginTop: 5,
  },
  progressStyle: {
    position: 'absolute',
    left: 15,
    top: 20,
  },
}));

export default function UnknownDevicesTable(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { locationData } = props;
  const { customerId } = useContext(UserContext);
  const [gridApi, setGridApi] = useState(null);
  const [openNetworkDialog, setOpenNetworkDialog] = useState(false);
  const [openEndPointDialog, setOpenEndPointDialog] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(null);
  const [query, setQuery] = useState('');
  const [openColSettingsDialog, setOpenColSettingsDialog] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);


  const handleClose = () => {
    setOpenEndPointDialog(false);
    setOpenNetworkDialog(false);
  };

  const handleRefresh = () => {
    setRefreshLoading(true);
    gridApi?.refreshInfiniteCache();
    setTimeout(() => setRefreshLoading(false), 2000);
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.gridOptionsWrapper.gridOptions.context = {
        ...gridApi.gridOptionsWrapper.gridOptions.context,
        location_uuids: locationData,
      };
      gridApi.purgeInfiniteCache();
    }
  }, [locationData]);

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem('nms-unknown-devices-column'));
    if (stored === null) {
      setColumnDefs(columnHeaders);
    } else {
      setColumnDefs(getSortedColumnDef(columnHeaders, stored));
    }
  }, []);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    return params.api.setDatasource({
      rowCount: null,
      context: {
        global_keyword: query,
      },
      getRows: async (_params) => {
        setRefreshLoading(true);
        params.api.hideOverlay();
        const payload = {
          ..._params,
          global_keyword: _params.context.global_keyword,
          location_uuids: _params.context.location_uuids,
        };
        delete payload.context;
        getUnknownDevices(customerId, payload)
        .then((devicesList) => {
          let lastRow = -1;
          if (devicesList.total <= _params.endRow) {
            lastRow = devicesList.total;
          }
          setTotalItems(devicesList.total);
          if (devicesList.total === 0) {
            params.api.showNoRowsOverlay();
          }
          _params.successCallback(devicesList.data, lastRow);
          setRefreshLoading(false);
        })
        .catch((e) => {
          console.log(e,'error');
          setRefreshLoading(false);
          _params.failCallback();
        });
      },
    });
  }, []);

  const onCopyText = (e, text) => {
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
    setTimeout(() => { }, 500);
  };

  const handleClickOpenColSettingsDialog = () => {
    setOpenColSettingsDialog(true);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    gridApi?.purgeInfiniteCache();
  };

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      floatingFilter: true,
      suppressMenu: true,
      filter: true,
    };
  }, []);

  const columnHeaders = [
    {
      headerName: 'Location',
      colId: 'r_location_name',
      checkboxSelection: true,
      width:200,
      filter: false,
      floatingFilter: false,
      editable: false,
      sortable: false,
      // pinned: 'left',
      headerClass: 'checkbox',
      cellRenderer: 'keyRenderer',
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400', },
    },
    {
      headerName: `IP address`,
      colId: 'r_dvc_ip_address',
      sortable: true,
      width:200,
      editable: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params.data?.r_dvc_ip_address) {
          return (
            <div className={classes.root}>
              {params?.data?.r_dvc_ip_address}
              <span className={classes.field}>
                <CopyToClipboard
                  text={params?.data?.r_dvc_ip_address}
                  options={{ format: 'text/plain' }}
                  onCopy={(e) => onCopyText(e, params?.data?.r_dvc_ip_address)}
                >
                  <Tooltip title={'Click to copy'}>
                    <IconButton size="small" className={classes.icon}>
                      <ContentCopy fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </span>
            </div>
          );
        }
      },
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400', },
    }, {
      headerName: `Information`,
      colId: 'r_dvc_error',
      floatingFilter: false,
      sortable: true,
      filter: false,
      width:200,
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_error;
      },
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400', },
    },
    {
      headerName: `Description`,
      colId: 'r_dvc_description',
      floatingFilter: false,
      sortable: true,
      filter: false,
      width:200,
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.r_dvc_description;
      },
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400', },
    },
  ];

  const components = useMemo(() => ({
    keyRenderer: KeyRenderer,
  }), []);

  const _openNetworkDialog = () => {
    setSelectedData(gridApi?.getSelectedRows());
    setOpenNetworkDialog(true);
  };

  const _openEndpointDialog = () => {
    setSelectedData(gridApi?.getSelectedRows());
    setOpenEndPointDialog(true);
  };

  const onSelectionChanged = () => {
    var selectedRows = gridApi?.getSelectedRows();
    if (selectedRows.length === 0) {
      setSelectedRowCount(0);
    } else {
      setSelectedRowCount(selectedRows.length);
    }
  };

  const isExternalFilterPresent = useCallback(() => {
    return true;
  }, []);

  const resetColumnSetting = () => {
    gridApi.setColumnDefs([]);
    setColumnDefs([...columnHeaders]);
    gridApi.setColumnDefs([...columnHeaders]);
    localStorage.setItem('nms-unknown-devices-column', JSON.stringify(mapColumnDefinitionToStore(columnHeaders)));
    setOpenColSettingsDialog(false);
  };

  const onDragStopped = (params) => {
    const columnState = params.columnApi.getColumnState();
    const _afterDragColumnDefs = columnState.reduce((a, b) => {
      const a1 = columnHeaders.find((e) => e.colId === b.colId);
      return a.concat(Object.assign(a1, b));
    }, []);
    setColumnDefs([..._afterDragColumnDefs]);
    localStorage.setItem(
      'nms-unknown-devices-column',
      JSON.stringify(mapColumnDefinitionToStore(_afterDragColumnDefs))
    );
  };

  return (
    <>
      <Card>
        <CardHeader
          style={{ padding: '10px' }}
          avatar={
            <Grid container spacing={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
                paddingTop: '10px',
              }}
            >
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                <Chip label={totalItems || 0} />
                {refreshLoading && <CircularProgress size={30} className={classes.progressStyle} />}
              </Grid>
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                <Typography variant="h5" gutterBottom sx={{ marginLeft: '8px', marginTop: '8px' }}> Unknown Devices </Typography>
              </Grid>
            </Grid>
          }
          action={
            <Grid container spacing={1} sx={{ display: 'flex', flexDirection: "row", justifyContent: 'right', alignItems: 'center', paddingTop: '10px' }}>
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                  <div className={classes.buttonContainer}>
                    {selectedRowCount > 0 && <Typography>{selectedRowCount} Item(s) selected</Typography>}
                    {selectedRowCount > 0 && (
                      <Stack direction="row" sx={{ ml: 2, height: '40px' }} spacing={2}>
                        <Button onClick={_openNetworkDialog} sx={{ mr: 1 }} variant="contained">
                          Mark as network devices
                        </Button>
                        {/* <Button onClick={_openEndpointDialog} variant="contained">
                          Mark as end points
                        </Button> */}
                      </Stack>
                    )}
                  </div>
              </Grid>

              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                <TextField
                  focused
                  autoFocus
                  type="text"
                  size="small"
                  value={query}
                  variant="outlined"
                  name='new-password'
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search devices"
                />
              </Grid>

              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                <IconButton color="primary" onClick={handleRefresh}>
                  {refreshLoading ? (
                    <CircularProgress size={27} sx={{ mr: '5px' }} />
                  ) : (
                    <Refresh sx={{ fontSize: '2rem' }} />
                  )}
                </IconButton>
              </Grid>

              <Grid
              item
              xs="auto"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
            >
              <Box>
                <Tooltip title="Column settings">
                  <IconButton color="primary" onClick={handleClickOpenColSettingsDialog}>
                    <Settings sx={{ fontSize: '2rem' }} />
                  </IconButton>
                </Tooltip>
                {openColSettingsDialog && (
                  <ColumnSettingsDialog
                    gridApi={gridApi}
                    columnDefs={columnDefs}
                    openDialog={openColSettingsDialog}
                    resetColumnSetting={resetColumnSetting}
                    handleClose={() => setOpenColSettingsDialog(false)}
                  />
                )}
              </Box>
            </Grid>
            </Grid>
          }
        />

        <Divider />
        {refreshLoading && <LinearProgress />}

        <Box style={{ height: '500px', width: '100%' }} className="ag-theme-material">
          <AgGridReact
            onGridReady={onGridReady}
            columnDefs={columnDefs}
            onDragStopped={onDragStopped}
            defaultColDef={defaultColDef}
            components={components}
            onSelectionChanged={onSelectionChanged}
            isExternalFilterPresent={isExternalFilterPresent}
            {...defaultAgGridProps}
            context={{
              global_keyword: query,
              location_uuids: locationData,
            }}
          />
        </Box>
      </Card>
      {openNetworkDialog && (
        <NetworkDevicesDialog
          openDialog={openNetworkDialog}
          handleClose={handleClose}
          selectedRecords={selectedData}
          setSelectedData={setSelectedData}
          gridApi={gridApi}
        />
      )}
      {openEndPointDialog && (
        <EndpointsDialog
          openDialog={openEndPointDialog}
          handleClose={handleClose}
          selectedRecords={selectedData}
          setSelectedData={setSelectedData}
        />
      )}
    </>
  );
}
