import { CircularProgress } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { addZone } from '../../../API/Zones/zones';
import { UserContext } from '../../../context/UserContext';

// ------------------------------------------------------------------

export default function DcZoneSelector(props) {
  const { zoneOptions, zoneValue, setZoneValue, zoneLoading } = props;
  const { customerId } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [options, setOptions] = useState([]);
  const filter = createFilterOptions();

  useEffect(() => {
    setOptions(
      zoneOptions.map((item) => {
        return {
          id: item._uid,
          name: item.r_zone_name,
        };
      })
    );
  }, [zoneOptions]);

  const _addZone = (value) => {
    const data = {
      name: value,
    };
    return addZone(customerId, data)
      .then((res) => {
        enqueueSnackbar(
          'Zone added sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        return Promise.resolve(res);
      })
      .catch((res) => {
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  // ------------------------------------------------------

  return (
    <Autocomplete
      value={zoneValue}
      fullWidth
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setZoneValue({
            name: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          _addZone(newValue.inputValue)
          .then((res) => {
            setZoneValue({
              name: res.r_zone_name,
              id: res._uid,
            });
          });
        } else {
          setZoneValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue.toLowerCase() === option.name.toLowerCase());
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            label: `Create "${inputValue}"`,
            name:inputValue,
            value:inputValue,
            create: true,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      getOptionLabel={(option) => {
        return option.name;
      }}
      renderOption={(props, option) => <li {...props}>{option.create ? option.label : option.name}</li>}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select zones"
          label="Zone (optional)"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {zoneLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

DcZoneSelector.propTypes = {
  zoneOptions: PropTypes.array,
  zoneValue: PropTypes.object,
  setZoneValue: PropTypes.object,
  zoneLoading: PropTypes.bool.isRequired,
};