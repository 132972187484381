import { Difference, HelpOutline } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import DiffDialogwithTabs from '../../../SummaryComponents/Components/DiffDialogwithTab';
import AffectedDeviceDialog from '../AffectedDeviceDialog';



const ActionRenderer = (props) => {
  const { data } = props;
  const [ differenceDialog, setDifferenceDialog ] = useState(false);
  const [openAffectedDeviceDialog, setAffectedDeviceDialog] = useState(false);

  const handleClickOpenDiffDialog = () => {
    setDifferenceDialog(true);
  };

  const handleClickOpen = () => {
    setAffectedDeviceDialog(true);
  };

  if (props.data === undefined) {
    return '';
  }

  if (data) {
    return (
      <Box sx={{ textAlign: 'center' }}>
         <Tooltip title={'View Diff'}>
          <IconButton onClick={handleClickOpenDiffDialog}>
            <Difference fontSize="inherit" />
          </IconButton>
        </Tooltip>
            {differenceDialog && (
              <DiffDialogwithTabs
                openDialog={differenceDialog}
                goldenConfigPath={data.device_summary_event[0].r_golden_config_path}
                currentConfigPath={data.device_summary_event[0].r_change_backup_path}
                previousConfigPath={data.device_summary_event[0].r_change_previous_path}
                data={data.device_summary_event[0]}
                cmdbGroupSummaryEvent={data}
                handleClose={() => setDifferenceDialog(false)}
              />
            )}
        <Tooltip title="View affected devices">
          <IconButton  onClick={handleClickOpen}>
            <DatasetLinkedIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        {openAffectedDeviceDialog && (
          <AffectedDeviceDialog
            openDialog={openAffectedDeviceDialog}
            handleClose={() => {
              setAffectedDeviceDialog(false)
            }}
            groupSummaryEvent={data}
            groupedApi={props.api}
          />
        )}
      </Box>
    );
  }
};

ActionRenderer.propTypes = {
  data: PropTypes.object,
};

export default ActionRenderer;