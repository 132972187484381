/* eslint-disable*/
import { yupResolver } from '@hookform/resolvers/yup';
import { Close, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  TextField,
  DialogActions,
  Button
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { UserContext } from '../../../../context/UserContext';
import { FormProvider, RHFTextField } from '../../../hook-form';
import { LoadingButton } from '@mui/lab';

//-------------------------------------------------------------------------------
const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  stackContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const typesList = [
  { label: 'SNMP', id: 1 },
  { label: 'SSH', id: 2 },
];

//-------------------------------------------------------------------------------

function EditDialog(props) {
  const classes = useStyles();
  const { openDialog, handleClose } = props;
  const [macDataType, setMacDataType] = useState(null);
  const [slDataType, setSlDataType] = useState(null);


  const handelChangeMacDataType = (e, value) => {
    setMacDataType(value);
  };

  const handelChangeSLDataType = (e, value) => {
    setSlDataType(value);
  };

  const editConsoleSchema = Yup.object().shape({

  });

  const defaultValues = {
    devicetype: '',
    devicetypevendor: '',
    priority: '',
    devicetypecode: '',
    status: '',
    snmpcommand: '',
    rule: '',
    sshcommand: '',
    templatetext: '',
    variable: '',
  };

  const methods = useForm({
    resolver: yupResolver(editConsoleSchema),
    defaultValues,
  });

  const {
    handleSubmit,
  } = methods;

  const onSubmit = async (value) => {
    console.log(value, 'vvvvvvvvvvvvvvv');
  };

  //--------------------------------------------------------------
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      aria-labelledby="responsive-dialog-title"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <DialogTitle>
        <Stack direction="row" className={classes.stackContainer}>
          <Box className={classes.root}>
            Edit console
          </Box>

          <IconButton onClick={handleClose}>
            <Close sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <Divider />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ py: 2 }}>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Basic information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} sx={{ px: 1, py: 1 }}>
                <Grid item sm={4} md={4} lg={4}>
                  <RHFTextField name="devicetype" label="Device type" autoComplete="off" />
                </Grid>
                <Grid item sm={4} md={4} lg={4}>
                  <RHFTextField name="devicetypevendor" label="Device type vendor" autoComplete="off" />
                </Grid>
                <Grid item sm={4} md={4} lg={4}>
                  <RHFTextField name="priority" label="Priority" autoComplete="off" />
                </Grid>
                <Grid item sm={4} md={4} lg={4}>
                  <RHFTextField name="devicetypecode" label="Device type code" autoComplete="off" />
                </Grid>
                <Grid item sm={4} md={4} lg={4}>
                  <RHFTextField name="status" label="Status" autoComplete="off" />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Serial number</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} sx={{ px: 1, py: 2, pt: 4 }}>
                <Grid item sm={4} md={4} lg={4}>
                  <Autocomplete
                    sx={{ width: 300 }}
                    size="small"
                    handleHomeEndKeys
                    options={typesList}
                    autoHighlight
                    value={slDataType}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => <li {...props}>{option.label}</li>}
                    onChange={handelChangeSLDataType}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Device type"
                        placeholder="Choose device type"
                        InputLabelProps={{ shrink: true }}
                        autoComplete="off"
                      />
                    )}
                  />
                </Grid>
                <Grid container item sm={8} md={8} lg={8} spacing={2}>
                  <Grid item sm={12} md={12} lg={12}>
                    <RHFTextField name="snmpcommand" label="SNMP command" autoComplete="off"  />
                  </Grid>
                  <Grid item sm={12} md={12} lg={12}>
                    <RHFTextField name="rule" label="Rule" autoComplete="off" />
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>MAC</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} sx={{ px: 1, py: 2, pt: 4 }}>
                <Grid item sm={4} md={4} lg={4}>
                  <Autocomplete
                    sx={{ width: 300 }}
                    size="small"
                    handleHomeEndKeys
                    options={typesList}
                    autoHighlight
                    value={macDataType}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => <li {...props}>{option.label}</li>}
                    onChange={handelChangeMacDataType}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Device type"
                        placeholder="Choose device type"
                        InputLabelProps={{ shrink: true }}
                        autoComplete="off"
                      />
                    )}
                  />
                </Grid>
                <Grid container item sm={8} md={8} lg={8} spacing={2}>
                  <Grid item sm={12} md={12} lg={12}>
                    <RHFTextField name="sshcommand" label="SSH command" autoComplete="off"  />
                  </Grid>
                  <Grid item sm={12} md={12} lg={12}>
                    <RHFTextField name="templatetext" label="Template text" autoComplete="off"  />
                  </Grid>
                  <Grid item sm={12} md={12} lg={12}>
                    <RHFTextField name="variable" label="Variable conditions" autoComplete="off"  />
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </DialogContent>

        <Divider />
        <DialogActions>
          <Button variant='contained' onClick={handleClose()}>
            Cancel
          </Button>
          <LoadingButton type="submit" variant='outlined'>
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
export default EditDialog;
