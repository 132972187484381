import { CircularProgress ,Checkbox} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useContext, useEffect, useState } from 'react';
import { getLocationsWithoutPagination } from '../../../../API/Locations/showLocations';
import { UserContext } from '../../../../context/UserContext';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function DcLocationSelector(props) {
  const { zoneValue, setLocationValue, locationValue, setDcAgentSubnetOptions, zoneSelectionSource } =
    props;
  const { customerId } = useContext(UserContext);
  const [locationOptions, setLocationOptions] = useState([]);
  const [locationLoading, setLocationLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [page, setPage] = useState(0);
  const filter = createFilterOptions();

  const loadLoactionOptions = async () => {
    setLocationLoading(true);
    try {
      const _locationOption = await getLocationsWithoutPagination(customerId, page);
      setPage(_locationOption.current_page - 1);
      setLocations(_locationOption.data);
      setLocationOptions(_locationOption.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLocationLoading(false);
      setTimeout(() => {
        if (zoneValue) {
          _zoneValueChanged(zoneValue);
        }
      }, 1000);
    }
  };

  useEffect(() => {
    loadLoactionOptions();
    // if (props.locationValue?.data?.uuid) {
    //   setLocationLoading(true);
    //   const _addLocations = getLocationsWithoutPagination(customerId);
    //   setLocationOptions(_addLocations).filter((item) => item.zone._uid === props.locationValue?.data._uid);
    //   setTimeout(() => {
    //     setLocationLoading(false);
    //   }, 1000);
    // }
  }, []);

  const _zoneValueChanged = (zone, locationValue) => {
    const newLocations = zone ? locations.filter((loc) => loc.zone?._uid === zone._uid) : locations;
    setLocationOptions(newLocations);
    if (zoneSelectionSource !== 'location') {
      setDcAgentSubnetOptions(newLocations.reduce((current, loc) => [...current, ...loc.subnets], []));
      setLocationValue(newLocations);
    }
  };

  useEffect(() => {
    if (zoneValue) {
      _zoneValueChanged(zoneValue, locationValue);
    } else {
      setLocationOptions(locations);
      setLocationValue([]);
      setDcAgentSubnetOptions([]);
    }

    // const _addLocations = getLocationsWithoutPagination(customerId);
    // setLocationOptions(
    //   _addLocations?.data?.map((item) => {
    //     return {
    //       uuid: item.uuid,
    //       r_location_name: item.r_location_name,
    //     };
    //   })
    // );
  }, [zoneValue]);

  const _selectLocations = (_locations) => {
    if (_locations?.length === 1 && zoneValue === null) {
      //   setZoneValue(_locations[0].zone);
      // setTimeout(() => {
      //   setLocationValue(_locations);
      // }, 1000);
    }
    setLocationValue(_locations);
    setDcAgentSubnetOptions(_locations.reduce((current, loc) => [...current, ...loc.subnets], []));
  };

  return (
    <Autocomplete
      size="small"
      value={locationValue || ''}
      fullWidth
      limitTags={4}
      multiple
      onChange={(event, newValue) => {
        // if (typeof newValue === 'string') {
        //   setLocationValue({
        //     r_location_name: newValue,
        //   });
        // } else if (newValue && newValue.inputValue) {
        //   // Create a new value from the user input
        //   _addLocation(newValue.inputValue).then((res) => {
        //     setLocationValue({
        //       _uid: res._uid,
        //       r_location_name: res.r_location_name,
        //     });
        //   });
        // } else {
        _selectLocations(newValue);
        // }
      }}
      disableCloseOnSelect
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // const { inputValue } = params;
        // // Suggest the creation of a new value
        // const isExisting = locationOptions?.some(
        //   (option) => inputValue.toLocaleLowerCase() === option.r_location_name.toLocaleLowerCase()
        // );
        // if (inputValue !== '' && !isExisting) {
        //   filtered.push({
        //     inputValue,
        //     // r_location_name: `Create "${inputValue}"`,
        //   });
        // }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={locationOptions}
      loading={locationLoading}
      getOptionLabel={(option) => option.r_location_name}
      // renderOption={(props , option)=>{<li {...props}>{option.r_location_name}</li>}}
      renderOption={(props, option ,{selected}) => {
        if (option?.zone?.r_zone_name) {
          return <li {...props}><Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />{option.r_location_name}</li>;
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Location"
          label="Location"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {locationLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
