import React, { createContext } from 'react';
import PropTypes from 'prop-types';


export const UserContext = createContext({});

export const Provider = (props) => {
  const value = {
    customerId: localStorage.getItem('customerId'),
  };
  return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>;
};

export const { Consumer } = UserContext;

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

Provider.defaultProps = {};
