/* eslint-disable */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, CircularProgress } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useContext, useEffect, useState } from 'react';
import { getLocationsWithoutPagination } from '../../../../API/Locations/showLocations';
import { UserContext } from '../../../../context/UserContext';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function DcLocationSelector(props) {
  const { zoneValue, setLocationValue, locationValue, setDcAgentSubnetOptions, onChange, selectAllLabel } = props;
  const { customerId } = useContext(UserContext);
  const [locationOptions, setLocationOptions] = useState([]);
  const [locationLoading, setLocationLoading] = useState(false);

  const filter = createFilterOptions();

  const loadLoactionOptions = async () => {
    setLocationLoading(true);
    try {
      getLocationsWithoutPagination(customerId, {}).then((_locationOption) => {
        setLocationOptions(_locationOption.data.filter((loc) => loc.zone._uid === zoneValue._uid));
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLocationLoading(false);
    }
  };

  useEffect(() => {
    loadLoactionOptions();
  }, [zoneValue]);

  const _selectLocations = (_locations) => {
    const _locationsFiltered = locationOptions.filter((i) => _locations.findIndex((_l) => _l._uid === i._uid) !== -1);
    setLocationValue(_locationsFiltered);
    setDcAgentSubnetOptions(_locationsFiltered.reduce((current, loc) => [...current, ...loc.subnets], []));
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleToggleOption = (selectedOps) => setSelectedOptions(selectedOps);
  const handleClearOptions = () => setSelectedOptions([]);
  const [allSelected, setAllSelected] = useState(locationValue.length === selectedOptions.length);

  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setAllSelected(true);
      // setSelectedOptions(locationValue);
    } else {
      // handleClearOptions();
      setAllSelected(false);
      // setSelectedOptions([]);
    }
  };

  const [value, setValue] = useState([]);

  const handleToggleSelectAll = (event) => {
    handleSelectAll && handleSelectAll(event.target.checked);
  };

  return (
    <Autocomplete
      size="small"
      value={locationValue || ''}
      fullWidth
      limitTags={4}
      multiple
      onChange={(event, newValue, reason) => {
        if (reason === 'selectOption') {
          if (newValue.find((option) => option.r_location_name === selectAllLabel)) {
            handleToggleSelectAll(event);
            const result = locationOptions.filter((el) => el.r_location_name !== selectAllLabel);
            _selectLocations(result);
            handleToggleOption && handleToggleOption(result);
          } else {
            handleToggleOption && handleToggleOption(newValue);
            _selectLocations(newValue);
          }
        } else if (reason === 'clear') {
          handleToggleSelectAll(event);
          handleClearOptions && handleClearOptions();
          handleToggleOption && handleToggleOption([]);
          _selectLocations(newValue);
        } else if (reason === 'removeOption') {
          if (newValue.find((option) => option.r_location_name === selectAllLabel)) {
            handleToggleSelectAll(event);
            const result = locationOptions.filter((el) => el.r_location_name !== selectAllLabel);
            _selectLocations([]);
            handleToggleOption && handleToggleOption([]);
          } else {
            handleToggleOption && handleToggleOption(newValue);
            _selectLocations(newValue);
          }
        }
      }}
      isOptionEqualToValue={(option, value) => option._uid === value._uid}
      disableCloseOnSelect
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // const { inputValue } = params;
        // // Suggest the creation of a new value
        // const isExisting = locationOptions?.some(
        //   (option) => inputValue.toLocaleLowerCase() === option.r_location_name.toLocaleLowerCase()
        // );
        // if (inputValue !== '' && !isExisting) {
        //   filtered.push({
        //     inputValue,
        //     // r_location_name: `Create "${inputValue}"`,
        //   });
        // }
        return [{ r_location_name: selectAllLabel }, ...filtered];
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={locationOptions}
      loading={locationLoading}
      getOptionLabel={(option) => (option ? option.r_location_name : '')}
      // getOptionLabel={(option) =>  option.r_location_name}
      // renderOption={(props , option)=>{<li {...props}>{option.r_location_name}</li>}}
      renderOption={(props, option, { selected }) => {
        const selectAllProps = option.r_location_name === selectAllLabel ? { checked: allSelected } : {};
        return (
          <li {...props}>
            {' '}
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              {...selectAllProps}
            />
            {option.r_location_name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Location"
          label="Location"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {locationLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
