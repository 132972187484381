import modem from '../modem';

export const getGenerals = async (id, key) => {
  const newPromise = modem.get(`${id}/global-config/${key}`).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const setGenerals = async (id, data) => {
  const newPromise = modem.put(`${id}/global-config`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};
