import modem from '../modem';

export const getScheduler = async (id, data) => {
  const newPromise = modem.get(`${id}/schedules`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const updateScheduler = async (id, schedulerId, data) => {
  const newPromise = modem.post(`${id}/schedules/${schedulerId}`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const getSchedulerEmail = async (id, data) => {
  const newPromise = modem
    .get(`${id}/email-configurations`, data)
    .then((response) => {
      return response.data.data;
    });
  return newPromise;
}

export const addSchedulerEmail = async (customerId, data) => {
  const newPromise = modem
    .post(`${customerId}/email-configurations`, data)
    .then((response) => {
      return response.data.data;
    })
  return newPromise
};

export const deleteEmail = async (customerId, data) => {
  const newPromise = modem
    .delete(`${customerId}/email-configurations/${data}`, data)
    .then((response) => {
      return response.data.data;
    })
  return newPromise
};


// export const sendTestEmail = async (customerId) => {
//   const newPromise = modem
//     .delete(`${customerId}/email-test/${data}`, data)
//     .then((response) => {
//       return response.data.data;
//     })
//   return newPromise
// };

