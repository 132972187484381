import { startOfMonth, startOfQuarter, startOfWeek, startOfYear, subDays, subHours, subMinutes, subMonths, subQuarters, subWeeks, subYears } from 'date-fns';

export const timeRangeOptions = [
    {
        id: '5m',
        label: "Last 5 minutes",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subMinutes(end, 5).getTime(),
                end: end.getTime(),
                step: 1
            }

        }
    },
    {
        id: '15m',
        label: "Last 15 minutes",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subMinutes(end, 15).getTime(),
                end: end.getTime(),
                step: 3,
            }
        }
    },
    {
        id: '30m',
        label: "Last 30 minutes",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subMinutes(end, 30).getTime(),
                end: end.getTime(),
                step: 7,
            }
        }
    },
    {
        id: '1hr',
        label: "Last 1 hour",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subHours(end, 1).getTime(),
                end: end.getTime(),
                step: 14,
            }
        }
    },
    {
        id: '3hr',
        label: "Last 3 hours",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subHours(end, 3).getTime(),
                end: end.getTime(),
                step: 43,
            }
        }
    },
    {
        id: '6hr',
        label: "Last 6 hours",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subHours(end, 6).getTime(),
                end: end.getTime(),
                step: 86,
            }
        }
    },
    {
        id: '12hr',
        label: "Last 12 hours",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subHours(end, 12).getTime(),
                end: end.getTime(),
                step: 172,
            }
        }
    },
    {
        id: 'last 24 hours',
        label: "Last 24 hours",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subHours(end, 24).getTime(),
                end: end.getTime(),
                step: 345,
            }
        }
    },
    {
        id: 'last 2 days',
        label: "Last 2 days",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subDays(end, 2).getTime(),
                end: end.getTime(),
                step: 691,
            }
        }
    },
    {
        id: 'last 7 days',
        label: "Last 7 days",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subDays(end, 7).getTime(),
                end: end.getTime(),
                step: 2419,
            }
        }
    },
    {
        id: 'Last 30 days',
        label: "Last 30 days",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subDays(end, 30).getTime(),
                end: end.getTime(),
                step: 10368,
            }
        }
    },
    {
        id: 'last 90 days',
        label: "Last 90 days",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subDays(end, 90).getTime(),
                end: end.getTime(),
                step: 31104,
            }
        }
    },
    {
        id: 'last 6 months',
        label: "Last 6 months",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subMonths(end, 6).getTime(),
                end: end.getTime(),
                step: 62208,
            }
        }
    },
    {
        id: 'last 1 year',
        label: "Last 1 year",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subYears(end, 1).getTime(),
                end: end.getTime(),
                step: 126144,
            }
        }
    },
    {
        id: 'last 2 year',
        label: "Last 2 year",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subYears(end, 2).getTime(),
                end: end.getTime(),
                step: 252288,
            }
        }
    },
    {
        id: 'Last 5 year',
        label: "Last 5 year",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subYears(end, 5).getTime(),
                end: end.getTime(),
                step: 630720,
            }
        }
    },
    {
        id: 'yesterday',
        label: "Yesterday",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subDays(end, 1).getTime(),
                end: end.getTime(),
                step: 1
            }
        }
    },
    {
        id: 'day before yesterday',
        label: "Day before yesterday",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subDays(end, 2).getTime(),
                end: subDays(end, 1).getTime(),
                step: 1
            }
        }
    },
    {
        id: 'this day last week',
        label: "This day last week",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subWeeks(end, 1).getTime(),
                end: subWeeks(end, 1).getTime(),
                step: 1
            }
        }
    },
    {
        id: 'previous week',
        label: "Previous week",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subWeeks(end, 1).getTime(),
                end: end.getTime(),
                step: 1
            }
        }
    },
    {
        id: 'previous month',
        label: "Previous month",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subMonths(end, 1).getTime(),
                end: end.getTime(),
                step: 1
            }
        }
    },
    {
        id: 'previous fiscal quarter',
        label: "Previous fiscal quarter",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subQuarters(end, 1).getTime(),
                end: end.getTime(),
                step: 1
            }
        }
    },
    {
        id: 'previous year',
        label: "Previous year",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subYears(end, 1).getTime(),
                end: end.getTime(),
                step: 1
            }
        }
    },
    {
        id: 'previous fiscal year',
        label: "Previous fiscal year",
        calculatevalue: () => {
            const end = new Date();
            return {
                start: subYears(end, 1).getTime(),
                end: end.getTime(),
                step: 1
            }
        }
    },
    {
        id: 'today',
        label: "Today",
        calculatevalue: () => {
            const end = new Date();
            const start = new Date();
            return {
                start: start.getTime(),
                end: end.getTime(),
                step: 1
            }
        }
    },
    {
        id: 'today so far',
        label: "Today so far",
        calculatevalue: () => {
            const start = new Date();
            return {
                start: start.getTime(),
                end: new Date().getTime(),
                step: 1
            }
        }
    },
    {
        id: 'this week',
        label: "This week",
        calculatevalue: () => {
            const end = new Date();
            const start = new Date();
            return {
                start: startOfWeek(start).getTime(),
                end: end.getTime(),
                step: 1
            }
        }
    },
    {
        id: 'this week so far',
        label: "This week so far",
        calculatevalue: () => {
            const start = new Date();
            return {
                start: startOfWeek(start).getTime(),
                end: new Date().getTime(),
                step: 1
            }
        }
    },
    {
        id: 'this month',
        label: "This month",
        calculatevalue: () => {
            const end = new Date();
            const start = new Date();
            return {
                start: startOfMonth(start).getTime(),
                end: end.getTime(),
                step: 1
            }
        }
    },
    {
        id: 'this month so far',
        label: "This month so far",
        calculatevalue: () => {
            const start = new Date();
            return {
                start: startOfMonth(start).getTime(),
                end: new Date().getTime(),
                step: 1
            }
        }
    },
    {
        id: 'this year',
        label: "This year",
        calculatevalue: () => {
            const end = new Date();
            const start = new Date();
            return {
                start: startOfYear(start).getTime(),
                end: end.getTime(),
                step: 1
            }
        }
    },
    {
        id: 'this year so far',
        label: "This year so far",
        calculatevalue: () => {
            const start = new Date();
            return {
                start: startOfYear(start).getTime(),
                end: new Date().getTime(),
                step: 1
            }
        }
    },
    {
        id: 'this fiscal quarter so far',
        label: "This fiscal quarter so far",
        calculatevalue: () => {
            const start = new Date();
            return {
                start: startOfQuarter(start).getTime(),
                end: new Date().getTime(),
                step: 1
            }
        }
    },
    {
        id: 'this fiscal quarter',
        label: "This fiscal quarter",
        calculatevalue: () => {
            const end = new Date();
            const start = new Date();
            return {
                start: startOfQuarter(start).getTime(),
                end: end.getTime(),
                step: 1
            }
        }
    },
    {
        id: 'this fiscal year so far',
        label: "This fiscal year so far",
        calculatevalue: () => {
            const start = new Date();
            return {
                start: startOfYear(start).getTime(),
                end: new Date().getTime(),
                step: 1
            }
        }
    },
    {
        id: 'this fiscal year',
        label: "This fiscal year",
        calculatevalue: () => {
            const end = new Date();
            const start = new Date();
            return {
                start: startOfYear(start).getTime(),
                end: end.getTime(),
                step: 1
            }
        }
    },
]