
// @mui
import { styled } from '@mui/material/styles';
import { Button, Container, Typography } from '@mui/material';
// hooks
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  padding:'10px 10px 10px 10px',
  flexDirection:'column',
  marginTop:'10%', 
}));


// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {

  return (
    <Page title="Logout">
      <RootStyle>
        <Container sx={{textAlign:'center'}}>
            <Typography variant="h3" gutterBottom>
              You are logged out!
            </Typography>
        </Container>

        <Container>
            <Logo sx={{width:'200px',height:'70px',marginLeft:'480px'}}/>
        </Container>
        
        <Container sx={{textAlign:'center'}}>
          <Button variant="contained" sx={{width:'200px'}} href="/">Login</Button>
        </Container>
      </RootStyle>
    </Page>
  );
}
