/* eslint-disable */
import { Box, LinearProgress, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SdwanStateChange } from '../../../../../../API/NetworkDevices/health';
import { UserContext } from '../../../../../../context/UserContext';
import { fToNow } from '../../../../../../utils/formatTime';
import { useHealthContext } from '../../../userContextHealth';


// -------------------------------------
const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    height: 660,
}));

// ---------------------------------------


const SdwanStatusTable = ({ expandedState }) => {
    const { healthDashState, getStartEnd } = useHealthContext()
    const { customerId } = useContext(UserContext);
    const [statusData, setStatusData] = useState([]);
    const [loading, setLoading] = useState(true);

    // ------------------------------------------------------------------------------------------------
    const columnHeaders = [
        {
            headerName: 'Interface',
            field: 'interface',
            width: 200,
            sortable: false,
            filter: false,
            cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 },
        },
        {
            headerName: 'sla',
            field: 'sla',
            width: 200,
            sortable: true,
            filter: false,
            cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 },
        },
        {
            headerName: 'value',
            field: 'value',
            width: 300,
            sortable: false,
            filter: false,
            cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400', letterSpacing: 1 },
            valueFormatter: (params) => {
                const formattedDate = fToNow(params.value);
                return formattedDate;
            },
        },
    ];

    const fetchData = useCallback(() => {
        if (!healthDashState.selectedDevice || expandedState === false) {
            return '';
        }
        const data = getStartEnd();
        const payload = {
            start: data.start / 1000,
            end: data.end / 1000,
            step: data.step,
            // device_type: healthDashState.selectedDevice.l_dvc_typ_name,
            device_os: healthDashState.selectedDevice.r_dvc_os,
        };
        setLoading(true);
        SdwanStateChange(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
            .then((res) => {
                setLoading(false);
                setStatusData(res?.data);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [healthDashState.selectedDevice, expandedState, customerId]);

    useEffect(() => {
        fetchData();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, expandedState]);

    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            filter: true,
            sortable: true,
            suppressMenu: true,
            floatingFilter: false,
        };
    }, []);

    // -----------------------------------------------------------------------------------------------


    return (
        <>
            {loading && <LinearProgress sx={{ height: '2px' }} />}
            <Paper variant="outlined">
                <ChartWrapperStyle dir="ltr">
                    <Box style={{ height: '660px', width: '100%' }} className="ag-theme-material">
                        <AgGridReact
                            rowData={statusData}
                            columnDefs={columnHeaders}
                            onGridReady={fetchData}
                            defaultColDef={defaultColDef}
                        />
                    </Box>
                </ChartWrapperStyle>
            </Paper>
        </>
    );
};
export default SdwanStatusTable;
