/* eslint-disable */
import { RestartAlt, Settings, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isArray, isNull } from 'lodash';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactFlow, {
  ControlButton,
  Controls,
  Panel,
  ReactFlowProvider,
  addEdge,
  useEdgesState,
  useNodesState
} from 'reactflow';
import 'reactflow/dist/style.css';
import { getGlobalTopology } from '../../../../API/NetworkDevices/topology';
import EnterFullscreen from '../../../../Assets/enterFullscreen.svg';
import ExitFullscreen from '../../../../Assets/exitFullscreen.svg';
import globalIcon from '../../../../Assets/globalMapIcon.png';
import { updateLocation } from '../../../../actions/updateLocation';
import { UserContext } from '../../../../context/UserContext';
import { useFullscreen } from "../../../../hooks/useFullscreen";
import LocationSelectGraph from '../../LocationSelectGraph';
import PanControl from '../../PanControl';
import { useMapSettingContext } from '../../components/RelatedToGraph/mapSettingProvider';
import { CustomLocationNode } from './CustomeNodes/CustomLocationNode';
import FloatingConnectionLine from './FloatingConnectionLine';
import { GlobalFloatingEdge } from './GlobalFloatingEdge';
import LegendControl from './LegendControl';

// ------------------------------------------------------------------------------------------

const nodeTypes = {
  A1_Node: CustomLocationNode,
};

const edgeTypes = {
  floating: GlobalFloatingEdge,
};

const useStyles = makeStyles(() => ({
  overlayCard: {
    width: '300px',
    height: 'auto',
    fontSize: '1.6rem',
    opacity: 1,
  },
  CardItems: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardText: {
    width: '85%',
    overflow: 'hidden',
  },
  bannerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #eeeeee'
  },
  graphContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: '#f2f2f226',
    overflow: 'hidden',
  },
  TypoGridBox1: {
    fontSize: '1.25rem',
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    padding: '5px'
  },
  panelGridBox: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'right',
    alignItems: 'center',
    padding: '10px',
  },
  panelGridBoxItem: {
    backgroundColor: 'white',
  },
  controls: {
    position: 'relative',
    display: 'flex',
    margin: '0',
    width:160,
    marginLeft:"auto"
  },
  fullScreenCss: {
    height: "100vh",
    background: 'white'
  },
  notfullScreenCss: {
    height: "-webkit-calc(100vh - 200px)",
    height: "-moz-calc(100vh - 200px)",
    height: "calc(100vh - 200px)",
    background: 'white'
  },
}));


// -------------------------------------------------------------------------------------------
export default function OverView() {
  const classes = useStyles();
  const { customerId } = useContext(UserContext);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [resetLayout, setResetLayout] = useState(false);
  const [hoveredEdge, setHoveredEdge] = useState([]);
  const [edgeLabelOnHover, setEdgeLabelOnHover] = useState([]);
  const [loading, setLoading] = useState(false);
  const controller = new AbortController();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const { mapSettingState, mapSettingDispatch } = useMapSettingContext()
  const locationData = useSelector((state) => state.locationSelectReducer.globalmap.locationData);
  const dispatch = useDispatch();
  const fullScreenRef = useRef(null);
  const { isFullscreen, toggleFullscreen } = useFullscreen(fullScreenRef);
  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);
  const [clickedNode, setClickedNode] = useState(null);
  const [clickedNodeEdges, setClickedNodeEdges] = useState([]);
  // ----------------------------------------

  const onToggleFullscreen = useCallback(() => {
    toggleFullscreen()
    // onFullscreenChange()
  })

  const onFullscreenChange = useCallback(() => {
    if (reactFlowInstance) {
      setTimeout(() => {
        reactFlowInstance.fitView({ duration: 1000 })
      }, 1000);
    }
  }, [reactFlowInstance])

  useEffect(() => {
    if (reactFlowInstance && !clickedNode) {
      setTimeout(() => {
        reactFlowInstance.fitView({ duration: 1000 })
      }, 1000);
    }
  }, [isFullscreen])

  // useEffect(() => {
  //   document.addEventListener('fullscreenchange', onFullscreenChange);
  //   return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  // }, []);

  const selectLocation = useCallback((_locations) => {
    dispatch(updateLocation({ page: 'globalmap', locationUuid: _locations }));
    if (_locations.length != 0) {
      // zoomToNode(_locations[0])
      handleLocationSelection(null, nodes.find(i => i.id == _locations[0]))
    } else {
      reactFlowInstance.fitView({ duration: 1000 })
      handleLocationSelection(null, null)
    }
  });

  const zoomToNode = (nodeId) => {
    let fNodes = [{ id: nodeId }]
    if (isArray(nodeId)) {
      fNodes = nodeId.map(item => {
        return { id: item }
      })
    }
    reactFlowInstance.fitView({ nodes: fNodes, duration: 1000 })
  };

  const handleButtonClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const fetchData = async () => {
    setLoading(true);
    const payload = {
      endRow: 200,
      startRow: 0,
    };
    try {
      const res = await getGlobalTopology(customerId, payload, controller);
      setNodes(res.nodes.map((nd) => {
        return {
          ...nd,
          data: {
            ...nd.data,
            data: nd.nodes,
            handlers: {
              source: [
                ...res.edges.filter((nb) => {
                  return nb.source === nd.id
                }).map((nb) => nb.data.r_link_local_interface).filter((item, i, ar) => ar.indexOf(item) === i)
              ],
              target: [
                ...res.edges.filter((nb) => {
                  return nb.target === nd.id;
                }).map((nb) => nb.data.r_link_remote_interface).filter((item, i, ar) => ar.indexOf(item) === i)]
            }
          }
        }
      }));
      setEdges(res.edges.map((ed) => {
        return {
          ...ed,
          sourceHandler: ed.data.r_link_local_interface,
          targetHandler: ed.data.r_link_remote_interface,
        }
      }));
      if (reactFlowInstance) {
        setTimeout(() => {
          reactFlowInstance.fitView({ duration: 1000 })
        }, 500);
      }
      setLoading(false);
      setResetLayout(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setResetLayout(false);
    }
  };

  const handleResetLayout = () => {
    setResetLayout(true);
    fetchData();
  };

  useEffect(() => {
    return () => {
      controller.abort();
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [customerId]);

  useEffect(() => {
    setEdges((_edges) => {
      return _edges.map((_edge) => {
        if (hoveredEdge.findIndex(i => _edge.id === i.id) !== -1) {
          return {
            ..._edge,
            animated: true,
            data: {
              ..._edge.data,
              hover: true,
            },
            style: {
              strokeWidth: 2,
              stroke: "url(#gradient)"
            },
          }
        }
        return {
          ..._edge,
          animated: false,
          style: {},
          data: {
            ..._edge.data,
            hover: false,
          },
        }
      })
    }
    )
  }, [hoveredEdge]);

  const handleNodeHover = (event, node) => {
    const connectedEdges = edges.filter(
      (edge) =>
        edge.type === 'floating' &&
        (edge.source === node.id || edge.target === node.id || edge.source === '0')
    );
    setHoveredEdge(connectedEdges);
  };

  // const handleNodeHoverStop = () => {
  //   setHoveredEdge([]);
  // };

  const handleEdgeMouseEnter = useCallback((event, edge) => {
    setEdgeLabelOnHover([edge]);
  });

  const handleEdgeMouseLeave = useCallback(() => {
    setEdgeLabelOnHover([]);
  });

  useEffect(() => {
    setEdges((_edges) => {
      return _edges.map((_edge) => {
        if (edgeLabelOnHover.findIndex(i => _edge.id === i.id) !== -1) {
          return {
            ..._edge,
            animated: true,
            data: {
              ..._edge.data,
              hoverEdgeLabel: true,
            },
          }
        }
        return {
          ..._edge,
          animated: false,
          data: {
            ..._edge.data,
            hoverEdgeLabel: false,
          },
        }
      })
    })
  }, [edgeLabelOnHover]);

  const handleGlobalShowHideLabel = () => {
    mapSettingDispatch({ type: 'TOGGLE_GLOBAL_SHOW_LOCATION_NAME' })
  };

  const handleGlobalShowLegend = () => {
    mapSettingDispatch({ type: 'TOGGLE_GLOBAL_SHOW_LEGEND' })
  };

  const handleGlobalShowCounter = () => {
    mapSettingDispatch({ type: 'TOGGLE_GLOBAL_COUNTER' })
  };


  const handleLocationSelection = useCallback((_, node) => {
    let _relatedNodesId = [];
    let _connectedEdgesId = [];
    let _connectedEdges = [];
    if (clickedNode === node?.id) {
      setClickedNode(null);
      setClickedNodeEdges([]);

    } else {
      _connectedEdges = edges.filter(
        (edge) =>
          edge.source === node?.id || edge.target === node?.id
      );
      setClickedNodeEdges(_connectedEdges);
      setClickedNode(node?.id);
      _connectedEdgesId = Array.from(new Set([..._connectedEdges.map(i => i.id)]))
      _relatedNodesId = Array.from(new Set([..._connectedEdges.map(i => i.source), ..._connectedEdges.map(i => i.target)]))
      zoomToNode(_relatedNodesId)
    }

    setNodes((_nodes) => {
      return _nodes.map((_nd) => {
        // const isSelectedNode = _nd.id === clickedNode;
        // const isRelatedNode = clickedNodeEdges.some((edge) =>
        //   edge.source === _nd.id || edge.target === _nd.id
        // );
        // const opacity = clickedNode === null ? 1 : isSelectedNode || isRelatedNode ? 1 : 0.2;
        return {
          ..._nd,
          style: {
            ..._nd.style,
            opacity: _relatedNodesId.indexOf(_nd.id) === -1 && !isNull(node) ? 0.1 : 0.99,
            // pointerEvents: _relatedNodesId.indexOf(_nd.id) === -1 && !isNull(node) ? 'none' : 'auto',
          },
        };
      });
    });
    let offset = -(_connectedEdgesId.length / 2) * 0.99;
    setEdges((_edges) => {
      return _edges.map((_edge) => {
        const isSelectedEdge = _connectedEdgesId.indexOf(_edge.id) !== -1;
        const opacity = isSelectedEdge ? 1 : 0;
        const expand = isSelectedEdge ? true : false;
        if (isSelectedEdge) {
          if (offset == 0) {
            offset += 1
          }
          offset += 1
        }
        return {
          ..._edge,

          data: {
            ..._edge.data,
            expandEdges: isSelectedEdge && !isNull(node) ? offset : false,
          },
          style: {
            ..._edge.style,
            strokeOpacity: isNull(node) ? 1 : opacity,
            // pointerEvents: isNull(node) ? 'none' : 'auto',
          },
        };
      });
    });
  }, [clickedNode, clickedNodeEdges, edges, setNodes, setEdges]);

  const handleNodeClick = useCallback((event, node) => {
    selectLocation([node.id])
  }, [selectLocation]);


  // ----------------------------------------------------------------------------------------------
  return (
    <div id="react-flow-global-map" ref={fullScreenRef}>
      <Box className={isFullscreen ? classes.fullScreenCss : classes.notfullScreenCss} >
        <div className={classes.graphContainer}>
          {loading && <LinearProgress />}
          <ReactFlowProvider>
            <ReactFlow
              fitView
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onConnect={onConnect}
              onEdgesChange={onEdgesChange}
              onNodesChange={onNodesChange}
              onInit={(instance) => setReactFlowInstance(instance)}
              connectionLineComponent={FloatingConnectionLine}
              onEdgeMouseEnter={handleEdgeMouseEnter}
              onEdgeMouseLeave={handleEdgeMouseLeave}
              // onNodeMouseEnter={handleNodeHover}
              // onNodeMouseLeave={handleNodeHoverStop}
              onNodeClick={handleNodeClick}
            >
              <Panel position="top-left" style={{ backgroundColor: 'white', padding: '10px', marginTop: '0px', marginLeft: '0px' }}>
                <Typography gutterBottom className={classes.TypoGridBox1}>
                  <img width="30px" height="30px" src={globalIcon} alt="globalIcon" />
                  Global map
                </Typography>
              </Panel>

              <Panel position="top-right" style={{ padding: '10px', marginTop: '0px', marginRight: '0px' }}>
                <Grid container spacing={0} className={classes.panelGridBox}>
                  <Grid item xs="auto" className={classes.panelGridBoxItem}>
                    <LocationSelectGraph selectLocation={selectLocation} locationData={locationData} />
                  </Grid>
                  <Grid item xs="auto" className={classes.panelGridBoxItem}>

                    <Tooltip title="Reset layout">
                      <IconButton onClick={handleResetLayout}>
                        {resetLayout ? (
                          <CircularProgress size={25} />
                        ) : (
                          <RestartAlt sx={{ fontSize: '2.1rem' }} />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs="auto" className={classes.panelGridBoxItem}>
                    <Tooltip title="Settings">
                      <IconButton ref={anchorRef} onClick={handleButtonClick}>
                        <Settings sx={{ fontSize: '2rem' }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={0} className={classes.panelGridBox}>
                  <Grid item xs="auto" className={classes.panelGridBoxItem}>
                    <Controls
                      className={classes.controls}
                      showInteractive position='top-right' >
                      {isFullscreen ? (
                        <ControlButton onClick={onToggleFullscreen} title="Exit Fullscreen">
                          <img src={ExitFullscreen} width={15} height={15} alt="Exit Fullscreen" />
                        </ControlButton>
                      ) : (
                        <ControlButton onClick={onToggleFullscreen} title="Enter Fullscren">
                          <img src={EnterFullscreen} width={15} height={15} alt="Enter Fullscreen" />
                        </ControlButton>
                      )}
                    </Controls>
                    <PanControl />

                    {mapSettingState.showGlobalLegend && <LegendControl />}
                  </Grid>
                </Grid>

              </Panel>

              <svg>
                <defs>
                  <linearGradient id="all-up" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="rgb(0, 128, 0) " />
                    <stop offset="100%" stopColor="rgb(0, 128, 0) " />
                  </linearGradient>
                </defs>
              </svg>
              <svg>
                <defs>
                  <linearGradient id="all-down" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="rgb(255, 0, 0) " />
                    <stop offset="100%" stopColor="rgb(255, 0, 0) " />
                  </linearGradient>
                </defs>
              </svg>

              <svg>
                <defs>
                  <linearGradient id="few-down" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="rgb(255, 191, 0) " />
                    <stop offset="100%" stopColor="rgb(255, 191, 0) " />
                  </linearGradient>
                </defs>
              </svg>
            </ReactFlow>
          </ReactFlowProvider>
        </div>
      </Box>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement='bottom-end'
        disablePortal={true}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper className={classes.overlayCard} variant="outlined">
            <List>
              <ListItem>
                <ListItemText primary="Show Location name" />
                <ListItemIcon
                  sx={{ justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                  onClick={handleGlobalShowHideLabel}
                >
                  {!mapSettingState.showGlobalLocationName ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                </ListItemIcon>
              </ListItem>

              <ListItem>
                <ListItemText primary="Show Legend" />
                <ListItemIcon
                  onClick={handleGlobalShowLegend}
                  sx={{ justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                >
                  {!mapSettingState.showGlobalLegend ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                </ListItemIcon>
              </ListItem>

              {/* <ListItem>
                <ListItemText primary="Sticky counter" />
                <ListItemIcon
                  onClick={handleGlobalShowCounter}
                  sx={{ justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                >
                  {!mapSettingState.showGlobalCounter ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                </ListItemIcon>
              </ListItem> */}
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}