import { Box, LinearProgress, Skeleton } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { fetchCredentials } from '../../../API/Credentials/listCredentials';
import { UserContext } from '../../../context/UserContext';
import { defaultAgGridProps } from '../../../utils/agGridProps';
import { WithConfirm } from '../../WithConfirm';
import ActionRenderer from './Renderer/ActionRenderer';

const SNMPTable = (props) => {
  const [query, setQuery] = useState('');
  const { customerId } = useContext(UserContext);
  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [credentialsLoading, setCredentialsLoading] = useState(false);

  const columnHeaders = [
    {
      headerName: 'Version',
      field: 'r_credential_snmp_version',
      width: 130,
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return <Skeleton variant="rectangular" height={30} style={{ margin: "7px 0" }} />
        }
        return params.value.replace('SNMP', '');
      },
      // valueGetter: (params) => {
      //   if (params.data === undefined) {
      //     return '';
      //   }
      //   return params.value.replace('SNMP', '');
      // },
    },
    {
      headerName: 'Community',
      field: 'community',
      width: 400,
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        if (params?.data?.r_credential_snmp_version === 'SNMPV1') {
          return params.data?.e_credential_snmp_string;
        }
        if (params?.data?.r_credential_snmp_version === 'SNMPV2') {
          return params.data?.e_credential_snmp_community;
        }
      },
    },
    {
      headerName: 'User (v3)',
      field: 'r_credential_user_snmpv3',
      width: 200,
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Security level',
      field: '',
      width: 200,
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Auth type',
      field: '',
      width: 200,
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
    },
    // {
    //   headerName: 'Auth password',
    //   field: '',
    //   width: 200,
    //   cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
    // },
    {
      headerName: 'Privacy type',
      field: '',
      width: 200,
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
    },
    // {
    //   headerName: 'Privacy password',
    //   field: '',
    //   width: 200,
    //   cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
    // },
    {
      headerName: 'Action',
      field: 'action',
      width: 150,
      // pinned: 'right',
      cellStyle: { fontSize: '18px', fontFamily: 'Arial', fontWeight: '400' },
      cellRenderer: 'actionRenderer',
    },
  ];

  useEffect(() => {
    setColumnDefs(columnHeaders);
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      suppressMenu: true,
      minWidth: 150,
      filter: false,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    return params.api.setDatasource({
      rowCount: null,
      context: {
        global_keywords: query,
      },
      getRows: async (_params) => {
        setCredentialsLoading(true);
        params.api.hideOverlay();
        const payload = {
          ..._params,
          global_keywords: _params.context.global_keywords,
          filterModel: {
            ..._params.filterModel,
            r_credential_type: {
              filterType: 'text',
              type: 'CONTAINS',
              filter: 'SNMP',
            },
          },
        };
        await fetchCredentials(customerId, payload).then((response) => {
          let lastRow = -1;
          if (response.total <= _params.endRow) {
            lastRow = response.total;
          }
          if (response.total === 0) {
            params.api.showNoRowsOverlay();
          }
          _params.successCallback(response.data, lastRow);
          setCredentialsLoading(false);
        });
      },
    });
  }, []);

  const components = useMemo(() => ({
    actionRenderer: ActionRenderer,
  }), []);

  return (
    <div>
      {credentialsLoading && <LinearProgress />}
      <Box style={{ height: '600px', width: '100%' }} className="ag-theme-material">
        <AgGridReact
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          {...defaultAgGridProps}
          components={components}
          context={{
            global_keyword: props.query,
          }}
        />
      </Box>
    </div>
  );
};

export default WithConfirm(SNMPTable);
