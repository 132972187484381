
import { Breadcrumbs, Container, Typography, Divider } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Link } from 'react-router-dom';
import { DCAgentComponents } from '../components/DCAgentComponents';
import Page from '../components/Page';


// ----------------------------------------------------------------------

export default function Dcagent() {
  return (
    <Page title="DC Agent">
      <Container maxWidth={false}>
        <div role="presentation" style={{ cursor: 'pointer' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
              <HomeOutlinedIcon />
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/dc-agent">
              Onboarding
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/dc-agent">
              DC Agents
            </Typography>
          </Breadcrumbs>
          <Divider sx={{ marginTop: '15px', width: '100%' }} />
        </div>
        <DCAgentComponents />
      </Container>
    </Page>
  );
}
