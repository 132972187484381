// @mui
import {
    Table,TableBody, TableCell,TableContainer,TableHead,TableRow,styled,
  } from '@mui/material';
  
  // ----------------------------------------------------------------------
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  //----------------------------------------------------------------------
  export default function DNStable() {
    return (
      <>  
                    <TableContainer style={{ padding: '10px 10px 10px 10px' }}>
                      <Table sx={{ minWidth: 500, maxWidth: '60%' }} aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <TableCell> No.</TableCell>
                            <TableCell> Server Name</TableCell>
                            <TableCell> Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <StyledTableRow>
                              <TableCell>1</TableCell> 
                              <TableCell>10.10.1.1/24</TableCell>
                              <TableCell>UP</TableCell> 
                          </StyledTableRow>
                          <TableRow>
                              <TableCell>2</TableCell> 
                              <TableCell>10.10.1.1/25</TableCell>
                              <TableCell>DOWN</TableCell>
                          </TableRow>
                          <StyledTableRow>
                              <TableCell>3</TableCell> 
                              <TableCell>10.10.1.1/26</TableCell>
                              <TableCell>DOWN</TableCell>
                          </StyledTableRow>
                          <TableRow>
                              <TableCell>4</TableCell> 
                              <TableCell>10.10.1.1/27</TableCell>
                              <TableCell>UP</TableCell>
                          </TableRow>
                          <StyledTableRow>
                              <TableCell>5</TableCell>
                              <TableCell>10.10.1.1/28</TableCell>
                              <TableCell>DOWN</TableCell>
                           </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
      </>
    );
  }
  