// @mui
import {Grid} from '@mui/material';
import MembersTable from './Components/MembersTable';

export default function EndpointsComponents() {
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12}>
        <MembersTable />
      </Grid>
    </Grid>
  );
}
