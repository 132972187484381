import {
    Typography,
    Breadcrumbs,
    Container
  } from '@mui/material';
  import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
  import { Link } from 'react-router-dom';
  import Page from '../components/Page';
  import { OnDemandcomponent } from  "../components/OnDemandComponents";
  // ----------------------------------------------------------------------
  

  export default function Location() {
  
    return (
      <Page title="On-Demand">
        <Container maxWidth={false}>
  
          <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
            <Breadcrumbs aria-label="breadcrumb">
            <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
                <HomeOutlinedIcon />
              </Typography>
              <Typography component={Link} underline="hover" color="inherit" to="/dashboard/dashboard">
                Onboarding
              </Typography>
              <Typography component={Link} underline="hover" color="inherit" to="/dashboard/ondemand">
                On-demand
              </Typography>
            </Breadcrumbs>
          </div>
    
        <OnDemandcomponent />

        </Container>
      </Page>
    );
  }
  