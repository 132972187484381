import { Box, Chip } from "@mui/material";
import { useSnackbar } from 'notistack';
import PropTypes from "prop-types";
import { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { deAssignSubnets } from '../../../../API/Subnets/Subnets';
import { UserContext } from '../../../../context/UserContext';
import { WithConfirm } from '../../../WithConfirm';

 // ----------------------------------------------------------------

 const useStyles = makeStyles({
  subnetBox2: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px'
  }
});

 // -----------------------------------------------------------------

const SubnetRenderer = (props) => {
    const classes = useStyles();
    const { data, confirm, api } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { customerId } = useContext(UserContext);


    const handleSubnetDelete = (subnet, location) => {
        const payload = {
          subnet_uuids: [
            subnet._uid,
          ]
        };
        deAssignSubnets(customerId, location._uid, payload)
          .then((res) => {
            props.api.purgeInfiniteCache();       
            enqueueSnackbar(
              'Subnet deleted successfully',
              {
                variant: 'success',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              },
              500
            );
          })
          .catch((res) => {
            console.log(res,'ppppppppppppcatchres');
            enqueueSnackbar(
              res.response.data.message,
              {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              },
              500
            );
          });
      };

    if (data === undefined) {
        return '';
    }
    return (
        <>
            <Box className={classes.subnetBox2}>
                {data?.subnets?.map((_subnet) => {
                    return (
                        <Chip
                            size="small"
                            label={_subnet.r_subnet_value}
                            key={_subnet._uid}
                            variant="outlined"
                            onDelete={confirm((e) => handleSubnetDelete(_subnet, data), {
                                title: 'Information',
                                confirmationText: 'Yes',
                                disableOkBtn: false,
                                description: 'Are you sure you want to delete this subnet',
                            })}
                        />
                    );
                })}
            </Box>
        </>
    );
};

SubnetRenderer.propTypes = {
    data: PropTypes.object,
};

export default WithConfirm(SubnetRenderer);
