// @mui
import {
  Table,TableBody, TableCell,TableContainer,TableHead,TableRow,styled,
} from '@mui/material';

// ----------------------------------------------------------------------
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
//----------------------------------------------------------------------
export default function DHCPtable() {
  return (
    <>  
                  <TableContainer style={{ padding: '10px 10px 10px 10px' }}>
                    <Table sx={{ minWidth: 500, maxWidth: '60%' }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <TableCell> No.</TableCell>
                          <TableCell> Server</TableCell>
                          <TableCell> IP</TableCell>
                          <TableCell> Type</TableCell>
                          <TableCell> Scope</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                            <TableCell>1</TableCell> 
                            <TableCell>NTPCL</TableCell>
                            <TableCell>10.10.1.123</TableCell>
                            <TableCell>Firewall</TableCell> 
                            <TableCell>12</TableCell>
                        </StyledTableRow>
                        <TableRow>
                            <TableCell>2</TableCell> 
                            <TableCell>XYLBN</TableCell>
                            <TableCell>10.20.2.24</TableCell>
                            <TableCell>Windows</TableCell>
                            <TableCell>10</TableCell>
                        </TableRow>
                        <StyledTableRow>
                            <TableCell>3</TableCell> 
                            <TableCell>MSTCP</TableCell>
                            <TableCell>21.6.13.5</TableCell>
                            <TableCell>Linux</TableCell>
                            <TableCell>14</TableCell>
                        </StyledTableRow>
                        <TableRow>
                            <TableCell>4</TableCell> 
                            <TableCell>MSTCP</TableCell>
                            <TableCell>10.20.2.24</TableCell>
                            <TableCell>Windows</TableCell>
                            <TableCell>08</TableCell>
                        </TableRow>
                        <StyledTableRow>
                            <TableCell>5</TableCell>
                            <TableCell>MSTCP</TableCell>
                            <TableCell>10.20.2.24</TableCell>
                            <TableCell>Linux</TableCell>
                            <TableCell>14</TableCell>
                         </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
    </>
  );
}
