/* eslint-disable */
import { Grid, LinearProgress, Paper, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { interfaceThroughputBandwidth } from '../../../../../../API/NetworkDevices/health';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';


// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1),
    height: 590,
}));

function formatBytes(bytes) {
    if (bytes >= 1e6) {
        return (bytes / 1e6).toFixed(2) + ' MB';
    } else if (bytes >= 1e3) {
        return (bytes / 1e3).toFixed(2) + ' KB';
    } else {
        return bytes.toFixed(2) + ' B';
    }
}

const useStyles = makeStyles({
    noDataSelectedContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 635
    },
});


// ---------------------------------------


const ThroughputBandwidth = ({interfaceExpanded}) => {
    const classess = useStyles();
    const { customerId } = useContext(UserContext);
    const { healthDashState, getStartEnd } = useHealthContext()
    const [seriesData, setSeriesData] = useState([]);
    const [xAxisData, setXAxisData] = useState([]);
    const [chartLoading, setChartLoading] = useState(false);

    const solveCondition = async () => {
        if (!healthDashState.selectedDevice || !healthDashState.interface || interfaceExpanded === false) {
            return '';
        }
        fetchThroughputBandwidth();
    };

    const fetchThroughputBandwidth = async () => {
        const data = getStartEnd()
        const payload = {
            start: data.start / 1000,
            end: data.end / 1000,
            step: data.step,
            device_type: healthDashState.selectedDevice.l_dvc_typ_name,
            device_os: healthDashState.selectedDevice.r_dvc_os,
            interface_name: healthDashState.interface?.name,
        }
        setChartLoading(true);
        interfaceThroughputBandwidth(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
            .then((res) => {
                setChartLoading(false)
                setXAxisData(res?.timestamps)
                const receiveData = res?.receive?.[0] || [];
                const transmitData = res?.transmit?.[0] || [];
                setSeriesData([
                    {
                      name: receiveData.mac,
                      data: receiveData.data,
                    },
                    {
                      name: transmitData.mac,
                      data: transmitData.data,
                    },
                  ]);     
            })
            .catch(() => {
                setChartLoading(false);
            });
    };

    useEffect(() => {
        solveCondition();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, healthDashState.interface, interfaceExpanded]);

    const chartSeries = seriesData;

    const chartOptions = {
        chart: {
            type: 'area',
            height: 590,
            toolbar: { show: false },
        },
        annotations: {
            yaxis: [{
                y: 30,
            }],
        },
        stroke: {
            curve: 'straight',
            width: 2,
        },
        dataLabels: {
            enabled: false,
            dropShadow: { enabled: true }
        },
        tooltip: {
            shared: false,
            y: {
                formatter: (val, opts) => {
                    return formatBytes(val) + '/s';
                },
            },
            x: {
              show: false,
            },
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            showForSingleSeries: true,
            markers: {
                width: 15,
                height: 7,
                strokeWidth: 0,
                radius: 12,
                offsetX: 0,
                offsetY: 0
            },
        },
        xaxis: {
            categories: xAxisData,
            type: 'datetime',
            labels: {
                hideOverlappingLabels: true,
                formatter: function (value) {
                    const date = new Date(value * 1000);
                    const hours = date.getHours();
                    const minutes = date.getMinutes();
                    const timeString = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
                    return timeString;
                },
            }
        },
        yaxis: {
            forceNiceScale: true,
            labels: {
                formatter: (value) => formatBytes(value) + '/s',
            },
        },
    };

    return (
        <>
            {chartLoading && <LinearProgress sx={{ height: '2px' }} />}
            <Paper sx={{ padding: '10px' }} variant="outlined">
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12}>
                        <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
                            Throughput Bandwidth
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12}>
                    <ChartWrapperStyle dir="ltr">
                        {!healthDashState.interface ? (
                            <Stack direction="column" className={classess.noDataSelectedContainer}>
                                <Typography variant="h3" display="block" gutterBottom>
                                    Please select interface
                                </Typography>
                                <Typography variant="subtitle1" display="block" gutterBottom>
                                    to view analytics
                                </Typography>
                            </Stack>
                        ) : (
                            <ReactApexChart series={chartSeries} options={chartOptions} type="area" height={590} />
                        )}
                    </ChartWrapperStyle>
                </Grid>
            </Paper>
        </>
    );
};
export default ThroughputBandwidth;
