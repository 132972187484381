
import { Delete, Edit } from '@mui/icons-material';
import {
    IconButton,
    Tooltip
} from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { deleteIgnoreRule } from '../../../../API/CMDBOperations';
import { UserContext } from '../../../../context/UserContext';
import { WithConfirm } from '../../../WithConfirm';
import EditIgnoreDialog from '../EditIgnoreDialog';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------


const ActionRender = (props) => {
  const { data, confirm } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [editIgnoreData, setEditIgnoreData] = useState({});
  const [editIgnoredialog, setEditIgnoredialog] = useState(false);

  const handleDelete = (value) => {
    setLoading(true);
    deleteIgnoreRule(customerId, value.uuid)
      .then((res) => {
        setLoading(false);
        enqueueSnackbar(
          'Ignore rule deleted sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        props.api.purgeInfiniteCache();
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  const handleOpenIgnoreEdit = (value) => {
    setEditIgnoreData(value);
    setEditIgnoredialog(true);
  };


  if (data === undefined) {
    return '';
  }

  if (data?.dcir_is_global !== 1) {
    return (
      <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', paddingTop: "10px" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => handleOpenIgnoreEdit(data)}>
            <Edit />
          </IconButton>
        </Tooltip>
        {editIgnoredialog && (
          <EditIgnoreDialog
            openDialog={editIgnoredialog}
            handleClose={() => setEditIgnoredialog(false)}
            editIgnoreData={editIgnoreData}
            api={props.api}
          />
        )}
        <Tooltip title="Delete">
          <IconButton
            onClick={confirm((e) => handleDelete(data), {
              title: 'Information',
              confirmationText: 'Yes',
              disableOkBtn: false,
              description: 'Are you sure you want to delete this rule',
            })}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
};

ActionRender.propTypes = {
  data: PropTypes.object,
};

export default WithConfirm(ActionRender);
