import modem from '../modem';

export const getConfigChangeCards = async (id, payload, data) => {
  const newPromise =  modem.post(`/${id}/cmdb-statistics`, data).then((response) => {
    return response.data;
  });
  return newPromise;
};

export const getConfigChangeEventsTable = async (id, data) => {
  const newPromise =  modem.post(`/${id}/network-devices/config-change-events`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const getConfigChangeEventsTableLogs = async (id, data) => {
  const newPromise =  modem.post(`/${id}/network-devices/config-change-events/logs`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const getconfigBackupEventsviaSidebar = async (id, data) => {
  const newPromise =  modem.post(`/${id}/inventory/cmdb`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};


export const getBackupEventsTable = async (id, data) => {
  const newPromise =  modem.post(`/${id}/events/cmdb`, data).then((response) => {
  // const newPromise =  modem.post(`/${id}/network-devices/config-change-events`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};
