import { Star } from '@mui/icons-material';
import { Autocomplete, CircularProgress, Skeleton, TextField } from "@mui/material";
import queryString from 'query-string';
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { getInventory } from '../../../API/NetworkDevices/networkDevices';
import { UserContext } from '../../../context/UserContext/userContext';
import { toCamelizeWords } from "../../../utils/formatString";
import { useHealthContext } from './userContextHealth';


//------------------------------------------------------------------

//--------------------------------------------------------------

export default function DeviceTypeSelector() {
  const navigate = useNavigate();
  const { customerId } = useContext(UserContext);
  const { healthDashState, healthDashDispatch } = useHealthContext();
  const [isLoading, setLoadingData] = useState(false);
  const [networkDeviceTypeOptions, setNetworkDeviceTypeOptions] = useState([]);
  // const [isWLCControllednetwork, setWLCControllednetwork] = useState(false)
  const { search } = useLocation();

  const getQueryParams = () => {
    return queryString.parse(window.location.search);
  };

  const fetchNetworkDeviceTypes = async () => {
    if (healthDashState.locationuuid) {
      const payload = {
        endRow: 500,
        startRow: 0,
        location_uuids: [healthDashState.locationuuid],
      };
      setLoadingData(true);
      try {
        const res = await getInventory(customerId, payload);
        setLoadingData(false);        
        if(res?.data.filter(item => item.l_dvc_typ_name === "WIRELESS_VIRTUAL_CONTROLLER")?.length){
          setNetworkDeviceTypeOptions(res?.data?.filter(item => item.l_dvc_typ_name !== "ACCESS_POINT"));
        }else{
          setNetworkDeviceTypeOptions(res?.data);
        }
      } catch (err) {
        setLoadingData(false);
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchNetworkDeviceTypes();
  }, [customerId, healthDashState.locationuuid]);

  useEffect(() => {
    if (networkDeviceTypeOptions) {
      const serialNumberQuery = getQueryParams().serialNum
      let selectedDevice = networkDeviceTypeOptions.find((option) => option?.r_dvc_serial_number === serialNumberQuery)
      // setSerialNumber(selectedDevice);
      if (!selectedDevice) {
        selectedDevice = null
      }
      healthDashDispatch({ type: 'SET_DEVICE', payload: selectedDevice });
    }
  }, [networkDeviceTypeOptions, search]);


  const handleSelectType = (event, selectedOptions) => {
    if (selectedOptions) {
      // setSerialNumber(selectedOptions);
      navigate(`/dashboard/health?locationUuid=${healthDashState.locationuuid}&serialNum=${selectedOptions?.r_dvc_serial_number}`);
    }
    else {
      // setSerialNumber(null);
      navigate(`/dashboard/health?locationUuid=${healthDashState.locationuuid}`);
    }
  };


  if (isLoading) {
    return <Skeleton width={250} minwidth={200} />
  }
  return (
    <>
      <Autocomplete
        size="small"
        handleHomeEndKeys
        loading={isLoading}
        disabled={!healthDashState.locationuuid}
        options={networkDeviceTypeOptions}
        value={healthDashState.selectedDevice || null}
        sx={{ maxwidth: 300, minwidth: 200 }}
        isOptionEqualToValue={(option, value) => option?._uid === value?._uid}
        getOptionLabel={(option) => `${toCamelizeWords(option?.l_dvc_typ_name)} - ${option?.r_dvc_host} - ${option?.r_dvc_ip_address}`}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option._uid}>
              {toCamelizeWords(option?.l_dvc_typ_name)} - {option?.r_dvc_host} - {option?.r_dvc_ip_address} 
            </li>
          )
        }}
        onChange={handleSelectType}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Choose device"
            label="Devices"
            type="text"
            InputLabelProps={{ shrink: true }}
            autoComplete='new-password'
            InputProps={{
              ...params.InputProps,
              autoComplete: 'new-password',
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
}
