// @mui
import {
    Breadcrumbs,
    Container,
    Grid,
    Link,
  } from '@mui/material';
  // components
  import Page from '../../../Page';
  import ISPGood1MainPage from './ISPGood1MainPage';
  // sections
  
  // ----------------------------------------------------------------------
  
  export default function ISPGood1() {
    
    return (
      <>
        <Container maxWidth={false}>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/dashboard/dashboard">
                NMS
                </Link>
                <Link underline="hover" color="inherit" href="/dashboard/dashboard">
                IPS Management
                </Link>
                <Link underline="hover" color="inherit" href="/dashboard/isp-management">
                Dashboard
                </Link>
                <Link underline="hover" color="inherit" href="/dashboard/isp-management/isp-good/1">
                    ISP Status
                </Link>
            </Breadcrumbs>
          </div>
  
        <Grid container item xs={12} sm={12} md={12} lg={12} sx={{py:2}}>
            <ISPGood1MainPage />
        </Grid>
  
        </Container>
      </>
    );
  }
  