import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Breadcrumbs, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import SitewiseMapComponent from '../components/VisibilityComponents/SitewiseMapComponent';


// ----------------------------------------------------------------------

export default function Dashboard() {


  return (
    <Page title="Sitewise Map">
      <Container maxWidth={false}>
        <Grid container direction="column" spacing={3}>

          <Grid item container>
            <Grid item xs={3} sx={{ cursor: 'pointer', paddingBottom: '17px', display: 'flex', justifyContent: 'space-between' }}>
              <div role="presentation" style={{ cursor: 'pointer' }}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
                    <HomeOutlinedIcon />
                  </Typography>
                  <Typography component={Link} underline="hover" color="inherit" to="/dashboard/sitewise-map">
                    Sitewise Maps
                  </Typography>
                </Breadcrumbs>
              </div>
            </Grid>
          </Grid>


          <Grid item container >
            <Grid item xs={12} >
              <SitewiseMapComponent />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
