import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Slide,
  Stack,
  Typography
} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import { forwardRef, useState } from 'react';
import { toCamelizeWords } from '../../../utils/formatString';
import DiffDialog001 from './DiffDialog001';
import DiffDialog002 from './DiffDialog002';

//---------------------------------------------------------------------
const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  icon: {
    visibility: 'hidden',
  },
  field: {
    width: '50%',
    paddingLeft: '10px',
  },
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  fileNameBox: {
    width: '100%',
    height: '30px',
  },
  stackContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
  },
  tabs: {
    "& button": {
      border: "1px solid #eeeeee",
      borderRadius: '10px 10px 0px 0px',
    },
  },
});

//----------------------------------------------------------------------

export default function DiffDialogwithTabs(props) {
  const classes = useStyles();
  const { openDialog, handleClose, goldenConfigPath, previousConfigPath, currentConfigPath, data, cmdbGroupSummaryEvent } = props;
  const [tabValue, setTabValue] = useState(0);


  // -------------------------------------------------------------------------------------------------------
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography component="span">{children}</Typography>
          </Box>
        )}
      </Box>
    );
  }

  // -------------------------------------------------------------------------------------------------------

  return (
    <>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        onClose={handleClose}
        fullWidth
        maxWidth="xxl"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <DialogTitle sx={{ padding: '0px' }}>
          <Stack direction="row" className={classes.stackContainer}>
            <Box sx={{ paddingLeft: '10px' }}>
              <Typography variant='h6' gutterBottom>
                Configuration diff view
              </Typography>

              <Typography gutterBottom>
                {toCamelizeWords(data?.c_m_d_b_network_device?.r_dvc_type_name)} - ({data?.c_m_d_b_network_device?.r_dvc_ip_address} - {data?.c_m_d_b_network_device?.r_dvc_serial_number})
              </Typography>
            </Box>

            <IconButton onClick={handleClose}>
              <Close sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </Stack>


          <Tabs value={tabValue} onChange={handleChange} className={classes.tabs}>
            <Tab label="Diff with Golden configuration" />
            <Tab label="Diff with Previous configuration" disabled={previousConfigPath === null || previousConfigPath === undefined || previousConfigPath.length === 0} />
          </Tabs>
        </DialogTitle>

        <Divider sx={{ width: '100%' }} />

        <DialogContent sx={{ padding: '0px' }}>
          <TabPanel value={tabValue} index={0}>
            <DiffDialog001
              sourcePath={goldenConfigPath}
              targetPath={currentConfigPath}
              data={data}
              cmdbGroupSummaryEvent={cmdbGroupSummaryEvent} />
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <DiffDialog002
              previousConfigPath={previousConfigPath}
              targetPath={currentConfigPath}
              data={data}
              // cmdbGroupSummaryEvent={cmdbGroupSummaryEvent} 
              />
          </TabPanel>
        </DialogContent>

        <DialogActions style={{ height: '3vh' }} />
      </Dialog>
    </>
  );
}
