import {
  MonitorHeart,
  Pause, PlayArrow
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  IconButton,
  Stack, Tooltip
} from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { pauseUpDown, resumeUpDown } from '../../../../API/CMDBOperations/index';
import { UserContext } from '../../../../context/UserContext';
import { WithConfirm } from '../../../WithConfirm';


// ----------------------------------------------------------------------


// ----------------------------------------------------------------


const ActionRender = (props) => {
  const navigate = useNavigate();
  const { data, confirm } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [skipLoading, setSkipLoading] = useState(false);
  const [unskipLoading, setUnskipLoading] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [pingable, setPingable] = useState(true)

  useEffect(() => {
    if (data) {
      if (Object.prototype.hasOwnProperty.call(data, "r_dvc_pingable")) {
        setPingable(data?.r_dvc_pingable)
      }
    }
  }, [data]);

  useEffect(() => {
    if(data?.l_dvc_typ_name === "ACCESS_POINT" && data?.r_dvc_controller === "WLC" || data?.r_dvc_type_source !== "DC"){
      setShowDashboard(false);
    }
    if(data?.l_dvc_typ_name === "SWITCH" && data?.r_dvc_controller === "WLC" || data?.r_dvc_type_source !== "DC"){
      setShowDashboard(true);
    }else{
      setShowDashboard(true);
    }
  }, [data]);
  
// const filteredData = data?.l_dvc_typ_name === 'WIRELESS'  ? data : null;
// console.log(filteredData,'-------------------------filteredData');


  const handleSkip = (value) => {
    setSkipLoading(true);
    pauseUpDown(customerId, value?._uid)
      .then(() => {
        setSkipLoading(false);
        setPingable(false)
        enqueueSnackbar(
          'Device is removed from up down monitoring',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setSkipLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  }

  const handleUnskip = (value) => {
    setUnskipLoading(true);
    resumeUpDown(customerId, value?._uid)
      .then(() => {
        setUnskipLoading(false);
        setPingable(true)
        enqueueSnackbar(
          'Device is set for up down monitoring',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setUnskipLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  }

  if (props.data === undefined) {
    return '';
  }

  if (props?.data) {
    return (
      <>
        <Stack direction="row" spacing={2}>
          <Box>
            {pingable === true ? (
              <LoadingButton
                variant="outlined"
                loading={skipLoading}
                startIcon={<Pause style={{ color: "red" }} />}
                onClick={confirm(() => handleSkip(data), {
                  title: 'Information',
                  confirmationText: 'Yes',
                  disableOkBtn: false,
                  description: 'Are you sure you want to remove from up down monitoring ?',
                })}
              >
                Skip scan
              </LoadingButton>

            ) : (
              <LoadingButton
                variant="outlined"
                loading={unskipLoading}
                startIcon={<PlayArrow style={{ color: "green" }} />}
                onClick={confirm(() => handleUnskip(data), {
                  title: 'Information',
                  confirmationText: 'Yes',
                  disableOkBtn: false,
                  description: 'Are you sure you want to add device to up down monitoring ?',
                })}
              >
                Resume scan
              </LoadingButton>
            )
            }
          </Box>

          {showDashboard && (
            <Box>
              <Tooltip title="Go to health dashboard">
                <IconButton
                  onClick={() => {
                    navigate(`/dashboard/health?locationUuid=${data?.location?._uid}&serialNum=${data?.r_dvc_serial_number}`);
                  }}
                >
                  <MonitorHeart color="#212121" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Stack>
      </>
    )
  }
  return null;
};


ActionRender.propTypes = {
  data: PropTypes.object,
  confirm: PropTypes.func.isRequired,
};

export default WithConfirm(ActionRender);
