import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';
import NewLogoWithText from '../Assets/LinkEyeLogowithtext.svg';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {

  // const logo = (
  //   <>
  //   <Box sx={{ width: 130, height: 35,  ...sx}}>
  //     <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 443.8 100" style={{enableBackground:"new 0 0 443.8 100"}}  xmlSpace="preserve">
  //         <g>
  //             <g>
  //                 <rect x="-31.1" y="46.5" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -43.8706 62.5757)" fill={'#FFC200'} width="80.8" height="13.5" />
  //                 <rect x="47.1" y="66.4" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -5.5515 140.7907)" fill={'#FFC200'} width="40.9" height="13.5" />
  //                 <rect x="38.2" y="5.3" transform="matrix(6.123234e-17 -1 1 6.123234e-17 10.1628 79.6072)" fill={'#FFC200'} width="13.5" height="58.9" />
  //                 <rect x="44.6" y="1.5" fill={'#FFC200'} width="29.8" height="13.5" />
  //                 <rect x="-10" y="43.3" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -11.53 88.5021)" fill={'#FFC200'} width="97.1" height="13.5" />
  //             </g>
  //             <path fill={'#424242'} d="M126.2,25.9v21.3h20.1v9.7h-20.1v30.8h-9.2V16.2H156v9.7H126.2z" />
  //             <rect x="165.2" y="34" fill={'#424242'} width="9" height="53.7" />
  //             <rect x="165.2" y="17.9" fill={'#424242'} width="9" height="8.9" />
  //             <path fill={'#424242'} d="M185.3,87.7V16.2h8.6v71.5H185.3z" />
  //             <path fill={'#424242'} d="M220.3,86.1c-3.3-1.4-6.2-3.4-8.7-5.9c-2.5-2.5-4.5-5.4-5.9-8.7c-1.4-3.3-2.1-6.8-2.1-10.4 c0-3.7,0.7-7.3,2.1-10.6c1.4-3.4,3.4-6.3,5.9-8.8c2.5-2.5,5.4-4.5,8.7-5.9c3.3-1.4,6.8-2.1,10.4-2.1c3.7,0,7.3,0.7,10.6,2.1 c3.4,1.4,6.3,3.3,8.8,5.9c2.5,2.5,4.5,5.4,5.9,8.8c1.4,3.4,2.1,6.9,2.1,10.6c0,3.7-0.7,7.1-2.1,10.4c-1.4,3.3-3.4,6.2-5.9,8.7 c-2.5,2.5-5.4,4.5-8.8,5.9c-3.4,1.4-6.9,2.2-10.6,2.2C227.1,88.2,223.6,87.5,220.3,86.1z M218.3,48.4c-1.7,1.6-2.9,3.6-3.9,5.8 c-0.9,2.2-1.4,4.5-1.4,6.9s0.5,4.6,1.4,6.8c0.9,2.1,2.2,4,3.9,5.7c1.6,1.6,3.5,2.9,5.6,3.9c2.1,0.9,4.4,1.4,6.7,1.4 c2.4,0,4.7-0.5,6.9-1.4c2.2-0.9,4.1-2.2,5.7-3.9s2.8-3.5,3.8-5.7c0.9-2.1,1.4-4.4,1.4-6.8s-0.5-4.7-1.4-6.9 c-0.9-2.2-2.2-4.2-3.8-5.8c-1.6-1.6-3.5-2.9-5.6-3.8c-2.2-0.9-4.5-1.3-6.9-1.3C225.9,43.2,221.8,44.9,218.3,48.4z" />
  //             <path fill={'#424242'} d="M278.6,43v44.7h-9V43h-5.1v-9h5.2c0.1-3.4,1.1-6.5,3-9.2c1.8-2.7,4.2-4.8,7.3-6.3c3-1.5,6.3-2.3,9.9-2.3v9.2 c-3-0.1-5.5,0.6-7.6,2.3c-2,1.7-3.1,3.8-3.3,6.3h17.4v9H278.6z" />
  //             <path fill={'#424242'} d="M309,43v44.7h-9V43h-5.1v-9h5.2c0.1-3.4,1.1-6.5,3-9.2c1.8-2.7,4.2-4.8,7.3-6.3c3-1.5,6.3-2.3,9.9-2.3v9.2 c-3-0.1-5.5,0.6-7.6,2.3c-2,1.7-3.1,3.8-3.3,6.3h10.9v9H309z" />
  //             <path fill={'#424242'} d="M333.7,64.9c0,1.7,0.6,3.5,1.9,5.4c1.3,1.9,2.7,3.4,4.4,4.7c3,2.4,6.5,3.5,10.5,3.5c6.4,0,11.6-3.1,15.6-9.2 l8.2,4.8c-2.8,4.5-6.2,8-10.3,10.4c-4.1,2.4-8.6,3.7-13.4,3.7c-3.7,0-7.1-0.7-10.4-2.1c-3.3-1.4-6.2-3.3-8.7-5.9 c-2.5-2.5-4.5-5.4-5.9-8.7c-1.4-3.3-2.1-6.8-2.1-10.4c0-3.7,0.7-7.1,2.1-10.5c1.4-3.3,3.3-6.2,5.9-8.8c2.4-2.5,5.3-4.4,8.6-5.8 s6.8-2,10.5-2c3.7,0,7.1,0.7,10.5,2s6.2,3.3,8.6,5.8c5.3,5.4,8,11.8,8,18.9c0,1.3-0.1,2.7-0.3,4.1H333.7z M342.2,44.8 c-2.6,1.3-4.6,3-6.2,5.1c-1.5,2.1-2.3,4.4-2.3,6.7h33.5c0-2.4-0.8-4.6-2.3-6.7c-1.5-2.1-3.6-3.8-6.2-5.1c-2.6-1.3-5.3-1.9-8.3-1.9 C347.6,42.9,344.8,43.5,342.2,44.8z" />
  //             <path fill={'#424242'} d="M395.4,64.9c0,1.7,0.6,3.5,1.9,5.4c1.3,1.9,2.7,3.4,4.4,4.7c3,2.4,6.5,3.5,10.5,3.5c6.4,0,11.6-3.1,15.6-9.2 l8.2,4.8c-2.8,4.5-6.2,8-10.3,10.4c-4.1,2.4-8.6,3.7-13.4,3.7c-3.7,0-7.1-0.7-10.4-2.1c-3.3-1.4-6.2-3.3-8.7-5.9 c-2.5-2.5-4.5-5.4-5.9-8.7c-1.4-3.3-2.1-6.8-2.1-10.4c0-3.7,0.7-7.1,2.1-10.5c1.4-3.3,3.3-6.2,5.9-8.8c2.4-2.5,5.3-4.4,8.6-5.8 s6.8-2,10.5-2c3.7,0,7.1,0.7,10.5,2c3.3,1.4,6.2,3.3,8.6,5.8c5.3,5.4,8,11.8,8,18.9c0,1.3-0.1,2.7-0.3,4.1H395.4z M403.9,44.8 c-2.6,1.3-4.6,3-6.2,5.1c-1.5,2.1-2.3,4.4-2.3,6.7h33.5c0-2.4-0.8-4.6-2.3-6.7c-1.5-2.1-3.6-3.8-6.2-5.1c-2.6-1.3-5.3-1.9-8.3-1.9 C409.2,42.9,406.5,43.5,403.9,44.8z" />
  //         </g>
  //     </svg> 
  //   </Box>
  //   </>
  // );

  const newLogo = (
    <>
      <Box sx={{ width: 190, height: 40,paddingLeft:'10px',display:'flex',justifyContent:'center',alignItems:'center',marginLeft:'-30px', ...sx }}>
        <img src={NewLogoWithText} alt="logo" />
      </Box>
    </>
  );

  if (disabledLink) {
    return <>{newLogo}</>;
  }

  return <RouterLink to="/dashboard/dashboard">{newLogo}</RouterLink>;
}
