import modem from '../modem';

export const getDcAgent = async (id, page, data) => {
  const newPro = modem.get(`/${id}/agents?page=${page}`, data).then((response) => {
    return {
      ...response.data.data,
      data: response.data.data.data.map((agent) => {
        return {
          ...agent,
          subnets: agent.subnets_with_location,
          locations: agent.subnets_with_location.map((su) => su.location),
        };
      }),
    };
  });

  return newPro;
};
export const getDcAgentById = async (id, agnetUuid) => {
  const newPro = modem.get(`/${id}/agents/${agnetUuid}`).then((response) => {
    return response.data.data;
  });

  return newPro;
};
export const rejectDcAgents = async (id, agentsId) => {
  const newPromise = modem.post(`${id}/agents/${agentsId}/deactivate`).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const enableDcAgents = async (id, agentsId) => {
  const newPromise = modem.post(`${id}/agents/${agentsId}/activate`).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const getZones = async (id, data) => {
  const newPromise = modem.get(`${id}/zones`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const agentConfigure = async (id, agentsId, data) => {
  const newPromise = modem.post(`${id}/agents/${agentsId}/configure`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const deleteDcAgents = async (id, agentsUid) => {
  const newPromise = modem.delete(`${id}/agents/${agentsUid}`).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const addDcAgentSubnet = async (id, agentsId, data) => {
  const newPromise = modem.post(`${id}/agents/${agentsId}/subnets/assign`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};
export const deleteDcAgentSubnet = async (id, agentsUid, data) => {
  const newPromise = modem.post(`${id}/agents/${agentsUid}/subnets/de-assign`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const scanFullConfig = async (id, agentsUid, data) => {
  const newPromise = modem.post(`${id}/agents/${agentsUid}/full-scan`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const scanSpecificSubnet = async (id, agentsUid, data) => {
  const newPromise = modem.post(`${id}/agents/${agentsUid}/subnets/scan`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};

export const scanNewlyAddedSubnet = async (id, agentsUid, data) => {
  const newPromise = modem.put(`${id}/agents/${agentsUid}/status`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};
export const agentReConfigure = async (id, agentsId, data) => {
  const newPromise = modem.post(`${id}/agents/${agentsId}/re-configure`, data).then((response) => {
    return response.data.data;
  });
  return newPromise;
};
