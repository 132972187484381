import modem from '../modem';

export const getMembers = async (id, page, payload) => {
  const newPromise =  modem
    .get(`${id}/members?page=${page}`, payload)
    .then((response) => {
      return response.data.data;
    })
    return newPromise
};
export const addMember = async (id,data) => {
  const newPromise =  modem
    .post(`${id}/members`, data)
    .then((response) => {
      return response.data.data;
    })
    return newPromise
};

export const deleteMember = async (id,memberId, data) => {
  const newPromise =  modem
  .delete(`${id}/members/${memberId}`, data)
    .then((response) => {
      return response.data.data;
    })
    return newPromise
};

export const editMember = async (id,memberId, data) => {
  const newPromise =  modem
  .post(`${id}/members/${memberId}`, data)
    .then((response) => {
      return response.data.data;
    })
    return newPromise
};

