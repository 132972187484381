import { Card, CardContent, CardHeader, Skeleton,CircularProgress, IconButton,Box } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import ReactApexChart from 'react-apexcharts';
import ShareIcon from '@mui/icons-material/Share';
import { Refresh } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import merge from 'lodash/merge';
import { makeStyles } from '@mui/styles';
import cardStyles from '../../../styles/cardsstyles';
import { BaseOptionChart } from '../../chart';
import { getCountByFamily } from '../../../API/Endpoints/showEndpoints';
import { UserContext } from '../../../context/UserContext';
import NoDataComponent from '../../NoDataComponent';

// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const useStyles = makeStyles({
  skeletonLoader: {
    top: 0,
    left: 0,
    width: "100%",
    display:'flex',
    height: 480,
  },
  noDataContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
});
// ---------------------------------------

const EndpointsByFamily = (props) => {
  const { locationData,selectEndpointFamily } = props;
  const classess = useStyles();
  const classes = cardStyles();
  const { customerId } = useContext(UserContext);
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [chartLoading, setChartLoading] = useState(true);
  const [refreshLoading, setRefreshLoading] = useState(false);


  const fetchDevicesByVendor = async () => {
    const locations = locationData;
    getCountByFamily(customerId, locations)
      .then((res) => {
        const toArray = Object.keys(res).map((item) => ({ x: item, y: res[item] })).sort((a, b) => b.y - a.y)
        setLabels(toArray.map(x => x.x));
        setSeries(toArray.map(x => x.y));
        setChartLoading(false)
        setRefreshLoading(false);
      })
      .catch((error) => {
        setRefreshLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDevicesByVendor();
  }, [customerId, locationData]);

  const handleRefresh = () => {
    setRefreshLoading(true);
    fetchDevicesByVendor()
  };

  const chartSeries = series;

  const chartOptions = merge(BaseOptionChart(), {
    labels,
    chart: {
      height:'auto',
      width:'100%',
      events: {
        dataPointMouseEnter: (event, chartContext, config) => {
          event.target.style.cursor = 'pointer';
        },
        dataPointSelection: (event, chartContext, config) => {
          const selectedIndex = config.dataPointIndex;
          if (config.dataPointIndex === selectedIndex) {
            const val = config.w.config.labels[selectedIndex];
            const tableNode = document.getElementById("ndTable")
            let scrollTo= 700
            if (tableNode){
              scrollTo=tableNode.offsetTop-document.getElementById("topBar").offsetHeight
            }
            selectEndpointFamily(val);
            window.scrollTo({top:scrollTo, left:0, behavior:"smooth"});
          }
        },
      }
    },
    legend: { 
      position:'right', 
      horizontalAlign:'right',
      width: 200,
      formatter(label, opts) {
        return [label, " - ", opts.w.globals.series[opts.seriesIndex]]
      },
    },
    dataLabels: { enabled: false, dropShadow: { enabled: true } },
    plotOptions: {
      pie: { 
        donut: { 
          size:'80%',
          labels: { 
            show: true ,
            value: {
              show: true,
              fontSize: '16px',
            },
            total: {
              show: true,
              label: 'Total',
              color: '#373d3f',
              fontWeight: 900,
              fontSize: '20px',
            },
          }, 
        }, 
      },
    },
  });

  
  
  return (
    <>
      <Card elevation={10}>
        <CardHeader
          color="text.error"
          title={'Client devices by Family'}
          avatar={<ShareIcon className={classes.boxTitleIcon} />}
          action={
            <Box>
                {refreshLoading ? (
                  <CircularProgress
                    size={30}
                    sx={{ mr: '5px' }}
                  />
                ) : (
                  <IconButton color="primary" onClick={handleRefresh} sx={{ mt: '-5px' }}>
                    <Refresh sx={{ fontSize: '2rem' }} />
                  </IconButton>
                )}
            </Box>
          }
        />
        <CardContent>
          {series?.length === 0 && !chartLoading ? (
            <Box className={classess.noDataContainer}>
              <NoDataComponent noDataFoundText="Client devices by Family" />
            </Box>
          ) : (
            <ChartWrapperStyle dir="ltr">              
            {chartLoading ? (
                <Skeleton animation="wave" variant="rectangular" className={classess.skeletonLoader} />
              ) : (
                <ReactApexChart series={chartSeries} options={chartOptions} type="donut" height={470} />
              )}
            </ChartWrapperStyle>
          )}
        </CardContent>
      </Card>
    </>
  );
};
export default EndpointsByFamily;
