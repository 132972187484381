import axios from 'axios';
import clientCredentials from "../api_endpoints"

export const logIN = async (payload) => {
  const endpoint = clientCredentials.apiHost

  return axios.post(`${endpoint}/auth/login`, payload)
  .then((res) => {
    localStorage.setItem('AuthorizationToken', JSON.stringify(res.data?.data?.authorization));
    localStorage.setItem('customerId', res.data?.data?.customer.unique_id);
    return res.data.data;
    // return res.data.data;
  })
  .catch((res) => {
    throw res;
  });
};