// @mui
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useContext, useState } from "react";
import { assignSubnets } from "../../../API/Subnets/Subnets";
import { UserContext } from "../../../context/UserContext";
import { RHFSubnetSelector, FormProvider } from "../../hook-form";
import { subnetRegex } from "../../../utils/ValidateSubnet";
// import SubnetSelector from "./SubnetSelector";

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },
});

export default function AssignSubnetsDialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog, handleClose, api, locationData } = props;
  const { customerId } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [selectedSubnets, setSelectedSubnets] = useState([]);

  // --------------------------------------------------------------
  // const subnetRegex = /^(([12]?[0-9]{1,2}|2[0-4][0-9]|25[0-5])(\.|\/)){4}([1-2]?[0-9]|3[0-2])$/;

  const assignsubnetSchema = Yup.object().shape({
    subnets: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().matches(subnetRegex, "Invalid subnet"),
          label: Yup.string()
        })
      )
      .min(1, "Min 1 subnet is required"),
  });


  const defaultValues = {
    subnets: [],
  };

  const methods = useForm({
    resolver: yupResolver(assignsubnetSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // ---------------------------------------------------------------

  const onSubmit = async (value) => {
    const data = {
      subnets: value.subnets.map(item => item.value),
    };
    setLoading(true)
    assignSubnets(customerId, locationData._uid, data)
      .then((res) => {
        setLoading(false);
        handleClose();
        api.api.purgeInfiniteCache(); 
        enqueueSnackbar(
          "Subnets added sucessfully",
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          },
          500
        );
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          },
          500
        );
      });
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
      <FormProvider  methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
          Assign subnets
          <IconButton onClick={handleClose} sx={{ float: "right" }}>
            <CloseIcon sx={{ fontSize: "1.5rem" }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        {loading && <LinearProgress />}
        <DialogContent>
          {/* <SubnetSelector setSelectedSubnets={setSelectedSubnets} selectedSubnets={selectedSubnets} /> */}
          <RHFSubnetSelector name="subnets" setSelectedSubnets={setSelectedSubnets} selectedSubnets={selectedSubnets}/>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton type='submit' variant="contained" loading={loading}>
            Assign
          </LoadingButton>
        </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
}
