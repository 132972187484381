import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useHealthContext } from '../userContextHealth';
import InterfaceSelector from '../InterfaceSelector';
import OverviewDetails from './components/System/OverviewDetails';
import CPUUsageRatioChart from './components/HACluster/CPUUsageRatioChart';
import IPSEventsTotal from './components/HACluster/IPSEventsTotal';
import MEMUsageRatio from './components/HACluster/MEMUsageRatio';
import MemberBytesTotal from './components/HACluster/MemberBytesTotal';
import MemberPacketChart from './components/HACluster/MemberPacketChart';
import MemberStatus from './components/HACluster/MemberStatus';
import NetworkUsageRatioChart from './components/HACluster/NetworkUsageRatioChart';
import HaSessionsChart from './components/HACluster/SessionsChart';
import VirusEventsTotal from './components/HACluster/VirusEventsTotal';
import CPUChart from './components/System/CPUChart';
import MemoryChart from './components/System/MemoryChart';
import SessionsChart from './components/System/SessionsChart';
import VDOMUsageChart from './components/System/VDOMUsageChart';
import VPNClients from './components/System/VPNClients';
import VersionBox from './components/System/VersionBox';
import MemoryChartDetailed from './components/System/MemoryChartDetailed';
import ThroughputBandwidth from './components/Interface/ThroughputBandwidth';
import ClientVpnConnections from './components/VPN/ClientVpnConnections';
import BandwidthReceive from './components/VPN/BandwidthReceive';
import BandwidthTransmit from './components/VPN/BandwidthTransmit';
import VPNstatusTable from './components/VPN/VPNstatusTable';
import InterfaceStatusTable from './components/Interface/InterfaceStatusTable';
import CerificateValidityTable from './components/Certificates/CerificateValidityTable';
import AllBandwidths from './components/SD-WAN/AllBandwidths';
import VirtualWanActiveSessions from './components/SD-WAN/VirtualWanActiveSessions';
import VirtualWanJitter from './components/SD-WAN/VirtualWanJitter';
import VirtualWanLatency from './components/SD-WAN/VirtualWanLatency';
import VirtualWanPacketLoss from './components/SD-WAN/VirtualWanPacketLoss';
import VirtualWanSentReceivedTotal from './components/SD-WAN/VirtualWanSentReceivedTotal';
import VirtualWanStatus from './components/SD-WAN/VirtualWanStatus';
import SdwanStatusTable from './components/SD-WAN/SdwanStatusTable';

//----------------------------------------------------
const useStyles = makeStyles({
    skeletonLoader: {
        top: 0,
        left: 0,
        width: "100%",
        display: 'flex',
    },
    noDataSelectedContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px',
        border: '2px solid',
        borderColor: '#cfd8dc'
    }
});

//----------------------------------------------------

const FortinetHealthDashboardComponent = () => {
    const classess = useStyles();
    const [systemExpanded, setSystemExpanded] = useState(true);
    const [haClusterExpanded, setHaClusterExpanded] = useState(false);
    const [interfaceExpanded, setInterfanceExpanded] = useState(false)
    const [vpnExpanded, setVpnExpanded] = useState(false);
    const [sdwanExpanded, setSdwanExpanded] = useState(false);
    const [certificateExpanded, setCertificateExpanded] = useState(false);
    const { healthDashState } = useHealthContext()


    const handleSystemChange = () => {
        setSystemExpanded(!systemExpanded);
    };

    const handleHaClusterChange = () => {
        setHaClusterExpanded(!haClusterExpanded);
    };

    const handleInterfaceChange = () => {
        setInterfanceExpanded(!interfaceExpanded);
    };

    const handleVpnChange = () => {
        setVpnExpanded(!vpnExpanded);
    };

    const handleCertificateChange = () => {
        setCertificateExpanded(!certificateExpanded);
    };

    const handleSdwanChange = () => {
        setSdwanExpanded(!sdwanExpanded);
    };

    if (!healthDashState.selectedDevice) {
        return <Stack direction="column" className={classess.noDataSelectedContainer}>
            <Typography variant="h3" display="block" gutterBottom>
                Please select device
            </Typography>
            <Typography variant="subtitle1" display="block" gutterBottom>
                to view dashboard
            </Typography>
        </Stack>
    };


    return (
        <>
            <Box>
                <Accordion expanded={systemExpanded} onChange={handleSystemChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 600 }}>System</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <OverviewDetails systemExpanded={systemExpanded} />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={1}>
                                <Grid item sm={7} md={7} lg={7}>
                                    <CPUChart systemExpanded={systemExpanded} />
                                </Grid>
                                <Grid container item direction="row" spacing={1} sm={2} md={2} lg={2}>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <VersionBox systemExpanded={systemExpanded} />
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <VPNClients systemExpanded={systemExpanded} />
                                    </Grid>
                                </Grid>
                                <Grid item sm={3} md={3} lg={3}>
                                    <MemoryChart systemExpanded={systemExpanded} />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={1}>
                                <Grid item sm={4} md={4} lg={4}>
                                    <MemoryChartDetailed systemExpanded={systemExpanded} />
                                </Grid>
                                <Grid item sm={4} md={4} lg={4}>
                                    <SessionsChart systemExpanded={systemExpanded} />
                                </Grid>
                                <Grid item sm={4} md={4} lg={4}>
                                    <VDOMUsageChart  systemExpanded={systemExpanded} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                {healthDashState.selectedDevice.r_dvc_ha_type === "1" ? "" : (
                    <Accordion expanded={haClusterExpanded} onChange={handleHaClusterChange}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography gutterBottom sx={{ fontWeight: 600 }}>HA Cluster</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                    <Grid item sm={4} md={4} lg={4}>
                                        <CPUUsageRatioChart expandedState={haClusterExpanded}/>
                                    </Grid>
                                    <Grid item sm={4} md={4} lg={4}>
                                        <HaSessionsChart expandedState={haClusterExpanded}/>
                                    </Grid>
                                    <Grid item sm={4} md={4} lg={4}>
                                        <MemberStatus expandedState={haClusterExpanded}/>
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                    <Grid item sm={4} md={4} lg={4}>
                                        <NetworkUsageRatioChart expandedState={haClusterExpanded}/>
                                    </Grid>
                                    <Grid item sm={4} md={4} lg={4}>
                                        <MemberPacketChart expandedState={haClusterExpanded} />
                                    </Grid>
                                    <Grid item sm={4} md={4} lg={4}>
                                        <VirusEventsTotal expandedState={haClusterExpanded}/>
                                    </Grid>
                                </Grid>
                                <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                    <Grid item sm={4} md={4} lg={4}>
                                        <MEMUsageRatio  expandedState={haClusterExpanded}/>
                                    </Grid>
                                    <Grid item sm={4} md={4} lg={4}>
                                        <MemberBytesTotal expandedState={haClusterExpanded}/>
                                    </Grid>
                                    <Grid item sm={4} md={4} lg={4}>
                                        <IPSEventsTotal expandedState={haClusterExpanded}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )}

                <Accordion expanded={interfaceExpanded} onChange={handleInterfaceChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                            <Typography sx={{ fontWeight: 600 }}>Interface</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            <Grid container item sm={12} md={12} lg={12} sx={{pb:2}}>
                                <Box sx={{width: '100%', display:'flex', justifyContent:'right', alignItems:'center'}}>
                                    <InterfaceSelector interfaceExpanded={interfaceExpanded}/>
                                </Box>
                            </Grid>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={8} md={8} lg={8}>
                                    <ThroughputBandwidth expandedState={interfaceExpanded}/>
                                </Grid>
                                <Grid item sm={4} md={4} lg={4}>
                                    <InterfaceStatusTable expandedState={interfaceExpanded}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={vpnExpanded} onChange={handleVpnChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 600 }}>VPN</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <ClientVpnConnections expandedState={vpnExpanded}/>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid container item direction="row" sm={8} md={8} lg={8} spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <BandwidthReceive expandedState={vpnExpanded}/>
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <BandwidthTransmit expandedState={vpnExpanded}/>
                                    </Grid>
                                </Grid>
                                <Grid item sm={4} md={4} lg={4}>
                                    <VPNstatusTable expandedState={vpnExpanded}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion> 

                <Accordion expanded={sdwanExpanded} onChange={handleSdwanChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 600 }}>SD - WAN</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={4} md={4} lg={4}>
                                    <VirtualWanStatus expandedState={sdwanExpanded}/>
                                </Grid>
                                <Grid item sm={4} md={4} lg={4}>
                                    <VirtualWanLatency expandedState={sdwanExpanded}/>
                                </Grid>
                                <Grid item sm={4} md={4} lg={4}>
                                    <VirtualWanSentReceivedTotal expandedState={sdwanExpanded}/>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={4} md={4} lg={4}>
                                    <SdwanStatusTable expandedState={sdwanExpanded}/>
                                </Grid>
                                <Grid container item direction="row" sm={8} md={8} lg={8} spacing={1}>
                                    <Grid item sm={6} md={6} lg={6}>
                                        <VirtualWanJitter expandedState={sdwanExpanded}/>
                                    </Grid>
                                    <Grid item sm={6} md={6} lg={6}>
                                        <VirtualWanActiveSessions expandedState={sdwanExpanded}/>
                                    </Grid>
                                    <Grid item sm={6} md={6} lg={6}>
                                        <VirtualWanPacketLoss expandedState={sdwanExpanded}/>
                                    </Grid>
                                    <Grid item sm={6} md={6} lg={6}>
                                        <AllBandwidths expandedState={sdwanExpanded}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={certificateExpanded} onChange={handleCertificateChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography gutterBottom sx={{ fontWeight: 600 }}>Certificate</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container item direction="row" sm={12} md={12} lg={12} spacing={1}>
                                <Grid item sm={5} md={5} lg={5}>
                                    <CerificateValidityTable expandedState={certificateExpanded}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
};

export default FortinetHealthDashboardComponent;
