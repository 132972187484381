import { Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  key_content: {
    display: "-webkit-box",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    "word-break": "break-word",
    fontSize: '18px',
    fontFamily: 'Arial',
    fontWeight: '400',
  },
}));

const KeyRenderer = (props) => {
  const classes = useStyles();
  const { data } = props;
  if (props.data === undefined) {
    return <Skeleton variant="rectangular" height={30} style={{ margin: "7px 0" }} />
  }
  return <div className={classes.key_content}>{data.location?.r_location_name}</div>;
};

KeyRenderer.propTypes = {
  data: PropTypes.object,
};

export default KeyRenderer;
