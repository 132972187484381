import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
    Autocomplete,
    Box,
    Divider,
    Grid,
    LinearProgress,
    Paper,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { FormProvider, RHFTextField } from '../../hook-form';


// -------------------------------------------------------------------------------------------

const typesList = [
    { label: 'SNMP', id: 1 },
    { label: 'SSH', id: 2 },
];

// ----------------------------------------------------------------------------------------------

const ConsoleContent = () => {
    const [loading, setLoading] = useState(false);
    const [outputMesg, setOutputMsg] = useState({});
    //----------------------------------------------------------------------------------
    const typeSchema = Yup.object().shape({
        devicetype: Yup.object().shape({
            label: Yup.string().required('Device type is required'),
        }),
        deviceip: Yup.string().when('devicetype.label', {
            is: 'SSH',
            then: Yup.string().required('Device IP is required'),
            otherwise: Yup.string(),
        }),
        sshuser: Yup.string().when('devicetype.label', {
            is: 'SSH',
            then: Yup.string().required('SSH user is required'),
            otherwise: Yup.string(),
        }),
        sshport: Yup.string().when('devicetype.label', {
            is: 'SSH',
            then: Yup.string().required('SSH port is required'),
            otherwise: Yup.string(),
        }),
        sshpassword: Yup.string().when('devicetype.label', {
            is: 'SSH',
            then: Yup.string().required('SSH Password is required'),
            otherwise: Yup.string(),
        }),
        sshcommand: Yup.string().when('devicetype.label', {
            is: 'SSH',
            then: Yup.string().required('SSH command is required'),
            otherwise: Yup.string(),
        }),
        temptext: Yup.string().when('devicetype.label', {
            is: 'SSH',
            then: Yup.string().required('Template text is required'),
            otherwise: Yup.string(),
        }),
        varcond: Yup.string().when('devicetype.label', {
            is: 'SSH',
            then: Yup.string().required('Variable conditions is required'),
            otherwise: Yup.string(),
        }),
        snmpcommand: Yup.string().when('devicetype.label', {
            is: 'SNMP',
            then: Yup.string().required('SNMP command is required'),
            otherwise: Yup.string(),
        }),
    });

    const defaultValues = {
        devicetype: '',
        deviceip: '',
        sshuser: '',
        sshport: '',
        sshpassword: '',
        sshcommand: '',
        temptext: '',
        varcond: '',
        snmpcommand: '',
    };

    const methods = useForm({
        resolver: yupResolver(typeSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        reset,
        watch,
        control,
        formState: { isSubmitting }
    } = methods;

    const initialTypeData = watch('devicetype');
    const [typeData, setTypeData] = useState(typesList[1].label);

    const handelChangeDeviceSelect = (e, value) => {
        if (value) {
            setTypeData(value.label);
        } else {
            setTypeData('');
        }
    };

    const onSubmit = async (value) => {
        setLoading(true);
        let payload = {
            devicetype: value.devicetype.label,
        };

        if (value.devicetype.label === 'SSH') {
            payload = {
                ...payload,
                deviceip: value.deviceip,
                sshuser: value.sshuser,
                sshport: value.sshport,
                sshpassword: value.sshpassword,
                sshcommand: value.sshcommand,
                temptext: value.temptext,
                varcond: value.varcond,
            };
        } else if (value.devicetype.label === 'SNMP') {
            payload = {
                ...payload,
                snmpcommand: value.snmpcommand,
            };
        }
        setOutputMsg(payload)
        console.log(payload, '-------------value res');
        setTimeout(() => {
            setLoading(false);
            setTypeData(null);
            setOutputMsg(null);
            reset();
        }, 50000);
    };

    //----------------------------------------------------------------------------

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid container item spacing={2}>
                        <Grid item lg={4} sm={4} xs={4} md={4}>
                            <Controller
                                name="devicetype"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        size="small"
                                        autoHighlight
                                        options={typesList}
                                        value={typesList.find((item) => item.label === typeData)}
                                        sx={{ minWidth: 350, maxWidth: 500 }}
                                        getOptionLabel={(option) => option.label || ''}
                                        isOptionEqualToValue={(option) => option?.label}
                                        onChange={(e, value) => {
                                            field.onChange(value); 
                                            handelChangeDeviceSelect(e, value); 
                                        }}
                                        renderOption={(props, option) => {
                                            if (option?.label) {
                                              return (
                                                <li key={option.id} {...props}>
                                                  {option.label}
                                                </li>
                                              );
                                            }
                                          }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Device type"
                                                placeholder="Choose device type"
                                                InputLabelProps={{ shrink: true }}
                                                autoComplete="new-password"
                                                sx={{ mt: 1 }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item lg={8} sm={8} xs={8} md={8}>
                            {typeData === '' ? (
                                <Paper variant='outlined' sx={{ padding: '10px', borderRadius: '5px', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                    <Typography gutterBottom>
                                        Please select device type
                                    </Typography>
                                </Paper>
                            ) : ''}
                            {typeData === 'SSH' && (
                                <Box>
                                    <Stack spacing={3} sx={{ py: 1 }}>
                                        <Stack direction="row" spacing={2}>
                                            <RHFTextField
                                                name="deviceip"
                                                label="Device IP"
                                                placeholder="Enter device IP"
                                                size="small"
                                            />
                                             <RHFTextField
                                                name="sshport"
                                                label="SSH port"
                                                placeholder="Enter SSH port"
                                                size="small"
                                            />
                                            <RHFTextField
                                                name="sshuser"
                                                label="SSH user"
                                                placeholder="Enter SSH username"
                                                size="small"
                                            />
                                             <RHFTextField
                                                name="sshpassword"
                                                label="SSH password"
                                                placeholder="Enter SSH password"
                                                size="small"
                                            />
                                        </Stack>
                                        <RHFTextField
                                            name="sshcommand"
                                            label="SSH command"
                                            placeholder="Enter SSH command"
                                            size="small"
                                        />
                                        <RHFTextField
                                            name="temptext"
                                            label="Template text"
                                            placeholder="Enter template text"
                                            size="small"
                                            multiline
                                            rows={3}
                                        />
                                        <RHFTextField
                                            name="varcond"
                                            label="Variable conditions"
                                            placeholder="Enter variable conditions"
                                            size="small"
                                        />
                                    </Stack>
                                </Box>
                            )}
                            {typeData === 'SNMP' && (
                                <Box>
                                    <Stack spacing={3} sx={{ py: 1 }}>
                                        <RHFTextField
                                            name="snmpcommand"
                                            label="SNMP full command"
                                            placeholder="Enter SNMP command"
                                            size="small"
                                        />
                                    </Stack>
                                </Box>
                            )}
                            <Box sx={{py:1}}>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                    sx={{ float: 'right', width: 'auto' }}
                                    disabled={typeData === ''}
                                >
                                    Submit
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>

                    <Divider variant="middle" />
                    <Grid item lg={12} md={12} sm={12} sx={{ mt: 1 }}>
                        Output:
                        {loading && <LinearProgress />}
                        <Paper variant="outlined" sx={{ padding: '10px', borderRadius: '5px' }}>
                            {outputMesg && outputMesg.devicetype
                                ? <pre>{outputMesg.devicetype}</pre>
                                : 'Output is displayed here'
                            }
                        </Paper>
                    </Grid>
                </Grid>
            </FormProvider>
        </>
    );
};

export default ConsoleContent;








