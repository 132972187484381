import { PhoneAndroid, Wifi } from '@mui/icons-material';
import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { clientTotal, macTotal } from '../../../../../../API/NetworkDevices/health';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';

// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    padding: theme.spacing(1),
}));

const useStyles = makeStyles({
    headerTextStyle: {
        textAlign: 'center',
        color: '#9e9e9e'
    },
    subtitleTextStyle: {
        textAlign: 'center',
        color: '#212121',
        fontSize: '1.3rem'
    }
});

// ---------------------------------------


const NetworkClients = ({overviewExpanded}) => {
    const classes = useStyles();
    const { healthDashState, getStartEnd } = useHealthContext()
    const { customerId } = useContext(UserContext);
    const [clientData, setClientData] = useState([]);
    const [macData, setMacData] = useState([]);
    const [chartLoading, setChartLoading] = useState(false);

    const solveCondition = async() => {
        if(!healthDashState.selectedDevice || overviewExpanded === false){
            return '';
        }
        fetchMacCount();
        fetchClientCount();
    };

    const fetchMacCount = async () => {
        const data = getStartEnd()
        const payload = {
            start: data.start/1000,
            end: data.end/1000,
            step: data.step,
            // device_type: healthDashState.selectedDevice.l_dvc_typ_name,
            device_os: healthDashState.selectedDevice.r_dvc_os
        }
        setChartLoading(true);
        macTotal(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
            .then((res) => {
                setChartLoading(false)
                setMacData(res.totalCount)
            })
            .catch(() => {
                setChartLoading(false);
            });
    };

    const fetchClientCount = async () => {
        const data = getStartEnd()
        const payload = {
            start: data.start/1000,
            end: data.end/1000,
            step: data.step,
            // device_type: healthDashState.selectedDevice.l_dvc_typ_name,
            device_os: healthDashState.selectedDevice.r_dvc_os
        }
        setChartLoading(true);
        clientTotal(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
            .then((res) => {
                setChartLoading(false)
                setClientData(res.totalCount)
            })
            .catch(() => {
                setChartLoading(false);
            });
    };

    useEffect(() => {
        solveCondition();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, overviewExpanded]);
    
    return (
        <>
            <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6}>
                {chartLoading && <LinearProgress />}
                    <Paper sx={{ padding: '10px' }} variant="outlined">
                        <ChartWrapperStyle>
                            <Grid container spacing={1} direction="row">
                                <Grid item lg={4} md={4} sm={4}>
                                    <Typography gutterBottom variant='h5' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1, color: '#9e9e9e' }}>
                                        <Wifi /> MAC
                                    </Typography>
                                    <Typography gutterBottom variant='subtitle1' sx={{ pl: 1 }} className={classes.subtitleTextStyle}>
                                        {macData}
                                    </Typography>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Typography gutterBottom variant='h5' className={classes.headerTextStyle}>
                                        Active
                                    </Typography>
                                    <Typography gutterBottom variant='subtitle1' className={classes.subtitleTextStyle}>
                                        --
                                    </Typography>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Typography gutterBottom variant='h5' className={classes.headerTextStyle}>
                                        In-Active
                                    </Typography>
                                    <Typography gutterBottom variant='subtitle1' className={classes.subtitleTextStyle}>
                                        --
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ChartWrapperStyle>
                    </Paper>
                </Grid>

                <Grid item lg={6} md={6} sm={6}>
                {chartLoading && <LinearProgress />}
                    <Paper sx={{ padding: '10px' }} variant="outlined">
                        <ChartWrapperStyle>
                            <Grid container spacing={1} direction="row">
                                <Grid item lg={4} md={4} sm={4}>
                                    <Typography gutterBottom variant='h5' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1, color: '#9e9e9e' }}>
                                        <PhoneAndroid />  Clients
                                    </Typography>
                                    <Typography gutterBottom variant='subtitle1' sx={{ pl: 1}} className={classes.subtitleTextStyle}>
                                        {clientData}
                                    </Typography>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Typography gutterBottom variant='h5' className={classes.headerTextStyle}>
                                        Wireless
                                    </Typography>
                                    <Typography gutterBottom variant='subtitle1' className={classes.subtitleTextStyle}> 
                                        --
                                    </Typography>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Typography gutterBottom variant='h5' className={classes.headerTextStyle}>
                                        Wired
                                    </Typography>
                                    <Typography gutterBottom variant='subtitle1' className={classes.subtitleTextStyle}>
                                        --
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ChartWrapperStyle>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

NetworkClients.propTypes = {
    overviewExpanded: PropTypes.bool.isRequired,
};

export default NetworkClients;
