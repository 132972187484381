// @mui
import {
    Grid,
  } from '@mui/material';
  import ISPGoodTable from './ISPGoodTable';
  // components
  // ----------------------------------------------------------------------
  
  export default function ISPGood() {
    return (
      <>
           <Grid item lg={12} sm={12} xs={12} md={12} xl={12}>
             <ISPGoodTable />
           </Grid>
      </>
    );
  }
  