/* eslint-disable */
import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { haClusterMemberStatus } from '../../../../../../API/NetworkDevices/health';
import { UserContext } from '../../../../../../context/UserContext';
import { useHealthContext } from '../../../userContextHealth';


// -------------------------------------

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    height: 259,
    display: 'flex',
    flexDirection: 'row',
    gap: 0,
    justifyContent: 'center',
    alignItems: 'center'
}));

// ---------------------------------------

const MemberStatus = ({ expandedState }) => {
    const { healthDashState, getStartEnd } = useHealthContext()
    const { customerId } = useContext(UserContext);
    const [seriesData, setSeriesData] = useState([]);
    const [chartLoading, setChartLoading] = useState(false);

    const solveCondition = async () => {
        if (!healthDashState.selectedDevice || expandedState === false) {
            return '';
        }
        fetchMemberStatus();
    }

    const fetchMemberStatus = async () => {
        const data = getStartEnd()
        const payload = {
            start: data.start / 1000,
            end: data.end / 1000,
            step: data.step,
            // device_type: healthDashState.selectedDevice.l_dvc_typ_name,
            device_os: healthDashState.selectedDevice.r_dvc_os
        }
        setChartLoading(true)
        haClusterMemberStatus(customerId, healthDashState.selectedDevice.r_dvc_serial_number, payload)
            .then((res) => {
                setChartLoading(false)
                const convertedData = res?.data.map((chartData) => ({
                    ...chartData,
                    data: chartData.data.map((value) => (value === 1 ? 100 : 0)),
                }));
                setSeriesData(convertedData);
            })
    };

    useEffect(() => {
        solveCondition();
    }, [customerId, healthDashState.selectedDevice, healthDashState.timeRange, expandedState]);

    const chartSeries = seriesData;

    
    const chartOptions = {
        chart: {
            height: 250,
            type: 'radialBar',
            toolbar: { show: false },
        },
        stroke: {
            lineCap: "round",
        },
        plotOptions: {
            radialBar: {
                size: "100%",
                inverseOrder: false,
                startAngle: -110,
                endAngle: 110,
                offsetX: 0,
                offsetY: 0,
                hollow: {
                    margin: 0,
                    size: "60%"
                },
                track: {
                    show: true,
                    startAngle: -110,
                    endAngle: 110,
                    background: "#f2f2f2",
                    strokeWidth: "100%",
                    opacity: 1,
                    margin: 1,
                    dropShadow: {
                        enabled: false,
                        top: 0,
                        left: 0,
                        blur: 3,
                        opacity: 0.5
                    }
                },
                dataLabels: {
                    show: true,
                    name: {
                        show: true,
                        fontSize: "1.0rem",
                        fontWeight: 600,
                        offsetY: 65
                    },
                    value: {
                        show: true,
                        fontSize: "2.0rem",
                        offsetY: 8,
                        formatter: function (val) {
                            return val / 100;
                        },
                    },
                    total: {
                        show: false,
                    }
                }
            },
        },
        legend: {
            show: false
        },
    };

    return (
        <>
            {chartLoading && <LinearProgress sx={{ height: '2px' }} />}
            <Paper sx={{ padding: '10px' }} variant="outlined">
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12}>
                        <Typography gutterBottom variant='h6' sx={{ pl: 1 }}>
                            Member Status
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12}>
                    <ChartWrapperStyle dir="ltr">
                        {chartSeries.map((chartData, index) => {
                            return (
                                <ReactApexChart
                                    key={index}
                                    series={chartData.data}
                                    options={{
                                        ...chartOptions,
                                        labels: [chartData.name], 
                                    }}
                                    type="radialBar"
                                    height={250}
                                />
                            )
                        })}
                    </ChartWrapperStyle>
                </Grid>
            </Paper>
        </>
    );
};

export default MemberStatus;
