import axios from 'axios';
import clientCredentials from "./api_endpoints"
// const modem = () => {
const defaultOptions = {
  // baseURL: 'https://decathlon-api.filoffee.ai/api',
  // baseURL: 'https://nms-portal-api.onedigilta.com/api',
  // baseURL: process.env.REACT_APP_API_ENDPOINT,
  baseURL: clientCredentials.apiHost,
  // baseURL: 'http://127.0.0.1:8000/api',  
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  crossOrigin: true,
};
// Create instance
const instance = axios.create(defaultOptions);

instance.interceptors.request.use((config) => {
  if (localStorage.getItem('AuthorizationToken') === null) {
    config.headers.Authorization = null;
  } else {
    try {
      const token = JSON.parse(localStorage.getItem('AuthorizationToken'));
      config.headers.Authorization = `Bearer ${token.token}`;
    } catch (error) {
      console.log(error)
    }
  }
  return config;
});

instance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response?.status === 401) {
    window.location = '/';
  } else {
    return Promise.reject(error);
  }
});

export default instance;
