import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, CircularProgress, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo, useState } from 'react';
import { getLocationsWithoutPagination } from '../../API/Locations/showLocations';
import { UserContext } from '../../context/UserContext';

// -----------------------------------------------------------------------

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// -----------------------------------------------------------------------

export default function LocationSelect(props) {
  const { locationData } = props;
  const { customerId } = useContext(UserContext);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [locationOptions, setLocationOptions] = useState([]);

  // ----------------------------------------------------------------------------
  
  const defaultValues = useMemo(() => {
    if (!locationOptions || !locationData) {
      return [];
    }
    return locationData ? locationOptions.filter((option) => locationData.includes(option._uid)) : [];
  }, [locationOptions, locationData]);
  
  const [selectedLocations, setSelectedLocations] = useState(defaultValues);

  useEffect(() => {
    const loadLoactionOptions = async () => {
      try {
        setLoading(true);
        getLocationsWithoutPagination(customerId).then((_locationOptions) => {
          const sortedOptions = _locationOptions.data.sort((a, b) => {
            if (a.zone?.r_zone_name !== b.zone?.r_zone_name) {
              return a.zone?.r_zone_name.localeCompare(b.zone?.r_zone_name);
            }
            return a.r_location_name.localeCompare(b.r_location_name);
          });
          setLocationOptions(sortedOptions);
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
      }
    };
  
    loadLoactionOptions();
  }, [customerId]); 

  useEffect(() => {
    setSelectedLocations(defaultValues);
  }, [defaultValues]);

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSelectLocation = (event, selectedOptions) => {
    const selectedLocationUids = selectedOptions.map((option) => option._uid);
    props.selectLocation(selectedLocationUids);
  };
  
  // ----------------------------------------------------------------------------

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'end',
      }}
    >
      <Autocomplete
        multiple
        size="small"
        limitTags={2}
        loading={loading}
        handleHomeEndKeys
        disableCloseOnSelect
        value={selectedLocations}
        options={locationOptions}
        onChange={handleSelectLocation}
        sx={{ maxwidth: 900, minWidth: 300 }}
        groupBy={(option) => option.zone?.r_zone_name}
        getOptionLabel={(option) => option.r_location_name}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option.r_location_name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            type="text"
            label="Locations"
            name='new-password'
            autoComplete='new-password'
            placeholder="Choose Locations"
            onChange={handleQueryChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              autoComplete: 'new-password',
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
}

LocationSelect.propTypes = {
  locationData: PropTypes.array, 
  selectLocation: PropTypes.func.isRequired,
};
