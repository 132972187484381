// @mui
import { Box, Grid, IconButton, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DevicesUpIcon, LinksUpIcon, LinksDownIcon } from '../../../icons';

const useStyles = makeStyles({
  boxPadding: {
    padding: '25px',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
});

const IPAMCards = () => {
  const classes = useStyles();
  return (
    <>
      <Grid sx={{ minWidth: '270px' }} item lg={12 / 4} sm={6} xs={12} md={4}>
        <Paper elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <IconButton size="large" sx={{ color: 'blue', background: '#c3cfe6', borderRadius: '12px' }}>
                <LinksUpIcon />
              </IconButton>
              <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h5" component="div">
                Total subnets
              </Typography>
            </Box>
            <Box sx={{ marginTop: '8px', marginLeft: '8px' }}>
              <Typography variant="h1" component="div">
                250
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid sx={{ minWidth: '270px' }} item lg={12 / 4} sm={6} xs={12} md={4}>
        <Paper elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <IconButton size="large" sx={{ color: '#112627', background: '#56562f54', borderRadius: '12px' }}>
                <LinksDownIcon />
              </IconButton>
              <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h5" component="div">
                Total devices
              </Typography>
            </Box>
            <Box sx={{ marginTop: '8px', marginLeft: '8px' }}>
              <Typography variant="h1" component="div">
                250
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid sx={{ minWidth: '270px' }} item lg={12 / 4} sm={6} xs={12} md={4}>
        <Paper elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <IconButton size="large" sx={{ color: 'green', background: '#c3e6c3', borderRadius: '12px' }}>
                <DevicesUpIcon />
              </IconButton>
              <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h5" component="div">
                Devices up
              </Typography>
            </Box>
            <Box sx={{ marginTop: '8px', marginLeft: '8px' }}>
              <Typography variant="h1" component="div">
                250
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid sx={{ minWidth: '270px' }} item lg={12 / 4} sm={6} xs={12} md={4}>
        <Paper elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <IconButton size="large" sx={{ color: 'red', background: '#e6b8bf', borderRadius: '12px' }}>
                <DevicesUpIcon />
              </IconButton>
              <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h5" component="div">
                Devices down
              </Typography>
            </Box>
            <Box sx={{ marginTop: '8px', marginLeft: '8px' }}>
              <Typography variant="h1" component="div">
                250
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </>
  );
};
export default IPAMCards;
