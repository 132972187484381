// @mui
import CloseIcon from '@mui/icons-material/Close';
import { useState, useContext } from 'react';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Stack,
  Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFTextField, FormProvider } from '../../hook-form';
import { UserContext } from '../../../context/UserContext';
import { addSchedulerEmail } from '../../../API/Scheduler/Scheduler';

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
});

export default function AddEmail(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog, handleClose, fetchEmails } = props;
  const { customerId } = useContext(UserContext);
  const [ loading, setLoading] = useState(false);

  const membersSchema = Yup.object().shape({
    newemail: Yup.string().email().required('Email address required'),
  });


  const defaultValues = {
    newemail: '',
  };

  const methods = useForm({
    resolver: yupResolver(membersSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (value) => {
    const data = {
        emails: value.newemail,
    };
    setLoading(true);
    addSchedulerEmail(customerId, data)
      .then((res) => {
        setLoading(false);
        handleClose();
        fetchEmails();
        enqueueSnackbar(
          'Emial added sucessfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        style={{margin:0}}
        aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="responsive-dialog-title" sx={{ py: 1 }}>
            Add new email
            <IconButton onClick={handleClose} sx={{ float: 'right' }}>
              <CloseIcon sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </DialogTitle>
          <Divider />
          {loading && <LinearProgress />}
          <DialogContent>
            <Stack spacing={3} sx={{ py: 2 }}>
              <RHFTextField name="newemail" label="Email address" fullWidth placeholder="Enter email address" />
            </Stack>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Add Email
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
}
